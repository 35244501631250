import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";

export const ACCOUNT_ERROR = "ACCOUNT_ERROR";

export const ACCOUNT_FETCHING = "ACCOUNT_FETCHING";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const ACCOUNT_RESET = "ACCOUNT_RESET";
export const ACCOUNT_CHECK_LOGIN_FIRST = "ACCOUNT_CHECK_LOGIN_FIRST";
export const ACCOUNT_CHECK_PACKAGE_DOWN = "ACCOUNT_CHECK_PACKAGE_DOWN";
export const RESET_ACCOUNT_CHECK_PACKAGE_DOWN =
  "RESET_ACCOUNT_CHECK_PACKAGE_DOWN";
export const ACCOUNT_GET_PACKAGE_BY_ID = "ACCOUNT_GET_PACKAGE_BY_ID";
export const ACCOUNT_PACKAGE_DOWN_COMMUNITY = "ACCOUNT_PACKAGE_DOWN_COMMUNITY";
export const ACCOUNT_DECRYPT = "ACCOUNT_DECRYPT";

export const updatePassword = (params) => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    let req = axios.post(`user/update-password/`, params);

    const resCreate = await req;

    const data = resCreate.data;

    dispatch({
      type: ACCOUNT_UPDATE,
      payload: { message: data.message, statusUpdate: data.data },
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const getCheckPackageDown = (packageId) => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(
      `user/permission-upgrade-resource/${packageId}`
    );
    dispatch({
      type: ACCOUNT_CHECK_PACKAGE_DOWN,
      payload: {
        data: res.data.data,
        status: res.data.status,
        message: res.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const getAccountById = () => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(`user/detail`);
    dispatch({
      type: ACCOUNT_ID,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const getPackageById = (id) => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(`user/package-by-id/${id}`);

    dispatch({
      type: ACCOUNT_GET_PACKAGE_BY_ID,
      payload: {
        data: res.data.data,
        status: res.data.status,
        message: res.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const checkPackageDownCommunity = () => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(`user/update-free-package`);

    dispatch({
      type: ACCOUNT_CHECK_PACKAGE_DOWN,
      payload: { status: res.data.status, message: res.data.message },
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const checkFirstLogin = () => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(`user/check-first-login`);
    dispatch({
      type: ACCOUNT_CHECK_LOGIN_FIRST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const updateActionScreen = (params) => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.post(`user/update-action-screen`, params);
    if (res.status === 200) {
      const res = await axios.get(`user/detail`);
      dispatch({
        type: ACCOUNT_ID,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const decrypt = (key) => async (dispatch) => {
  dispatch({ type: ACCOUNT_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const dataBody = { key: key };
    const res = await axios.post(`user/decrypt`, dataBody);
    dispatch({
      type: ACCOUNT_DECRYPT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: ACCOUNT_RESET });

export const resetPackageDown = () => (dispatch) =>
  dispatch({ type: RESET_ACCOUNT_CHECK_PACKAGE_DOWN });
