import React, { useState } from "react";
import { Col, Row, Form, Input, Button } from "antd";
import "./index.scss";
import { useDispatch } from "react-redux";
import * as RegisterAcctions from "./store/actions";

const tailLayout = {
  labelCol: {
    span: 12,
    offset: 6,
  },
  wrapperCol: {
    span: 12,
    offset: 6,
  },
};
const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const verifyEmail = (value) => {
    // eslint-disable-next-line max-len, prefer-named-capture-group
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    if (emailRex.test(value)) return true;
    return false;
  };
  const verifyNumberPhone = (value) => {
    const phoneRex =
      /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/;

    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    dispatch(RegisterAcctions.registerAccount(e));
  };
  return (
    <Row className="main-container">
      <Col span={15} className="form-register">
        <Row>
          <Col span={12} offset={6}>
            Đăng ký tài khoản Metplus
          </Col>
        </Row>

        <Form name="register" onFinish={onSubmit}>
          <Form.Item
            name={"lastName"}
            {...tailLayout}
            rules={[
              { required: true, message: "Vui lòng nhập họ và tên của bạn!" },
            ]}
          >
            <Input placeholder="Họ và Tên*"></Input>
          </Form.Item>
          <Form.Item
            name={"email"}
            {...tailLayout}
            rules={[
              { required: true, message: "Vui lòng nhập email của bạn!" },
              () => ({
                validator(_, value) {
                  if (verifyEmail(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Email của bạn không phù hợp!")
                  );
                },
              }),
            ]}
          >
            <Input
              placeholder="Email (dùng để kích hoạt tài khoản)*"
              value={email}
              onChange={(e) => setEmail(e)}
            ></Input>
          </Form.Item>
          <Form.Item
            name={"username"}
            {...tailLayout}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số điện thoại của bạn!",
              },
              () => ({
                validator(_, value) {
                  if (verifyNumberPhone(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Số điện thoại của bạn không phù hợp!")
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Số điện thoại*"></Input>
          </Form.Item>

          <Form.Item
            name={"passwordNew"}
            {...tailLayout}
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu của bạn !" },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Mật khẩu*"></Input.Password>
          </Form.Item>

          <Form.Item
            name={"passwordOld"}
            dependencies={["passwordNew"]}
            hasFeedback
            {...tailLayout}
            rules={[
              {
                required: true,
                message: "Vui lòng xác nhận lại mật khẩu của bạn!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("passwordNew") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Hai mật khẩu bạn vừa nhập không khớp!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Xác nhận mật khẩu*"></Input.Password>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Tạo tài khoản
            </Button>
          </Form.Item>
        </Form>
        <Row></Row>
      </Col>
    </Row>
  );
};
export default LoginPage;
