import React from "react";
import PropTypes from "prop-types";
import routes from "app/configs/routes.config";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./landing_page.scss";
import "./index.scss";
import { ConfigProvider, Layout, Col, Row } from "antd";
import { Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import Logo from "../../assets/img/icon_new/logo_dark.png";
import logoLandingPage from "../../assets/img/icon_new/logo-landing-page.png";
import apiConfig from "app/configs/api.config";
import FooterLandingPage from "../../components/Footer/FooterLandingPage";
// import background from "../../assets/icon/background-register.svg"
const { Content } = Layout;
const LandingPage = () => {
  const loading = (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </div>
  );

  return (
    <div id="landingpage-id" style={{ height: "100vh" }}>
      <ConfigProvider>
        {!isMobile ? (
          <Row className="main-header-landing-page">
            <Col span={24} className={"content-main-header-landing-page"}>
              <Col span={9}>
                <Row justify={"start"}>
                  <Col>
                    <a href={apiConfig.domainHome}>
                      <img
                        src={logoLandingPage}
                        alt="logoLandingPage"
                        style={{
                          width: "100%",
                          // paddingTop: "14px",
                          // paddingLeft: "33px",
                          // paddingBottom: "23px",
                          borderBottom: "3px solid #7FB342",
                        }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col span={14}></Col>
            </Col>
          </Row>
        ) : (
          <Row className="main-header-landing-page">
            <Col span={24} className={"content-main-header-landing-page"}>
              <Col span={16} offset={4}>
                <Row justify={"start"}>
                  <Col>
                    <a href={apiConfig.domainHome}>
                      <img
                        src={logoLandingPage}
                        alt="logoLandingPage"
                        style={{
                          width: "100%",
                          // paddingTop: "14px",
                          // paddingLeft: "33px",
                          // paddingBottom: "23px",
                          borderBottom: "2px solid #7FB342",
                        }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        )}
        <Layout
          className={
            isMobile
              ? "main-layout-landing-page block"
              : "main-layout-landing-page"
          }
          style={{
            clear: "both",
          }}
        >
          <Layout className="site-layout-landing-page">
            <Content
              className={isMobile ? "" : "main-content-landing-page"}
              style={{
                background: "url('background-register.png') !important",
              }}
            >
              <React.Suspense fallback={loading}>
                <Switch>
                  {routes.map((route, idx) =>
                    route.component ? (
                      <Route
                        key={String(idx)}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(renderProps) => (
                          <route.component {...renderProps} />
                        )}
                      />
                    ) : null
                  )}
                </Switch>
              </React.Suspense>
            </Content>
            <FooterLandingPage />
          </Layout>
        </Layout>
      </ConfigProvider>
    </div>
  );
};

LandingPage.propTypes = {
  children: PropTypes.element,
};

export default LandingPage;
