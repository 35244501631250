import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import "./index.scss";
import { Col, Drawer, Layout, Menu, Row } from "antd";
import navigation from "app/configs/_nav";
import { getLocalStorage, recursionOpenKeys } from "app/helpers/common";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import Avatar from "../../assets/img/icon_new/Avatar.png";
// import Profile from "../../app/main/profile/profile";
// import ProfileSub from "app/main/profile/components/profile_sub";
import constants from "app/helpers/constants";
import AuthService from "app/auth-keycloak/authservice";
import UserImg from "../../assets/img/icon_new/user.png";
import { useHistory } from "react-router-dom";
import LightDark from "../../assets/img/icon_new/light_dark.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = React.memo((props) => {
  const historyRedirect = useHistory();
  const dataSelected = getLocalStorage("AGENCY_SELECTED")
    ? JSON.parse(getLocalStorage("AGENCY_SELECTED"))
    : null;
  const { history, isCollapsed, handleCollapse, dataUser } = props;
  const pathName = history.location.pathname;
  const openKeysInit = recursionOpenKeys(navigation.items, [], pathName);
  const [selectedKey, setSelectedKey] = useState(pathName);
  const [openKeys, setOpenKeys] = useState(openKeysInit);
  const [avatar, setAvatar] = useState(false);
  const { t } = useTranslation("common");
  // const [checkedMode,setCheckedMode] = useState(true)
  useEffect(() => {
    setSelectedKey(pathName);
    setOpenKeys(openKeys);
  }, [pathName, openKeys]);

  const titleSidebar = (title, description) => {
    return (
      <Row>
        <Col
          style={{ fontWeight: 500, fontSize: 14 }}
          className={"cl-main-title"}
        >
          {title}
        </Col>
        <Col
          style={{
            fontWeight: 500,
            fontSize: 12,
            opacity: 0.5,
          }}
          className={"cl-main-title"}
        >
          {description}
        </Col>
      </Row>
    );
  };

  const recursionMenuItem = (items) =>
    items.map((item) => {
      if (
        item.permission &&
        item.permission.length > 0 &&
        dataSelected &&
        item.permission.indexOf(dataSelected.accountLevel) === -1
      ) {
        return <></>;
      }
      if (item.children && item.children.length > 0) {
        return (
          <SubMenu key={item.url} icon={item.icon} title={item.name}>
            {recursionMenuItem(item.children)}
          </SubMenu>
        );
      }

      return (
        <Menu.Item
          key={item.url}
          icon={item.icon}
          className={"main-menu " + item.name}
          style={
            localStorage.getItem("primaryThemes") !== "1" ? {} : { opacity: 1 }
          }
        >
          <Link to={item.url} style={{ color: "#fff" }}>
            {!item.children && "•"}{" "}
            {titleSidebar(t(item.name), t(item.description))}
          </Link>
        </Menu.Item>
      );
    });

  const recursionMenuItemMobile = (items) =>
    items.map((item) => {
      if (
        item.permission &&
        item.permission.length > 0 &&
        dataSelected &&
        item.permission.indexOf(dataSelected.accountLevel) === -1
      ) {
        return <></>;
      }
      if (item.children && item.children.length > 0) {
        return (
          <SubMenu key={item.url} icon={item.icon} title={item.name}>
            {recursionMenuItem(item.children)}
          </SubMenu>
        );
      }

      return (
        <Menu.Item
          key={item.url}
          icon={item.icon}
          className={"main-menu"}
          onClick={clickClose}
        >
          <Link to={item.url} style={{ color: "#fff" }}>
            {!item.children && "•"} {t(item.name)}
          </Link>
        </Menu.Item>
      );
    });

  const recursionMenuItemMobileSub = (items) =>
    items.map((item, idx) => {
      if (
        item.permission &&
        item.permission.length > 0 &&
        dataSelected &&
        item.permission.indexOf(dataSelected.accountLevel) === -1
      ) {
        return <></>;
      }
      if (item.children && item.children.length > 0) {
        return (
          <SubMenu key={item.url} icon={item.icon} title={item.name}>
            {recursionMenuItem(item.children)}
          </SubMenu>
        );
      }

      return idx === 1 ? (
        <Menu.Item
          className={"option-mobile"}
          style={{ lineHeight: "57px", margin: 10 }}
          key={idx}
          onClick={handleChangeMode}
        >
          <img src={LightDark} alt={"ModeLightDark"} />
          <span style={{ display: "inline-block" }} className="ml-10 mt-5">
            Chuyển giao diện{" "}
            {localStorage.getItem("primaryThemes") !== "1" ? " tối" : " sáng"}
          </span>
        </Menu.Item>
      ) : (
        <Menu.Item
          className="option-mobile"
          style={{ lineHeight: "57px", margin: 10 }}
          key={idx}
          onClick={() => {
            switch (item.function) {
              case "logout":
                AuthService.doLogout();
                clickClose();
                // history.replace("/auth/logout");
                break;
              default:
                history.push({
                  pathname: item.url,
                  state: { check: true },
                });
                clickClose();
                break;
            }
          }}
        >
          {item.icon}
          <span style={{ display: "inline-block" }} className="ml-10 mt-5">
            {t(item.name)}
          </span>
        </Menu.Item>
      );
    });

  const changeOpenKey = (openKey) => {
    setOpenKeys(openKey);
  };

  const selectKey = ({ selectedKeys }) => {
    setSelectedKey(selectedKeys);
  };

  const clickClose = () => {
    handleCollapse(false);
  };

  const clickProfile = () => {
    if (isMobileOnly) {
      setAvatar(!avatar);
    } else {
      clickClose();
    }
  };

  const menuHeader = (dataUser) => {
    return (
      <Row>
        <Col span={5} onClick={clickProfile} style={{ marginRight: "20px" }}>
          <img src={Avatar} alt={"avatar"} width="100%" />
        </Col>
        <Col span={17} onClick={clickProfile}>
          <Row>
            <Col span={24} className="text-header-dropdown">
              {dataUser?.userDetail?.firstName} {dataUser?.userDetail?.lastName}
            </Col>
            <Col
              span={24}
              className="text-header-dropdown-email-mobi cl-sb-email-mobi"
            >
              {dataUser?.userDetail?.email}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  // useEffect(() => {
  //   if (localStorage.getItem("primaryThemes") !== "1") {
  //     localStorage.setItem("primaryThemes", "1");
  //   } else {
  //     localStorage.setItem("primaryThemes", "0");
  //   }
  //   setCheckedMode
  // })
  const handleChangeMode = () => {
    // console.log;
    if (localStorage.getItem("primaryThemes") !== "1") {
      localStorage.setItem("primaryThemes", "1");
    } else {
      localStorage.setItem("primaryThemes", "0");
    }
    window.location.reload();
  };
  return !isMobileOnly ? (
    <Sider
      collapsed={true}
      className={"sidebar-close position-relative sidebar-mms bg-cl-header"}
      style={{
        position: "fixed",
        top: "100px",
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[`${selectedKey}`]}
        openKeys={openKeys}
        onOpenChange={changeOpenKey}
        onSelect={selectKey}
        className="sidebar-right-custom bg-cl-header"
      >
        {recursionMenuItem(navigation.items)}
      </Menu>
      {/*<Profile*/}
      {/*  isModalVisibleProfile={isModalVisibleProfile}*/}
      {/*  handleClickAvatar={handleClickAvatar}*/}
      {/*/>*/}
    </Sider>
  ) : (
    <Drawer
      title={menuHeader(dataUser)}
      placement="left"
      onClose={clickClose}
      visible={isCollapsed}
      closable={false}
      width={300}
      className="drawer-custom menu"
    >
      <Menu
        mode="inline"
        selectedKeys={[`${selectedKey}`]}
        openKeys={openKeys}
        onOpenChange={changeOpenKey}
        onSelect={selectKey}
        className="sidebar-right-custom mobile bg-cl-main-layout"
      >
        {avatar && (
          <Menu.Item
            className="option-mobile"
            style={{ lineHeight: "57px", margin: 10 }}
            key={"profile"}
            onClick={() => {
              clickClose();
              // handleClickAvatar();
              historyRedirect.push("/profile");
            }}
          >
            <img src={UserImg} alt="User" width="20px" height="20px" />
            <span style={{ display: "inline-block" }} className="ml-10 mt-5">
              {t("userInfo")}
            </span>
          </Menu.Item>
        )}
        {avatar
          ? recursionMenuItemMobileSub(constants.PROFILE_MENU_ITEMS)
          : recursionMenuItemMobile(navigation.items)}
      </Menu>
      {/*<Profile*/}
      {/*  isModalVisibleProfile={isModalVisibleProfile}*/}
      {/*  handleClickAvatar={handleClickAvatar}*/}
      {/*/>*/}
    </Drawer>
  );
});

Sidebar.propTypes = {
  history: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

export default withRouter(Sidebar);
