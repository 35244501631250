import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";
import { notificationPopup } from "../../../../helpers/common";
import history from "@history";

axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
export const CONTACT_RESET = "CONTACT_RESET";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_SET = "CONTACT_SET";
export const CONTACT_RESET_UPDATE = "CONTACT_RESET_UPDATE";

export const CONTACT_FETCHING = "CONTACT_FETCHING";
export const CONTACT_FETCHED = "CONTACT_FETCHED";
export const CONTACT_COUNT = "CONTACT_COUNT";
export const CONTACT_CREATE_UPDATE = "CONTACT_CREATE_UPDATE";
export const CONTACT_REMOVES = "CONTACT_REMOVES";
export const RESET_CONTACT_REMOVES = "RESET_CONTACT_REMOVES";

export const setContact = (contact) => (dispatch) => {
  dispatch({
    type: CONTACT_SET,
    payload: contact,
  });
};

export const setContactCreateUpdate = (contact) => (dispatch) => {
  dispatch({
    type: CONTACT_CREATE_UPDATE,
    payload: contact,
  });
};

export const createOrUpdate = (id, params) => async (dispatch, getState) => {
  dispatch({ type: CONTACT_FETCHING });

  try {
    let req = id
      ? axios.post(`contact/update/${id}`, params)
      : axios.post("contact/create", params);

    const resCreate = await req;

    dispatch({
      type: CONTACT_CREATE_UPDATE,
      payload: {
        data: resCreate.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
        isSetData: true,
      },
    });

    const dataBody = {
      searchCommon: "",
      sortBy: "",
      rowsPerPage: 999999,
    };

    const res = await axios.post("contact/list", dataBody);

    dispatch({
      type: CONTACT_FETCHED,
      payload: {
        data: res.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });
  } catch (error) {
    const data = getState().accountData.contact.contacts;
    dispatch({
      type: CONTACT_FETCHED,
      payload: data,
    });
    if (error?.response?.data?.status === -1) {
      history.push({
        // pathname: "/profile/upgrade",
        state: { isCreated: true, type: "người dùng" },
      });
    } else {
      const arrayError = [];
      if (error.response.data?.validMsgList?.email !== undefined) {
        arrayError.push(error.response.data?.validMsgList?.email?.join(","));
      }
      if (error.response.data?.validMsgList?.phone !== undefined) {
        arrayError.push(error.response.data?.validMsgList?.phone?.join(","));
      }
      const contentError = arrayError.join(",");
      notificationPopup("error", contentError);
      throw error;
    }
  }
};

export const getContactById = (id) => async (dispatch) => {
  dispatch({ type: CONTACT_FETCHING });

  try {
    const res = await axios.get(`contact/detail/${id}`);
    if (res.data.status === 200) {
      dispatch({
        type: CONTACT_SET,
        payload: { data: res.data.data, isShowModal: true },
      });
    } else {
      notificationPopup("error", res.data.message);
    }
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const remove = (id) => async (dispatch, getState) => {
  dispatch({ type: CONTACT_FETCHING });

  const { data } = getState().contactData.contact.contacts;
  data.content = data.content.filter((e) => e.id !== id);

  try {
    const res = await axios.get(`contact/delete/${id}`);

    dispatch({
      type: CONTACT_FETCHED,
      payload: {
        data: data,
        message: res.data.message,
        status: res.data.status,
      },
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const removeContacts = (param) => async (dispatch) => {
  dispatch({ type: CONTACT_FETCHING });

  try {
    const res = await axios.post(`contact/delete-by-list/`, param);
    dispatch({
      type: CONTACT_REMOVES,
      payload: { status: res.data.status, message: res.data.status },
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const fetch = (params) => async (dispatch) => {
  dispatch({ type: CONTACT_FETCHING });
  try {
    const dataBody = {
      ...params,
    };
    const res = await axios.post("contact/list", dataBody);

    dispatch({
      type: CONTACT_FETCHED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const count = (params) => async (dispatch) => {
  dispatch({ type: CONTACT_FETCHING });
  try {
    const dataBody = {
      ...params,
    };
    const res = await axios.post("contact/list", dataBody);

    dispatch({
      type: CONTACT_COUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const resetUpdate = () => (dispatch) =>
  dispatch({ type: CONTACT_RESET_UPDATE });
export const reset = () => (dispatch) => dispatch({ type: CONTACT_RESET });
export const resetRemoveLocation = () => (dispatch) =>
  dispatch({ type: RESET_CONTACT_REMOVES });
