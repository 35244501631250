import React from "react";
import Provider from "react-redux/es/components/Provider";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";

import history from "@history";
import "animate.css/animate.min.css";
import "assets/scss/weatherplus-admin.scss?v=1.9.0";
import "app/i18n/i18n.config";
import Layout from "app/layout/layout";

import AppContext from "./app_context";
import routes from "./configs/routes.config";
import store from "./store";
import "moment/locale/vi";
import RenderOnAuthenticated from "./auth-keycloak/render_on_authenticated";
// import KeycloakAuthentication from "./auth-keycloak/KeycloakAuthentication";
import LoginPage from "./main/auth/login";
import AuthConfig from "./main/auth/auth.config";
import LandingPage from "./main/landing_page/landing-page.config";
import Home from "./main/landing_page/home.config";

const App = (props) =>
  AuthConfig.guestPath.indexOf(props.path) === -1 &&
  LandingPage.guestPath.indexOf(props.path) === -1 &&
  Home.guestPath.indexOf(props.path) === -1 ? (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <BrowserRouter>
          {/* <KeycloakAuthentication> */}
          <RenderOnAuthenticated>
            <Router history={history}>
              <Layout />
            </Router>
          </RenderOnAuthenticated>
          {/* </KeycloakAuthentication> */}
        </BrowserRouter>
      </Provider>
    </AppContext.Provider>
  ) : (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <BrowserRouter>
          {/* <KeycloakAuthentication> */}
          {/* <RenderOnAuthenticated> */}
          <Router history={history}>
            <Layout />
          </Router>
          {/* </RenderOnAuthenticated> */}
          {/* </KeycloakAuthentication> */}
          <Switch>
            <Route exact path="/login" component={LoginPage}></Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </AppContext.Provider>
  );

export default App;
