const locale = {
  ListAlerts: "Danh sách gửi cảnh báo",
  ButtonContactManagement: "Quản lý người dùng",
  ButtonAddAlert: "Thêm mới",
  ButtonCreateAlert: "Thêm gửi cảnh báo mới",
  SearchToolTipTitle: "Tìm kiếm danh sách các cảnh báo theo tên",
  SearchLabelCommon: "Tìm kiếm",
  SearchPlaceholderCommon: "Tìm kiếm theo tên",
  SearchLabelInsight: "Cảnh báo",
  SearchPlaceholderInsight: "Tìm kiếm theo cảnh báo",
  SearchButton: "Tìm kiếm",
  TableTitleColumnName: "Danh sách gửi cảnh báo",
  TableTitleColumnAlertContent: "Nội dung",
  TableTitleColumnStatus: "Trạng thái",
  TableTitleColumnPoints: "Địa điểm cảnh báo",
  TableTitleColumnContacts: "Người nhận cảnh báo",
  TableTitleColumnAction: "Tác vụ",
  TableTitleColumnActionToolTipEdit: "Chỉnh sửa",
  TableTitleColumnActionToolTipDelete: "Xóa",
  TableTitleColumnActionToolTipDeleteConfirm: "Bạn chắc chắn muốn xoá?",
  TableTitleColumnActionToolTipDeleteCancel: "Không",
  CreateTitleHeader: "Thêm gửi cảnh báo",
  EditTitleHeader: "Sửa gửi cảnh báo",
  FormLabelName: "Tên thông báo",
  FormMessageName: "Vui lòng nhập tên thông báo",
  FormPlaceholderName: "Nhập tên thông báo",
  FormPlaceholderNameThunderstorms: "Cảnh báo mưa dông, sét cho địa điểm",
  FormLabelInsight: "Cảnh báo nguồn",
  FormMessageInsight: "Vui lòng chọn cảnh báo nguồn",
  FormPlaceholderInsight: "Chọn cảnh báo nguồn",
  FormPlaceholderInsightThunderstorms: "Cảnh báo mưa dông sét",
  FormLabelChannelAlert: "Kênh gửi cảnh báo",
  FormMessageChannelAlert: "Vui lòng chọn kênh gửi cảnh báo",
  FormPlaceholderChannelAlert: "Email Zalo",
  FormLabelBeforeTime: "Cảnh báo trước",
  FormMessageBeforeTime: "Vui lòng chọn thời gian cảnh báo trước",
  FormPlaceholderBeforeTime: "12 giờ",
  FormPlaceholderBeforeTimeThunderstorms: "Tùy theo hạn cảnh báo của gói",
  FormLabelContentAlert: "Nội dung cảnh báo",
  FormMessageContentAlert: "Vui lòng nhập nội dung cảnh báo",
  FormPlaceholderContentAlert: "Nhập nội dung cảnh báo",
  FormPlaceholderContentAlertThunderstorms:
    "Nội dung cảnh báo sẽ được hệ thống tự động sinh ra",
  FormLabelPoints: "Địa điểm",
  FormMessagePoints: "Nhập địa điểm cần gửi cảnh báo",
  FormPlaceholderPoints: "Nhập địa điểm cần gửi cảnh báo",
  FormLabelContacts: "Người nhận cảnh báo",
  FormMessageContacts: "Vui lòng chọn người dùng",
  FormPlaceholderContacts: "Chọn người nhận cảnh báo",
  FormLabelStatus: "Trạng thái",
  FormCheckBoxStatus: "Kích hoạt",
  FormCheckBoxStatusOff: "Ngừng kích hoạt",
  ButtonCancel: "Hủy bỏ",
  ButtonCreate: "Thêm",
  ButtonEdit: "Lưu",
  NoData: "Không có dữ liệu",
};

export default locale;
