import React from "react";

import { authRoles } from "app/auth";

import vi from "./i18n/vi";
import en from "./i18n/en";
import { isMobile } from "react-device-detect";
import i18next from "i18next";

i18next.addResourceBundle("vi", "help", vi);
i18next.addResourceBundle("en", "help", en);

const HelpConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/help",
      exact: true,
      component: React.lazy(() =>
        isMobile ? import("./helps.mobile") : import("./helps")
      ),
    },
  ],
};

export default HelpConfig;
