import React, { useEffect, useState } from "react";
import Utils from "app/helpers/utils";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin } from "antd";
import imgTickUsed from "../../../../assets/img/icon_new/tickUsed.png";
// import imgFrame from "../../../../assets/icon/Frame.svg";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import "../styles/profile.scss";
import * as InsightActions from "../../insights/store/actions";
import { useDispatch } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import Avatar from "assets/img/icon_new/Avatar.png";
import EditAvatar from "assets/img/icon_new/edit_avatar.svg";

const AlertExceedContent = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("profile");
  const [infoUser, setInfoUser] = useState({
    fullName: "",
    emailUser: "",
    phoneUser: "",
  });
  const {
    data,
    fetching,
    handleClickClosePopupInfo,
    dataContact,
    dataLocation,
    dataInsight,
  } = props;

  const clickButtonUpgrade = () => {
    history.push("/profile/upgrade");
    handleClickClosePopupInfo();
    dispatch(
      InsightActions.setInsight({
        data: null,
        isShowModal: false,
        isCopy: false,
      })
    );
  };
  useEffect(() => {
    dataContact?.data?.content?.map((detail) => {
      if (detail.userId === data?.userDetail?.id) {
        setInfoUser({
          fullName: detail.name,
          emailUser: detail.email,
          phoneUser: detail.phoneZalo,
        });
      }
    });
  }, [dataContact.data.content, data.userDetail]);
  return (
    <Spin spinning={fetching}>
      <Row className={"profile-body"}>
        <Col span={24} className={"profile-info-account cl-main-title"}>
          Thông tin tài khoản
        </Col>
        <Col span={24}>
          <Row className={"body-title"}>
            <Col span={24} className={"info-user"}>
              <Row>
                <Col span={24} className={"avatar-user"}>
                  <img src={Avatar} alt={"avatar"} width={80} />
                  <img
                    src={EditAvatar}
                    alt={"edit_avatar"}
                    className={"edit_avatar"}
                  />
                </Col>
                <Col span={24} className={"fullname-user cl-main-title"}>
                  {infoUser.fullName}
                </Col>
                <Col span={24} className={"email-user cl-main-title"}>
                  {infoUser.emailUser}
                </Col>
                <Col span={24} className={"phone-user cl-main-title"}>
                  {infoUser.phoneUser}
                </Col>
              </Row>
            </Col>
            <Col span={isMobileOnly ? 14 : 7} style={{ paddingTop: 15 }}>
              <span className={"package"}>
                {t(data?.packageDetail?.packageName)}
              </span>
            </Col>
            <Col span={isMobileOnly ? 10 : 7} style={{ paddingTop: 15 }}>
              <Row>
                <Col span={24} className="profile-check-used">
                  <img src={imgTickUsed} /> <span>Đang sử dụng</span>
                </Col>
              </Row>
            </Col>
            {isMobileOnly && data?.packageDetail?.packageId !== 0 ? (
              <Col span={24}>
                <span
                  className={
                    moment(data?.packageDetail?.expireDateTime).isBefore(
                      moment()
                    )
                      ? "duration red-color"
                      : "duration"
                  }
                  style={{ paddingBottom: 0 }}
                >
                  Hiệu lực đến:{" "}
                  {moment(data?.packageDetail?.expireDateTime).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </Col>
            ) : (
              ""
            )}
            {data?.packageDetail?.type === 0 ? (
              <Col span={isMobileOnly ? 24 : 10} style={{ paddingTop: 15 }}>
                <Row justify="end">
                  <Col
                    span={isMobileOnly ? 24 : 16}
                    className={"container-button-upgrade"}
                  >
                    <button onClick={() => clickButtonUpgrade()}>
                      <span>{t("Upgrade")}</span>
                    </button>
                  </Col>
                </Row>
              </Col>
            ) : (
              ""
            )}
            {!isMobileOnly && data?.packageDetail?.packageId !== 0 ? (
              <Col span={24}>
                <span
                  className={
                    moment(data?.packageDetail?.expireDateTime).isBefore(
                      moment()
                    )
                      ? "duration red-color"
                      : "duration"
                  }
                >
                  Hiệu lực đến:{" "}
                  {moment(data?.packageDetail?.expireDateTime).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </Col>
            ) : (
              ""
            )}
            {location.state?.isCreated === true && (
              <Row style={{ paddingTop: "32px" }}>
                <Col span={24}>
                  <Alert
                    className="alert-upgrade cl-text-description-insight bg-input-add-insight"
                    message=""
                    // description={
                    //   "Số lượng " +
                    //   location.state?.type +
                    //   " của bạn đã đạt đến giới hạn. Vui lòng nâng cấp gói để sử dụng dịch vụ!"
                    // }
                    description={
                      "Thông báo: bạn đang vượt qua số lượng " +
                      location.state?.type +
                      " của gói hiện tại, mời bạn nâng cấp gói để tiếp tục trải nghiệm."
                    }
                    type="error"
                  />
                </Col>
              </Row>
            )}
            {(data?.packageDetail?.locationCurrent >
              data?.packageDetail?.locationTotal ||
              data?.packageDetail?.insightCurrent >
                data?.packageDetail?.insightTotal ||
              data?.packageDetail?.alertCurrent >
                data?.packageDetail?.alertTotal ||
              data?.packageDetail?.contactCurrent >
                data?.packageDetail?.contactTotal ||
              moment(data?.packageDetail?.expireDateTime).isBefore(moment())) &&
              location.state?.isCreated !== true && (
                <Row style={{ paddingTop: 32 }}>
                  <Col span={24}>
                    <Alert
                      className="alert-upgrade"
                      message="Thông báo:"
                      description="Quyền lợi gói cước của quý khách đã vượt quá mức cho phép, vui lòng nâng cấp gói để sử dụng dịch vụ hoặc điều chỉnh quyền lợi gói cước để tiếp tục sử dụng dịch vụ!"
                      type="error"
                    />
                  </Col>
                </Row>
              )}
          </Row>

          {location.state?.report === true && (
            <Row>
              <Col span={24}>
                <Alert
                  className="alert-upgrade"
                  message="Thông báo:"
                  description={
                    "Chức năng " +
                    location.state?.type +
                    " chỉ dành cho gói Doanh nghiệp. Vui lòng nâng cấp gói để sử dụng dịch vụ!"
                  }
                  type="error"
                />
              </Col>
            </Row>
          )}

          <Row
            className={
              isMobileOnly
                ? "package-benefit package-benefit-mobile"
                : "package-benefit"
            }
          >
            <Col span={24}>
              <Row
                gutter={[16, 16]}
                className={"benefit-contents"}
                style={{ textAlign: "center" }}
              >
                <Col span={isMobileOnly ? 12 : 8}>
                  <Row>
                    <Col span={24}>
                      <span className="body-contents">{t("Location")}</span>
                    </Col>

                    <Col span={24}>
                      <span
                        className={
                          "body-numbers color-number" +
                          (dataLocation?.data?.content?.length >=
                          data?.packageDetail?.locationTotal
                            ? " red-color"
                            : "")
                        }
                      >
                        {dataLocation?.data?.content?.length}
                        <span className="body-number-total">
                          /{data?.packageDetail?.locationTotal}
                        </span>
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col span={isMobileOnly ? 12 : 8}>
                  <Row>
                    <Col span={24}>
                      <span className="body-contents">{t("Insight")}</span>
                    </Col>

                    <Col span={24}>
                      <span
                        className={
                          "body-numbers color-number" +
                          (dataInsight?.data?.content?.length >=
                          data?.packageDetail?.insightTotal
                            ? " red-color"
                            : "")
                        }
                      >
                        {dataInsight?.data?.content?.length}{" "}
                        <span className="body-number-total">
                          /{data?.packageDetail?.insightTotal}
                        </span>
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col span={isMobileOnly ? 12 : 8}>
                  <Row>
                    <Col span={24}>
                      <span className="body-contents">{t("Contact")}</span>
                    </Col>

                    <Col span={24}>
                      <span
                        className={
                          "body-numbers color-number" +
                          // (data?.packageDetail?.contactCurrent >
                          (dataContact?.data?.content?.length >=
                          data?.packageDetail?.contactTotal
                            ? " red-color"
                            : "")
                        }
                      >
                        {dataContact?.data?.content?.length}{" "}
                        <span className="body-number-total">
                          /{data?.packageDetail?.contactTotal}
                        </span>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              style={
                isMobileOnly ? { paddingTop: "10px" } : { paddingTop: "30px" }
              }
            >
              {data?.packageDetail?.packageId === 5 ? (
                <Row gutter={[16, 16]}>
                  <Col span={isMobileOnly ? 24 : 8}>
                    <Row>
                      <Col span={24}>
                        <span className="body-contents">Lượt gọi API</span>
                      </Col>

                      <Col span={24}>
                        <span className="body-numbers cl-main-title">
                          {
                            Utils.getPackageInfo(data?.packageDetail?.packageId)
                              .hourApi
                          }
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : data?.packageDetail?.packageId === 0 ? (
                <Row gutter={[16, 16]}>
                  <Col span={isMobileOnly ? 24 : 8}>
                    <Row>
                      <Col span={24}>
                        <span className="body-contents">Lượt gọi API</span>
                      </Col>

                      <Col span={24}>
                        <span className={"body-numbers cl-main-title"}>
                          {data?.packageDetail?.numberCallApi} lượt
                          <span className="body-number-total"> / ngày</span>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={isMobileOnly ? 24 : 8}>
                    <Row>
                      <Col span={24}>
                        <span
                          className="body-numbers cl-main-title"
                          hidden={
                            Utils.getPackageInfo(data?.packageDetail?.packageId)
                              .hourApi === ""
                          }
                        >
                          {
                            Utils.getPackageInfo(data?.packageDetail?.packageId)
                              .hourApi
                          }{" "}
                          lượt
                          <span
                            className="body-number-total"
                            hidden={
                              Utils.getPackageInfo(
                                data?.packageDetail?.packageId
                              ).hourApi === ""
                            }
                          >
                            / giờ
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col span={isMobileOnly ? 24 : 24}>
                    <Row>
                      <Col span={24}>
                        <span className="body-contents">Lượt gọi API</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={isMobileOnly ? 24 : 24}>
                    <Row>
                      <Col span={isMobileOnly ? 12 : 12}>
                        <span
                          className={"body-numbers cl-main-title"}
                          style={{ margin: "5px 0px 0px 12px" }}
                        >
                          {data?.packageDetail?.numberCallApi} lượt
                          <span className="body-number-total"> / ngày</span>
                        </span>
                      </Col>
                      <Col span={isMobileOnly ? 12 : 12}>
                        <span
                          className="body-numbers cl-main-title"
                          style={{ margin: "5px 0px 0px 12px" }}
                        >
                          {
                            Utils.getPackageInfo(data?.packageDetail?.packageId)
                              .hourApi
                          }{" "}
                          lượt
                          <span
                            className="body-number-total"
                            hidden={
                              Utils.getPackageInfo(
                                data?.packageDetail?.packageId
                              ).hourApi === ""
                            }
                          >
                            / giờ
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default AlertExceedContent;
