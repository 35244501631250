import * as Actions from "../actions/element.action";

const initialState = () => (
    {
        elements: {
            fetching: false,
            data: {
                content: []
            },
            statusCode: null,
            message: null
        }
    });

const insightReducer = (state = initialState(), {type, payload}) => {
    switch (type) {
        case Actions.ELEMENT_FETCHED:
            return {
                ...state,
                elements: {
                    fetching: false,
                    data: {...payload.data},
                    statusCode: payload.status,
                    message: payload.message
                }
            };
        case Actions.ELEMENT_FETCHING:
            return {
                ...state,
                // fetching: true,
            };
        case Actions.ELEMENT_ERROR:
            return {
                ...state,
                fetching: false,
                error: payload,
            };
        case Actions.ELEMENT_RESET:
            return initialState();
        default:
            return state;
    }
};

export default insightReducer;
