const locale = {
  Package: "Package",
  Location: "Location",
  Insight: "Insight",
  Alert: "Alert",
  Contact: "Contact",
  Welcome: "Welcome",
  Phone: "Phone",
  Expire: "Expire",
  Upgrade: "Upgrade",
  PackageBenefits: "Package benefits",
  User: "User",
  COMMUNITY: "COMMUNITY",
  FREE: "Package Free",
  STANDARD: "Package Standard",
  ADVANCED: "Package Advanced",
  ENTERPRISE: "Package Enterprise",
  placeholderPasswordOld: "Nhập mật khẩu cũ",
  labelPasswordEditOld: "Mật khẩu cũ",
  labelPasswordEditNew: "Mật khẩu mới",
  placeholderPassword: "Nhập mật khẩu mới",
  labelPasswordEditConfirm: "Nhập lại mật khẩu mới",
  placeholderPasswordConfirm: "Vui lòng nhập lại mật khẩu của bạn",
};
export default locale;
