const locale = {
  Package: "Gói",
  Location: "Địa điểm",
  Insight: "Khuyến nghị",
  Alert: "API",
  Contact: "Người dùng",
  Welcome: "Chào mừng",
  Phone: "Điện thoại",
  Expire: "Thời hạn",
  Upgrade: "Nâng cấp gói",
  PackageBenefits: "Quyền lợi gói cước",
  User: "Người dùng",
  COMMUNITY: "CỘNG ĐỒNG",
  FREE: "Gói dùng thử",
  STANDARD: "Gói cơ bản",
  ADVANCED: "Gói nâng cao",
  ENTERPRISE: "Gói doanh nghiệp",
  placeholderPasswordOld: "Nhập mật khẩu cũ",
  labelPasswordEditOld: "Mật khẩu cũ",
  labelPasswordEditNew: "Mật khẩu mới",
  placeholderPassword: "Nhập mật khẩu mới",
  labelPasswordEditConfirm: "Nhập lại mật khẩu mới",
  placeholderPasswordConfirm: "Vui lòng nhập lại mật khẩu của bạn",
};
export default locale;
