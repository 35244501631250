import { Col, Menu, Row } from "antd";
import React from "react";
import "./menu.scss";
import constants from "app/helpers/constants";
import AuthService from "../../../app/auth-keycloak/authservice";
import Avatar from "../../../assets/img/icon_new/Avatar.png";
// import Profile from "../../../app/main/profile/profile";
import UserImg from "../../../assets/img/icon_new/user.png";
import LightDark from "../../../assets/img/icon_new/light_dark.svg";

export const menu = (history, t, dataUser) => {
  const handeChangeMode = () => {
    if (localStorage.getItem("primaryThemes") !== "1") {
      localStorage.setItem("primaryThemes", "1");
    } else {
      localStorage.setItem("primaryThemes", "0");
    }
    window.location.reload();
  };
  return (
    <Menu className={"menu-account bg-cl-header"} style={{ marginTop: 20 }}>
      <Menu.Item
        className="option"
        onClick={() => {
          history.push("/profile");
        }}
      >
        <Row>
          <Col span={3} style={{ marginRight: "30px" }}>
            <img src={Avatar} alt={"avatar"} width="48px" />
          </Col>
          <Col span={15} style={{ paddingTop: "10px" }}>
            <Row>
              <Col span={24} className="text-header-dropdown cl-main-title">
                {dataUser?.userDetail?.firstName !== null
                  ? dataUser?.userDetail?.firstName +
                    " " +
                    dataUser?.userDetail?.lastName
                  : dataUser?.userDetail?.username}
              </Col>
              <Col
                span={24}
                className="text-header-dropdown-email cl-text-description-insight"
              >
                {dataUser?.userDetail?.email !== null
                  ? dataUser?.userDetail?.email
                  : dataUser?.userDetail?.username}
              </Col>
            </Row>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          history.push("/profile");
        }}
      >
        <img src={UserImg} alt="User" width="20px" height="20px" />

        <span style={{ display: "inline-block" }} className="ml-10 mt-5">
          {t("userInfo")}
        </span>
      </Menu.Item>
      {/*<Profile isModalVisibleProfile1={isModalVisibleProfile} handleClickAvatar1={handleClickAvatar}/>*/}
      {constants.PROFILE_MENU_ITEMS.map((item, idx) => [
        idx === 1 ? (
          <Menu.Item
            className={"option"}
            key={String(idx)}
            onClick={handeChangeMode}
          >
            <img
              src={LightDark}
              alt={"ChangeLightDark"}
              width={20}
              height={20}
            />
            <span style={{ display: "inline-block" }} className="ml-10 mt-5">
              Chuyển giao diện{" "}
              {localStorage.getItem("primaryThemes") !== "1" ? " tối" : " sáng"}
            </span>
          </Menu.Item>
        ) : (
          <Menu.Item
            className="option"
            key={String(idx)}
            onClick={() => {
              switch (item.function) {
                case "logout":
                  AuthService.doLogout();
                  // history.replace("/auth/logout");
                  break;
                default:
                  history.push(item.url);
                  break;
              }
            }}
          >
            {item.icon}
            <span style={{ display: "inline-block" }} className="ml-10 mt-5">
              {t(item.name)}
            </span>
          </Menu.Item>
        ),
      ])}
    </Menu>
  );
};
