import React, { useEffect } from "react";
import { Avatar, Button, Col, Form, Row, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "../../main/account/store/actions";

const tailLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option, OptGroup } = Select;

const Steps1 = React.memo((props) => {
  const { data, onSubmit, form } = props;
  const listColor = ["#0D0E10", "#4C449B", "#D3D36E", "#FC7A32", "#EB0713"];
  const { t } = useTranslation("account");
  const dispatch = useDispatch();

  const careers = useSelector((state) => state.accountData.career.careers);
  const key = useSelector((state) => state.accountData.account.key);

  useEffect(() => {
    if (
      sessionStorage.getItem("key") !== null &&
      sessionStorage.getItem("key") !== "undefined"
    ) {
      dispatch(AccountActions.decrypt(sessionStorage.getItem("key")));
    }
  }, [dispatch]);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const validateForm = () => {
    return (
      form.getFieldValue("subSectors") === undefined ||
      form.getFieldValue("subSectors") === "" ||
      form.getFieldError("subSectors").length !== 0
    );
  };

  const onFinish = (values) => {
    let careerConvert = null;
    careers?.data?.content?.forEach((career) => {
      career.subSectors
        .filter((sub) => sub?.id === values.subSectors)
        .forEach(() => {
          careerConvert = career.id;
        });
    });
    onSubmit({ ...values, career: careerConvert });
  };

  return (
    <div style={{ width: 480 }}>
      <Row justify="space-around" align="middle" style={{ marginTop: 10 }}>
        <Col>
          <Avatar
            style={{
              backgroundColor: listColor[randomNumberInRange(0, 4)],
              verticalAlign: "middle",
              fontSize: 50,
            }}
            size={100}
          >
            {data?.packageDetail?.isOneClick === 1
              ? data?.userDetail?.username?.charAt(0).toUpperCase()
              : data?.userDetail?.email?.charAt(0).toUpperCase()}
          </Avatar>
        </Col>
      </Row>
      {data?.packageDetail?.isOneClick !== 1 ? (
        <Row
          style={{ marginTop: 20, marginBottom: 20 }}
          justify="space-around"
          align="middle"
        >
          <Col
            span={20}
            className="font-text font-weight-4 font-size"
            style={{ textAlign: "center", color: "#000000" }}
          >
            {data?.userDetail?.username}
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row style={{ marginTop: 20 }} justify="space-around" align="middle">
        <Col
          className="font-text font-weight-7"
          style={{ fontSize: "20px", color: "#000000" }}
        >
          Chào mừng bạn đến với Metplus
        </Col>
      </Row>
      {data?.packageDetail?.isOneClick === 1 ? (
        <Row style={{ marginTop: 5 }} justify="space-around" align="middle">
          <Spin spinning={key.fetching}>
            <Col
              span={24}
              className="font-text font-weight-6 font-size account-step1 cl-main-title"
              style={{ textAlign: "center" }}
            >
              Tài khoản của bạn: <span>{data?.userDetail?.username}</span> /{" "}
              <span>{key?.data}</span>
            </Col>
          </Spin>
          <Col
            span={20}
            className="font-text font-weight-4 font-size note-step1"
            style={{ textAlign: "center" }}
          >
            (Chú ý: hãy lưu lại tài khoản để đăng nhập lần sau tại
            https://metplus.vn)
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Form
        name="update-information"
        form={form}
        onFinish={onFinish}
        className="style-add-order"
        initialValues={{}}
      >
        <Row
          style={{ marginTop: 15, marginBottom: 20 }}
          justify="space-around"
          align="middle"
        >
          <Col
            span={20}
            className="font-text font-weight-4"
            style={{ textAlign: "center", color: "#000000" }}
          >
            {t("formLabelCareer")}
            <Form.Item
              name="subSectors"
              className="info-edit"
              {...tailLayout}
              rules={[{ required: true, message: "Vui lòng chọn ngành nghề" }]}
            >
              <Select
                className="info-contents-step1"
                placeholder={t("formPlaceholderCareer")}
                dropdownClassName={"select-dropdown-step1"}
              >
                {careers?.data.content?.length > 0 &&
                  careers?.data.content?.map((item) =>
                    item.type !== 2 ? (
                      <OptGroup key={item.id} label={item.name}>
                        {item.subSectors.map((itemSub) => (
                          <Option key={itemSub.id} value={itemSub?.id}>
                            {item.name} - {itemSub.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ) : (
                      item.subSectors.map((itemSub) => (
                        <Option key={itemSub.id} value={itemSub?.id}>
                          {itemSub.name}
                        </Option>
                      ))
                    )
                  )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 20, marginBottom: 20 }}
          justify="space-around"
          align="middle"
        >
          <Col
            className="font-text font-weight-4 font-size"
            style={{ textAlign: "center", color: "#FFFFFF" }}
          >
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  className="button-first mst-onboarding-step-1"
                  type="text"
                  htmlType="submit"
                  disabled={validateForm()}
                >
                  {data?.packageDetail?.isOneClick === 1
                    ? "Tiếp theo"
                    : "Bắt đầu"}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

export default Steps1;
