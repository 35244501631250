// import AddLocation from "./add_location";
import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Empty, Row, Select, Table, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { convertConditionOnboarding, locale } from "../../helpers/common";
import { useTranslation } from "react-i18next";
import tick from "../../../assets/icon/tick.svg";
// import AddLocation from "./add_location";
import { useDispatch, useSelector } from "react-redux";
import * as LocationActions from "../../main/location/store/actions";
import AddLocationNew from "./add_location_new";

const { Option } = Select;

const Steps5 = React.memo((props) => {
  const { prev, infoSteps, next, setInfoSteps, account } = props;
  const { t } = useTranslation("account");
  const [validate, setValidate] = useState(true);
  const dispatch = useDispatch();
  const [disablePoint, setDisablePoint] = useState(false);
  const [checkFocus, setCheckFocus] = useState(false);
  const [isDropdown, setIsDropdown] = useState(true);

  useEffect(() => {
    let exitsPoint = true;
    infoSteps.step5?.insights?.forEach((insight) => {
      if (insight.points.length === 0) {
        exitsPoint = false;
      }
    });
    const listPoint = [];
    infoSteps.step5.insights?.forEach((i) => {
      i.points?.forEach((point) => {
        listPoint.push(point);
      });
    });
    const uniqueListPoint = [
      ...new Map(listPoint.map((item) => [item, item])).values(),
    ];
    setValidate(exitsPoint && uniqueListPoint.length <= 2);
  }, [infoSteps]);

  const { data } = useSelector((state) => state.accountData.location.locations);

  useEffect(() => {
    dispatch(
      LocationActions.fetch({
        searchCommon: "",
        sortBy: "createdAt",
        rowsPerPage: 999999,
      })
    );
  }, [dispatch]);

  const processDataFinal = () => {
    if (account?.packageDetail?.isOneClick !== 1) {
      const dataBody = {
        step2: infoSteps.step2,
        step3: {
          insights: infoSteps.step5.insights.map((insight) => {
            return {
              ...insight,
              id: null,
              type: 1,
              packageInsight: null,
              groupConditions: insight.groupConditions.map((groupCondition) => {
                return {
                  conditions: groupCondition.conditions.map((condition) => {
                    return {
                      elementId: condition.elementId.id,
                      operator: condition.operator,
                      value: condition.value,
                    };
                  }),
                };
              }),
            };
          }),
        },
      };
      dispatch(LocationActions.fetchOnBoarding(dataBody));
    }
    next();
  };

  const onChangePoint = (e, insight) => {
    setInfoSteps({
      ...infoSteps,
      step5: {
        insights: infoSteps.step5.insights.map((i) => {
          if (i.id === insight.id || i.points.length === 0) {
            return {
              ...i,
              points: e,
            };
          }
          return i;
        }),
      },
    });
  };
  const handleClickSelect = () => {
    if (isDropdown) {
      setCheckFocus(true);
    } else {
      setCheckFocus(false);
    }
  };
  const onDropdownVisibleChangePoint = (e) => {
    setDisablePoint(!e);
    setIsDropdown(e);
    if (!e) {
      setCheckFocus(false);
    }
  };

  const columns = [
    {
      title: "Danh sách khuyến nghị của bạn",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <>
          <img alt="tick" src={tick} style={{ marginRight: 10 }} />
          <Tooltip placement="topLeft" title={name}>
            <span
              className="font-text font-weight-6"
              style={{ color: "#000000", fontSize: 16 }}
            >
              {name}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Điều kiện thời tiết",
      dataIndex: "description",
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      render: (description, record) => (
        // <Col
        //   className="font-text color-text font-weight-6"
        //   style={{ fontSize: 16 }}
        // >
        <Tooltip placement="topLeft" title={convertConditionOnboarding(record)}>
          <span style={{ color: "#000000", fontSize: 16 }}>
            {convertConditionOnboarding(record)}
          </span>
        </Tooltip>
        // </Col>
      ),
    },
    {
      title: "Gán vào địa điểm",
      dataIndex: "locations",
      key: "locations",
      render: (text, record) => (
        <>
          <Col
            style={{ padding: 10 }}
            // className="font-text color-text font-weight-4"
          >
            <Select
              mode="multiple"
              className={"alert-input"}
              showSearch
              optionFilterProp="children"
              placeholder={
                !checkFocus ? (
                  <span style={{ color: "rgb(38 38 38)" }}>
                    {t("FormPlaceholderPoint")}
                  </span>
                ) : (
                  ""
                )
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              allowClear
              onChange={(e) => onChangePoint(e, record)}
              dropdownClassName={`dropdown-select-step-popup-first`}
              listHeight={200}
              value={record.points}
              notFoundContent={<Empty description={false} />}
              onClick={() => {
                handleClickSelect();
              }}
              onDropdownVisibleChange={(e) => onDropdownVisibleChangePoint(e)}
              dropdownRender={(menu) => (
                <>
                  <AddLocationNew
                    checkFocus={checkFocus}
                    insight={record}
                    setInfoSteps={setInfoSteps}
                    infoSteps={infoSteps}
                    setDisablePoint={setDisablePoint}
                  />
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
              // open={true}
            >
              {data?.content?.length > 0 &&
                data?.content?.map((item, idx) => (
                  <Option
                    key={String(idx)}
                    value={item.id}
                    disabled={disablePoint}
                  >
                    {item.pointName}
                  </Option>
                ))}
            </Select>
          </Col>
          {/*<Col*/}
          {/*  span={24}*/}
          {/*  className="font-text color-text font-weight-4"*/}
          {/*  style={{ fontSize: 16 }}*/}
          {/*>*/}
          {/*  <AddLocation*/}
          {/*    insight={record}*/}
          {/*    setInfoSteps={setInfoSteps}*/}
          {/*    infoSteps={infoSteps}*/}
          {/*  />*/}
          {/*</Col>*/}
        </>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: 1218 }}>
      <ArrowLeftOutlined
        onClick={() => prev()}
        style={{
          color: "#FFFFFF",
          fontSize: 26,
          position: "absolute",
          top: 40,
        }}
      />
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-7"
          style={{ fontSize: "26px", paddingBottom: 41, color: "#000000" }}
        >
          Gán địa điểm cho các khuyến nghị của bạn
        </Col>
        <Col>
          <Table
            locale={locale(t("NoData"))}
            rowKey="id"
            dataSource={infoSteps.step5?.insights}
            columns={columns}
            scroll={{ y: 500 }}
            pagination={false}
            className="insight-package-table-onboarding table-steps"
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 20, marginBottom: 20 }}
        justify="space-around"
        align="middle"
      >
        <Col
          className="font-text font-weight-4 font-size"
          style={{ textAlign: "center", color: "#ffffff" }}
        >
          <Button
            className="button-first mst-onboarding-step-5"
            type="text"
            disabled={!validate}
            onClick={() => processDataFinal()}
          >
            Tiếp theo
          </Button>
        </Col>
      </Row>
    </div>
  );
});

export default Steps5;
