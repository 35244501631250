const locale = {
  ReportTitle: "Báo cáo",
  ButtonAddLocation: "Thêm địa điểm",
  Weather: "Dự báo thời tiết",
  Weather72h: "Thời tiết 72h tới",
  Weather14Day: "Thời tiết 14 ngày",
  "Forecast information": "Thông tin dự báo",
  Report: "Báo cáo",
  Location: "Địa điểm",
  "Choose Location": "Chọn địa điểm",
  Humidity: "Độ ẩm",
  Wind: "Tốc độ gió",
  "Probability Rain": "Khả năng mưa",
  Precipitation: "Lượng mưa",
  RealFeel: "Cảm nhận",
  Normal: "Bình thường",
  Warning: "Cảnh báo",
  Dangerous: "Nguy hiểm",
  "Cloud cover": "Độ bao phủ mây",
  PreviewReport: "Xem trước báo cáo",
  CancelTextPreviewReport: "Hủy",
  NoData: "Không có dữ liệu",
  Date: "Ngày",
  ReportTableTitleDate: "Thời gian",
  ReportTableTitleFileName: "Tên báo cáo",
  ReportTableTitleFileDownload: "Tải file",
  ReportTableTitleAction: "Tác vụ",

  CreateTitleHeader: "Thêm mới địa điểm",
  ButtonCancel: "Hủy bỏ",
  ButtonCreate: "Thêm điểm",
  PlaceholderAddress: "Nhập địa điểm bạn muốn tìm kiếm",
  MyReport: "Báo cáo của bạn",
  LibraryReport: "Thư viện báo cáo",
  CareerNews: "Bài viết phân tích ngành",

  ContactUsNow: "Hotline: 0822 091 091",
  Download: "Tải về",
  Close: "Đóng",
  Choose: "--------Chọn--------",
};

export default locale;
