import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";

export const ELEMENT_RESET = "ELEMENT_RESET";
export const ELEMENT_ERROR = "ELEMENT_ERROR";

export const ELEMENT_FETCHING = "ELEMENT_FETCHING";
export const ELEMENT_FETCHED = "ELEMENT_FETCHED";

export const fetch = (params) => async (dispatch) => {
  axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
  dispatch({ type: ELEMENT_FETCHING });

  const dataBody = {
    ...params,
  };
  try {
    const res = await axios.post("element/list", dataBody);

    dispatch({
      type: ELEMENT_FETCHED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ELEMENT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: ELEMENT_RESET });
