import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./app/app";
import reportWebVitals from "./reportWebVitals";
import AuthService from "./app/auth-keycloak/authservice";
import HttpService from "./app/auth-keycloak/HttpService";
import AuthConfig from "./app/main/auth/auth.config";
import LandingPage from "./app/main/landing_page/landing-page.config";
import LayoutHome from "./app/main/landing_page/home.config";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ThemeProvider from "./app/layout/home-theme-provider";

if (AuthConfig.guestPath.indexOf(window.location.pathname) === -1) {
  if (LandingPage.guestPath.indexOf(window.location.pathname) === -1) {
    if (LayoutHome.guestPath.indexOf(window.location.pathname) === -1) {
      const app = () => {
        ReactDOM.render(
          <React.StrictMode>
            <ThemeProvider>
              <App path={window.location.pathname} />
            </ThemeProvider>
          </React.StrictMode>,
          document.getElementById("root")
        );
      };
      AuthService.initKeycloak(app);
    } else {
      ReactDOM.render(
        <GoogleReCaptchaProvider reCaptchaKey="6Ley_DogAAAAANNMXPuIvNtaPzFJK6a5-a19wgrF">
          <React.StrictMode>
            <ThemeProvider>
              <App path={window.location.pathname} />
            </ThemeProvider>
          </React.StrictMode>
        </GoogleReCaptchaProvider>,
        document.getElementById("root")
      );
    }
  } else {
    ReactDOM.render(
      <GoogleReCaptchaProvider reCaptchaKey="6Ley_DogAAAAANNMXPuIvNtaPzFJK6a5-a19wgrF">
        <App path={window.location.pathname} />
      </GoogleReCaptchaProvider>,
      document.getElementById("root")
    );
  }
} else {
  ReactDOM.render(
    <GoogleReCaptchaProvider reCaptchaKey="6Ley_DogAAAAANNMXPuIvNtaPzFJK6a5-a19wgrF">
      <App path={window.location.pathname} />
    </GoogleReCaptchaProvider>,
    document.getElementById("root")
  );
}
HttpService.configure();

reportWebVitals();
