import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";
import history from "@history";
import { notificationPopup } from "../../../../helpers/common";

axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;

export const INSIGHT_RESET = "INSIGHT_RESET";
export const INSIGHT_ERROR = "INSIGHT_ERROR";
export const INSIGHT_FETCHING = "INSIGHT_FETCHING";
export const INSIGHT_FETCHING_ADMIN = "INSIGHT_FETCHING_ADMIN";
export const INSIGHT_FETCHED = "INSIGHT_FETCHED";
export const INSIGHT_COUNT = "INSIGHT_COUNT";
export const INSIGHT_SET = "INSIGHT_SET";
export const INSIGHT_SET_DETAIL = "INSIGHT_SET_DETAIL";
export const INSIGHT_SET_DETAIL_FETCHING = "INSIGHT_SET_DETAIL_FETCHING";
export const INSIGHT_ON_BOARDING = "INSIGHT_ON_BOARDING";
export const INSIGHT_REMOVES = "INSIGHT_REMOVES";
export const RESET_INSIGHT_REMOVES = "RESET_INSIGHT_REMOVES";
export const LOAD_DASHBOARD = "LOAD_DASHBOARD";

export const CAREER_RESET = "CAREER_RESET";
export const CAREER_ERROR = "CAREER_ERROR";
export const CAREER_FETCHING = "CAREER_FETCHING";
export const CAREER_FETCHED = "CAREER_FETCHED";
export const CAREER_SET = "CAREER_SET";

export const PACKAGE_INSIGHT_SET = "PACKAGE_INSIGHT_SET";

export const setInsightOnBoarding = (insight) => (dispatch) => {
  dispatch({
    type: INSIGHT_ON_BOARDING,
    payload: insight,
  });
};

export const setLoadDashboard = (isFormDashboard) => (dispatch) => {
  dispatch({
    type: LOAD_DASHBOARD,
    payload: {
      isFormDashboard,
    },
  });
};

export const setInsight = (insight) => (dispatch) => {
  dispatch({
    type: INSIGHT_SET,
    payload: insight,
  });
};
export const editInsight = (id, isContact, isShowModal) => async (dispatch) => {
  dispatch({ type: INSIGHT_FETCHING });

  try {
    const res = await axios.get(`insight/detail/${id}`);
    if (res.data.status === 200) {
      dispatch({
        type: INSIGHT_SET,
        payload: {
          data: res.data.data,
          isShowModal: isShowModal,
          isCopy: false,
          isContact,
        },
      });
    } else {
      notificationPopup("error", res.data.message);
    }
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const getDetailInsight =
  (id, isContact, isShowModal) => async (dispatch) => {
    dispatch({ type: INSIGHT_SET_DETAIL_FETCHING });

    try {
      const res = await axios.get(`insight/detail/${id}`);
      if (res.data.status === 200) {
        dispatch({
          type: INSIGHT_SET_DETAIL,
          payload: {
            data: res.data.data,
            isShowModal: isShowModal,
            isCopy: false,
            isContact,
          },
        });
      } else {
        notificationPopup("error", res.data.message);
      }
    } catch (error) {
      dispatch({
        type: INSIGHT_ERROR,
        payload: error,
      });

      throw error;
    }
  };

export const setPackageInsight = (packageInsight) => async (dispatch) => {
  try {
    if (packageInsight.id != null) {
      const res = await axios.get(
        `package-insight/detail/${packageInsight.id}`
      );
      if (res.data.status === 200) {
        dispatch({
          type: PACKAGE_INSIGHT_SET,
          payload: {
            data: { ...res.data.data, careerName: packageInsight.careerName },
            isShowPackageDetail: packageInsight.isShowPackageDetail,
            isOnBoarding: packageInsight.isOnBoarding,
          },
        });
      } else {
        notificationPopup("error", res.data.message);
      }
    } else {
      dispatch({
        type: PACKAGE_INSIGHT_SET,
        payload: packageInsight,
      });
    }
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const createOrUpdate =
  (id, params, root) => async (dispatch, getState) => {
    dispatch({
      type: INSIGHT_FETCHED,
      payload: { ...getState().accountData?.insight?.insights, fetching: true },
    });
    const editInsight = getState().accountData.insight.editInsight;
    editInsight.isShowModal = false;
    try {
      let req = id
        ? axios.post(`insight/update/${id}`, params)
        : axios.post("insight/create", params);

      const resCreate = await req;

      let dataBody;
      let url;
      if (root === "admin") {
        dispatch({ type: INSIGHT_FETCHING_ADMIN });
        url = "insight/admin/list";
        dataBody = {
          searchCommon: "",
          type: 2,
          page: 1,
          sortBy: "name",
          rowsPerPage: 10,
        };
        delete dataBody.root;
      } else {
        dispatch({ type: INSIGHT_FETCHING });
        url = "insight/list";
        dataBody = {
          searchCommon: "",
          type: 1,
          sortBy: "name",
          rowsPerPage: 999999,
        };
        delete dataBody.root;
        delete dataBody.page;
      }

      const res = await axios.post(url, dataBody);

      dispatch({
        type: INSIGHT_FETCHED,
        payload: {
          fetching: false,
          data: res.data.data,
          message: resCreate.data.message,
          status: resCreate.data.status,
        },
      });
      if (resCreate.data.status === 200 && params.isFormDashboard) {
        dispatch({
          type: LOAD_DASHBOARD,
          payload: {
            isFormDashboard: true,
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === -1) {
        history.push({
          // pathname: "/profile/upgrade",
          state: { isCreated: true, type: "khuyến nghị", check: true },
        });
      } else {
        dispatch({
          type: INSIGHT_ERROR,
          payload: error,
        });

        window.alert(error);
        throw error;
      }
    }
  };

export const remove = (id, root) => async (dispatch, getState) => {
  dispatch({
    type: INSIGHT_FETCHED,
    payload: { ...getState().insightData.insight.insights, fetching: true },
  });

  try {
    const res = await axios.get(`insight/delete/${id}`);

    if (res.data.status === 200) {
      let dataBody;
      let url;
      if (root === "admin") {
        dispatch({ type: INSIGHT_FETCHING_ADMIN });
        url = "insight/admin/list";
        dataBody = {
          searchCommon: "",
          type: 2,
          page: 1,
          sortBy: "name",
          rowsPerPage: 10,
        };
        delete dataBody.root;
      } else {
        dispatch({ type: INSIGHT_FETCHING });
        url = "insight/list";
        dataBody = {
          searchCommon: "",
          type: 1,
          sortBy: "name",
          rowsPerPage: 999999,
        };
        delete dataBody.root;
        delete dataBody.page;
      }

      const res = await axios.post(url, dataBody);

      dispatch({
        type: INSIGHT_FETCHED,
        payload: {
          fetching: false,
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};
export const removeList = (param) => async (dispatch) => {
  dispatch({ type: INSIGHT_FETCHING });

  try {
    const res = await axios.post(`insight/delete-by-list/`, param);
    dispatch({
      type: INSIGHT_REMOVES,
      payload: { status: res.data.status, message: res.data.status },
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};
export const fetch = (params) => async (dispatch) => {
  let url;
  let dataBody;
  if (params.root === "admin") {
    dispatch({ type: INSIGHT_FETCHING_ADMIN });
    url = "insight/admin/list";
    dataBody = {
      ...params,
    };
    delete dataBody.root;
  } else {
    dispatch({ type: INSIGHT_FETCHING });
    url = "insight/list";
    dataBody = {
      ...params,
    };
    delete dataBody.root;
    delete dataBody.page;
  }

  try {
    const res = await axios.post(url, dataBody);

    dispatch({
      type: INSIGHT_FETCHED,
      payload: { ...res.data, fetching: false },
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const fetchAdmin = (params) => async (dispatch) => {
  dispatch({ type: INSIGHT_FETCHING_ADMIN });

  const dataBody = {
    ...params,
  };

  try {
    const res = await axios.post("insight/admin/list", dataBody);

    dispatch({
      type: INSIGHT_FETCHED,
      payload: { ...res.data, fetching: false },
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const count = (params) => async (dispatch) => {
  dispatch({ type: INSIGHT_FETCHING });

  const dataBody = {
    ...params,
  };

  try {
    const res = await axios.post("insight/list", dataBody);

    dispatch({
      type: INSIGHT_COUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const fetchCareer = (params) => async (dispatch) => {
  dispatch({ type: CAREER_FETCHING });

  const dataBody = {
    ...params,
  };

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.post("career/list", dataBody);

    dispatch({
      type: CAREER_FETCHED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CAREER_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: INSIGHT_RESET });
export const resetCareer = () => (dispatch) => dispatch({ type: CAREER_RESET });
export const resetRemoveInsights = () => (dispatch) =>
  dispatch({ type: RESET_INSIGHT_REMOVES });
