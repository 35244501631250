import React, { lazy, Suspense } from "react";

export const HomeThemeProvider = ({ children }) => {
  const DarkTheme = lazy(() => import("./dark-theme"));
  const LightTheme = lazy(() => import("./light-theme"));
  return (
    <>
      <Suspense fallback={<span />}>
        {localStorage.getItem("primaryThemes") === "1" ? (
          <DarkTheme />
        ) : (
          <LightTheme />
        )}
        {children}
      </Suspense>
    </>
  );
};

export default HomeThemeProvider;
