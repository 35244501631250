const locale = {
  contactName: "Danh sách các người dùng",
  contactLib: "Thư viện khuyến nghị",
  contactAdd: "Thêm mới người dùng",
  contactEdit: "Sửa thông tin người dùng",
  contactButtonAdd: "Tạo",
  contactButtonEdit: "Lưu",
  contactButtonCancel: "Huỷ",
  labelName: "Họ và tên",
  messageName: "Vui lòng nhập tên người dùng",
  placeholderName: "Nhập tên người dùng",
  labelEmail: "Email (Nhập chính xác để nhận cảnh báo qua email)",
  messageEmail: "Vui lòng nhập email",
  messageValidateEmail: "E-mail không đúng định dạng",
  placeholderEmail: "Nhập email",
  labelPhone: "Số điện thoại đăng nhập",
  messagePhone: "Vui lòng nhập số điện thoại",
  messageValidatePhone: "Số điện thoại định dạng chưa đúng",
  placeholderPhone: "Nhập số điện thoại đăng nhập",
  labelPhoneZalo: "Số điện thoại sử dụng zalo (để nhận được cảnh báo qua zalo)",
  placeholderPhoneZalo: "Để trống nếu trùng số điện thoại đăng nhập",
  labelTelco: "Nhà mạng",
  messageTelco: "Vui lòng chọn nhà mạng",
  placeholderTelco: "Chọn nhà mạng",
  labelPoints: "Địa điểm hiệu lực (để trống là được truy cập tất cả)",
  placeholderPoints: "Chọn địa điểm",
  labelPassword: "Mật khẩu (nếu không nhập hệ thống sẽ tạo tự động)",
  labelPasswordConfirm: "Nhập lại mật khẩu",
  labelPasswordEdit: "Mật khẩu (hệ thống sẽ gửi email đến người dùng)",
  placeholderPassword: "Nhập mật khẩu",
  placeholderPasswordOld: "Nhập mật khẩu cũ",
  placeholderPasswordConfirm: "Vui lòng nhập lại mật khẩu của bạn",
  labelStatus: "Trạng thái",
  valueStatus: "Kích hoạt",
  FormCheckBoxStatusOff: "Deactivated",
  buttonAddContact: "Add new",
  SearchToolTipTitle: "Search list of contacts by name",
  SearchLabelCommon: "Search",
  SearchPlaceholderCommon: "Search by name",
  SearchButton: "Search",
  TableColumnName: "First and last name",
  TableColumnUserName: "Username",
  TableColumnEmail: "Email",
  TableColumnPhone: "Phone",
  TableColumnTelco: "Telco",
  TableColumnType: "Loại tài khoản",
  TableColumnStatus: "Status",
  TableColumnAction: "Action",
  TableColumnActionEdit: "Edit",
  TableColumnActionDelete: "Delete",
  TableColumnActionDeleteConfirm: "Are you sure you want to delete?",
  TableColumnActionDeleteConfirmYes: "Yes",
  TableColumnActionDeleteConfirmNo: "No",
  labelPasswordNow: "Mật khẩu hiện tại",
  labelPasswordEditConfirm: "Nhập lại mật khẩu mới",
  labelPasswordEditNew: "Mật khẩu mới",
  labelPasswordEditOld: "Mật khẩu cũ",
  placeholderPasswordEdit: "Nhập mật khẩu hiện tại",
  placeholderPasswordEditNew: "Nhập mật khẩu mới",
  placeholderPasswordEditNewConfirm: "Xác nhận lại mật khẩu mới",
  NoData: "No Data",
};

export default locale;
