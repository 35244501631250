import * as Actions from "../actions";

const initialState = () => ({
  info: {
    fetching: true,
    data: null,
    statusCode: null,
    message: null,
  },
  infoUpdate: {
    message: null,
    statusUpdate: null,
  },
  statusCode: null,
});

const infoReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.INFO_UPDATE:
      return {
        ...state,
        infoUpdate: {
          message: payload.message,
          statusUpdate: payload.statusUpdate,
          data: { ...payload.data },
        },
        statusCode: payload.statusUpdate,
      };
    case Actions.INFO_DETAIL:
      return {
        ...state,
        info: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
        statusCode: payload.status,
      };
    case Actions.INFO_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case Actions.INFO_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.INFO_RESET:
      return {
        ...state,
        infoUpdate: {
          message: null,
          statusUpdate: null,
        },
        statusCode: null,
      };
    default:
      return state;
  }
};

export default infoReducer;
