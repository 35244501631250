import React, { useEffect } from "react";
import "./styles/profile.scss";
import { Modal } from "antd";
import * as AccountActions from "../account/store/actions";
import * as ContactActions from "../contact/store/actions";
import * as LocationActions from "../location/store/actions";
import * as InsightActions from "../insights/store/actions";
import * as ReportActions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../store/with_reducer";
import reducer from "../account/store/reducers";
import AlertExceedContent from "./components/alert_exceed_content";
// import * as LocationActions from "../location/store/actions";

const AlertExceedPackage = (props) => {
  const { isModalVisibleProfile, handleClickClosePopupInfo } = props;
  // const dataBody = { searchCommon: "",
  //                     sortBy: "",
  //                     status: 1,
  //                     rowsPerPage: 999999,}
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AccountActions.getAccountById());
    dispatch(ReportActions.getAllReportFrequency());
    dispatch(
      ContactActions.fetch({
        searchCommon: "",
        sortBy: "",
        status: "",
        rowsPerPage: 999999,
      })
    );
    dispatch(
      LocationActions.fetch({
        searchCommon: "",
        sortBy: "",
        status: 1,
        rowsPerPage: 999999,
      })
    );
    dispatch(
      InsightActions.fetch({
        searchCommon: "",
        sortBy: "",
        status: 1,
        rowsPerPage: 999999,
      })
    );
  }, [dispatch]);

  const { fetching, data } = useSelector(
    (state) => state.accountData.account.account
  );
  const dataContact = useSelector(
    (state) => state.accountData.contact.contacts
  );
  const dataLocation = useSelector(
    (state) => state.accountData.location.locations
  );
  const dataInsight = useSelector(
    (state) => state.accountData.insight.insights
  );

  return (
    <Modal
      visible={isModalVisibleProfile}
      onCancel={handleClickClosePopupInfo}
      className="profile-user-modal"
      width={"600px"}
      centered={true}
      zIndex={100001}
    >
      <AlertExceedContent
        data={data}
        dataContact={dataContact}
        dataLocation={dataLocation}
        dataInsight={dataInsight}
        fetching={fetching}
        handleClickClosePopupInfo={handleClickClosePopupInfo}
      />
    </Modal>
  );
};
export default withReducer("accountData", reducer)(AlertExceedPackage);
