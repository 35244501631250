import * as Actions from "../actions";

const initialState = () => ({
  contacts: {
    fetching: true,
    data: {
      content: [],
    },
    statusCode: null,
    message: null,
  },
  countData: null,
  editContact: {
    fetching: false,
    data: null,
    isShowModal: false,
  },
  removeContacts: {
    fetching: true,
    statusCode: null,
    message: null,
  },
  contactCreateUpdate: {
    fetching: true,
    data: null,
    statusCode: null,
    message: null,
    isSetData: false,
  },
});

const reducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.CONTACT_SET:
      return {
        ...state,
        editContact: {
          fetching: false,
          data: payload.data,
          isShowModal: payload.isShowModal,
        },
        contacts: {
          ...state.contacts,
          fetching: false,
        },
      };
    case Actions.CONTACT_CREATE_UPDATE:
      return {
        ...state,
        contactCreateUpdate: {
          fetching: false,
          data: payload.data,
          statusCode: payload.status,
          message: payload.message,
          isSetData: payload.isSetData,
        },
      };
    case Actions.CONTACT_FETCHED:
      return {
        ...state,
        contacts: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.CONTACT_FETCHING:
      return {
        ...state,
        fetching: true,
        contactCreateUpdate: {
          ...state.contactCreateUpdate,
          fetching: true,
        },
        contacts: {
          ...state.contacts,
          fetching: true,
        },
      };
    case Actions.CONTACT_COUNT:
      return {
        ...state,
        fetching: true,
        countData: payload.data.content?.length,
      };
    case Actions.CONTACT_REMOVES:
      return {
        ...state,
        removeContacts: {
          fetching: false,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.RESET_CONTACT_REMOVES:
      return {
        ...state,
        removeContacts: {
          fetching: true,
          statusCode: null,
          message: null,
        },
      };
    case Actions.CONTACT_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.CONTACT_RESET:
      return initialState();
    case Actions.CONTACT_RESET_UPDATE:
      return {
        ...state,
        contactCreateUpdate: {
          fetching: true,
          data: null,
          statusCode: null,
          message: null,
          isSetData: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
