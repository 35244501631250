import React, { useEffect } from "react";
import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AccountActions from "../../main/account/store/actions";
import * as DashboardActions from "../../main/dashboard/store/actions";
import InfoGraphic from "./infographic";

const Steps6Mobile = React.memo((props) => {
  const { setIsModalVisible } = props;
  const history = useHistory();
  const onBoarding = useSelector(
    (state) => state.accountData.location.onBoarding
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (!onBoarding.fetching) {
        dispatch(AccountActions.getAccountById());
        history.push("/dashboards");
        setIsModalVisible(onBoarding.fetching);
        dispatch(
          DashboardActions.fetch({
            sortBy: "",
            rowsPerPage: 999999,
          })
        );
        dispatch(DashboardActions.isOnBoarding(true));
      }
    }, 10000);
  }, [onBoarding, setIsModalVisible, history, dispatch]);

  return (
    <div>
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-7"
          style={{ fontSize: "26px", paddingTop: 20, color: "#000000" }}
        >
          Chúc mừng!
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-5"
          style={{
            fontSize: "22px",
            paddingBottom: 41,
            textAlign: "center",
            color: "#000000",
          }}
        >
          Bạn đã hoàn thành thiết lập ban đầu!
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col
          span={20}
          className="font-text font-weight-4"
          style={{
            fontSize: "18px",
            color: "#000000",
            textAlign: "center",
            paddingBottom: 20,
          }}
        >
          Metplus đang thiết lập Bảng khuyến nghị hành động cho bạn
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col
          span={20}
          className="font-text font-weight-4"
          style={{
            fontSize: "18px",
            textAlign: "center",
            paddingBottom: 31,
            color: "#000000",
          }}
        >
          Hãy xem 1 số tính năng tuyệt vời của Metplus nào...
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col
          span={20}
          className="font-text font-weight-4"
          style={{ fontSize: "18px", textAlign: "center", color: "#000000" }}
        >
          <Spin className="spin-step5" size="large" />
        </Col>
      </Row>
      <InfoGraphic />
    </div>
  );
});

export default Steps6Mobile;
