import React from "react";
import { authRoles } from "app/auth";
import vi from "./i18n/vi";
import en from "./i18n/en";
import i18next from "i18next";
import { isMobileOnly } from "react-device-detect";
// import { Redirect } from "react-router-dom";

i18next.addResourceBundle("vi", "dashboards", vi);
i18next.addResourceBundle("en", "dashboards", en);

const DashboardConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    // {
    //   path: "/",
    //   exact: true,
    //   component: () => <Redirect to="/" />,
    // },
    {
      path: "/dashboards",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./dashboards.mobile") : import("./dashboards")
      ),
    },
  ],
};

export default DashboardConfig;
