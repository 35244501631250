const locale = {
  titleHeader: "Update password",
  formLabelUserName: "Account",
  formLabelPasswordOld: "Password Old",
  formMessagePasswordOld: "Please enter old password",
  formPlaceholderPasswordOld: "Enter old password",
  formLabelPasswordNew: "Password New",
  formMessagePasswordNew: "Please enter a new password",
  formPlaceholderPasswordNew: "Enter your new password",
  buttonUpdate: "Update",

  titleHeaderInfo: "Update information",
  formLabelCompanyName: "Company name",
  formLabelCareer: "Career",
  formLabelNumberMember: "Number of member",
  formPlaceholderCompanyName: "Enter company name",
  formPlaceholderCareer: "Career choices",
  formPlaceholderNumberMember: "Select the number of members",
  buttonSave: "Save information",

  formMessageCompanyName: "Please enter your Company Name",
  formMessageCareer: "Please select a profession",
  formMessageNumberMember: "Please select thẻ number of members",
  messageUpdate: "Successfully updated information !!!",

  formListCareer1: "Agriculture",
  formListCareer2: "Contruction",
  formListCareer3: "Mining",
  formListCareer4: "Tourist",
  formListCareer5: "Event Organization",
  formListCareer6: "Transport",
  formListCareer7: "Service",
  formListCareer8: "Restaurant",
  formListCareer9: "Retail",
  formListCareer10: "Insurance",
  formListCareer11: "Telecommunication",
  formListCareer12: "Marketing",
  formListCareer13: "Renewable Energy",
  formListCareer14: "Education",
  formListCareer15: "Disaster Prevention",
  formListCareer16: "Sport",
  formListCareer17: "Other Sectors",
  formListCareer18: "Fashion",
  formListCareer19: "Seafood",
  formListCareer20: "Oil exploited",
};

export default locale;
