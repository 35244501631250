const locale = {
  "List Alerts": "List Contacts Alert",
  ButtonContactManagement: "Contact Management",
  ButtonAddAlert: "Add new",
  ButtonCreateAlert: "Create new notification",
  SearchToolTipTitle: "Search list of alerts by name",
  SearchLabelCommon: "Search",
  SearchPlaceholderCommon: "Search by name",
  SearchLabelInsight: "Insight",
  SearchPlaceholderInsight: "Choose a insight",
  SearchButton: "Search",
  TableTitleColumnName: "Warning name",
  TableTitleColumnAlertContent: "Based on profession",
  TableTitleColumnStatus: "Accounts receive alerts",
  TableTitleColumnPoints: "Địa điểm cảnh báo",
  TableTitleColumnContacts: "Accounts receive alerts",
  TableTitleColumnAction: "Action",
  TableTitleColumnActionToolTipEdit: "Edit",
  TableTitleColumnActionToolTipDelete: "Yes",
  TableTitleColumnActionToolTipDeleteConfirm:
    "Are you sure you want to delete?",
  TableTitleColumnActionToolTipDeleteCancel: "No",
  CreateTitleHeader: "Create Alert",
  EditTitleHeader: "Edit Alert",
  FormLabelName: "Alert Name",
  FormMessageName: "Please enter alert name",
  FormPlaceholderName: "Enter the alert name",
  FormPlaceholderNameThunderstorms:
    "Thunderstorm, lightning warning for the location",
  FormLabelInsight: "Insight",
  FormMessageInsight: "Please select a insight",
  FormPlaceholderInsight: "Choose a insight",
  FormPlaceholderInsightThunderstorms: "Thunderstorm warning",
  FormLabelChannelAlert: "Alert Channel",
  FormMessageChannelAlert: "Please select alert channel",
  FormPlaceholderChannelAlert: "Choose alert channel",
  FormLabelBeforeTime: "Advance alert",
  FormMessageBeforeTime: "Please choose before time",
  FormPlaceholderBeforeTime: "Choose advance alert",
  FormPlaceholderBeforeTimeThunderstorms:
    "Depending on the package's warning period",
  FormLabelContentAlert: "Alert Content",
  FormMessageContentAlert: "Please enter the text of the warning",
  FormPlaceholderContentAlert: "Enter the text of the warning",
  FormPlaceholderContentAlertThunderstorms:
    "The message content will be automatically generated by the system",
  FormLabelPoints: "Points",
  FormMessagePoints: "Please choose a location",
  FormPlaceholderPoints: "Choose place",
  FormLabelContacts: "Contacts",
  FormMessageContacts: "Please choose a contact",
  FormPlaceholderContacts: "Choose contact",
  FormLabelStatus: "Status",
  FormCheckBoxStatus: "Activated",
  FormCheckBoxStatusOff: "Deactivated",
  ButtonCancel: "Cancel",
  ButtonCreate: "Add",
  ButtonEdit: "Save",
  NoData: "No Data",
};

export default locale;
