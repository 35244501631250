const locale = {
  insightTitle: "Library recommended",
  insightName: "Insight",
  insightLib: "Library insight",
  insightLibCareer: "Industry Templates",
  insightAdd: "Add insight",
  insightEdit: "Edit Insight",
  insightButtonAdd: "Add new",
  insightButtonEdit: "Edit",
  insightButtonCancel: "Cancel",
  headerCondition: "Conditions of the profession",
  headerInsight: "Details",
  buttonAddNew: "Add new",
  buttonAddInsight: "Thêm cảnh báo",
  formLabelName: "Insight Name",
  formMessageName: "Please enter insight name",
  formPlaceholderName: "Enter insight name",
  formLabelLevel: "Level of impact",
  formMessageLevel: "Please select the level of impact",
  formPlaceholderLevel: "Choose the level of impact",
  formLabelDescription: "Description",
  formPlaceholderDescription: "Enter description",
  formLabelTags: "Tags",
  formPlaceholderTags: "Enter Tags",
  formLabelCareTime: "Time of interest",
  formPlaceholderCareTime: "Select the time period of interest",
  formLabelNameCondition: "Condition group name",
  formMessageNameCondition: "Please enter the condition group name",
  formPlaceholderNameCondition: "Enter the condition group name",
  formLabelElement: "Element",
  formMessageElement: "Please select element",
  formPlaceholderElement: "Select element",
  formLabelOperator: "Operator",
  formMessageOperator: "Please select operator",
  formPlaceholderOperator: "Select operator",
  formLabelValue: "Value",
  formMessageValue: "Please enter value",
  formPlaceholderValue: "Enter value",
  buttonAddCondition: "Add condition",
  buttonAddGroupCondition: "Add condition group",
  SearchToolTipTitle: "Search list of insight by name",
  SearchLabelCommon: "Search",
  SearchPlaceholderCommon: "Search by name",
  SearchLabelLevelOfImpact: "Level Of Impact",
  SearchPlaceholderLevelOfImpact: "Select level of impact",
  SearchButton: "Search",
  TableColumnName: "Insight Name",
  TableColumnDescription: "Description",
  TableColumnLevel: "Level Of Impact",
  TableColumnHashtags: "Tags",
  TableColumnAction: "Action",
  TableColumnActionEdit: "Edit",
  TableColumnActionCopy: "Copy",
  TableColumnActionDelete: "Delete",
  TableColumnActionDeleteTitle: "Are you sure you want to delete?",
  TableColumnActionDeleteOkText: "Yes",
  TableColumnActionDeleteCancelText: "No",
  buttonAddDashboard: "Add Dashboard",
  ModalTitle: "What locations would you like to use this business group for?",
  ModalSearchPlaceholder: "Search location",
  ModalOkText: "Save",
  ModalCancelText: "Cancel",
  ModalContent: "List of applicable locations",
  NoData: "No Data",
  PlaceholderCareer: "Select Career",
  And: "AND",
  Or: "OR",
};

export default locale;
