import auth from "app/auth/store/reducers";
import appReducer from "./app.reducer";
import { combineReducers } from "redux";
import account from "../../main/account/store/reducers/account.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    appReducer,
    account,
    ...asyncReducers,
  });

export default createReducer;
