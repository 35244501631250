import * as Actions from "../actions";

const initialState = () => ({
  payment: {
    fetching: true,
    payUrl: null,
  },
  paymentRecurring: {
    fetching: true,
    data: null,
  },
  historyPayment: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
});

const accountReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.PAYMENT_GET_URL:
      return {
        ...state,
        payment: {
          fetching: false,
          payUrl: payload.payUrl,
        },
      };
    case Actions.PAYMENT_RECURRING:
      return {
        ...state,
        paymentRecurring: {
          fetching: false,
          data: { ...payload.data },
        },
      };
    case Actions.PAYMENT_RESET:
      return {
        ...state,
        payment: null,
      };
    case Actions.PAYMENT_FETCH:
      return {
        ...state,
        historyPayment: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    default:
      return state;
  }
};

export default accountReducer;
