const locale = {
  "List Locations": "Location",
  ButtonAddLocation: "Add new",
  SearchToolTipTitle: "Search list of locations by name",
  SearchLabelCommon: "Search",
  SearchPlaceholderCommon: "Search by name",
  SearchLabelStatus: "Status",
  SearchPlaceholderStatus: "Choose a status",
  SearchButton: "Search",
  TableTitleColumnName: "Name address",
  TableTitleColumnPointCode: "Code",
  TableTitleColumnLatitudeLongtitude: "Lat/Long",
  TableTitleColumnAddress: "Address",
  TableTitleColumnCreatedAt: "Created At",
  TableTitleColumnStatus: "Status",
  TableTitleColumnAction: "Action",
  TableTitleColumnActionToolTipEdit: "Edit",
  TableTitleColumnActionToolTipDelete: "Delete",
  TableTitleColumnActionToolTipDeleteConfirm:
    "Are you sure you want to delete?",
  TableTitleColumnActionToolTipDeleteCancel: "No",
  TableTitleColumnActionToolTipDeleteYes: "Yes",
  CreateTitleHeader: "Create Location",
  EditTitleHeader: "Edit Location",
  FormLabelPointName: "Location Name",
  FormMessagePointName: "Please enter location name",
  FormPlaceholderPointName: "Enter the location name (eg: Point B)",
  FormLabelPointCode: "Code",
  FormPlaceholderPointCode: "Enter point code",
  FormLabelLongitude: "Longitude",
  FormMessageLongitude: "Please enter longitude",
  FormMessageLimitLongitude:
    "Please enter the longitude within the territory of Vietnam (101.5 -> 110)",
  FormMessageGPS: "Please enter GPS",
  FormPlaceholderLongitude: "Enter longitude",
  FormLabelLatitude: "Latitude",
  FormMessageLatitude: "Please enter latitude",
  FormPlaceholderLatitude: "Enter latitude",
  FormMessageLimitLatitude:
    "Please enter the latitude within the territory of Vietnam (8 -> 24)",
  FormLabelAddress: "Address",
  FormPlaceholderAddress: "Enter address",
  FormLabelStatus: "Status",
  FormCheckBoxStatus: "Activated",
  FormCheckBoxStatusOff: "Deactivated",
  ButtonCancel: "Cancel",
  ButtonCreate: "Add location",
  ButtonEdit: "Save",
  NoData: "No Data",
  ChooseMethodCreateLocation: "Choose method for create location:",
  GPS: "GPS",
  GPSPositioning: "GPS Positioning",
  LatLong: "Lat/Long",
  FormMessageLocation: "Enter location",
  LoadingGps: "GPS Loading...",

  MessageGPS:
    "This option will get your current location, you may need to allow your browser to access your current location",
  MessageLocation:
    "This option will take the address you entered as the basis for determining the point coordinates",
  MessageLatLong:
    "This selection will add the exact coordinates you want to include in the system",
  MessagePlaceName: "Name points * (for reminders, can be changed later)",
  MessageLatLongPlace:
    "(Add a description so you can easily identify it later)",
};

export default locale;
