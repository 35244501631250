const locale = {
  insightTitle: "Thư viện khuyến nghị",
  insightName: "Cảnh báo",
  insightLib: "Khuyến nghị của bạn",
  insightLibCareer: "Khuyến nghị theo ngành",
  insightAdd: "Thêm khuyến nghị mới",
  insightEdit: "Sửa khuyến nghị",
  insightButtonAdd: "Thêm khuyến nghị",
  insightButtonEdit: "Lưu",
  insightButtonCancel: "Hủy bỏ",
  headerCondition: "Thiết lập điều kiện thời tiết cho khuyến nghị",
  headerInsight: "Thông tin chi tiết",
  buttonAddNew: "Thêm mới",
  buttonAddInsight: "Tạo khuyến nghị mới",
  formLabelName: "Tên khuyến nghị",
  formMessageName: "Vui lòng nhập tên cảnh báo",
  formPlaceholderName: "Tên cảnh báo",
  formLabelLevel: "Lựa chọn loại khuyến nghị",
  formMessageLevel: "Vui lòng chọn loại khuyến nghị",
  formPlaceholderLevel: "Chọn mức độ cảnh báo",
  formLabelDescription: "Mô tả",
  formPlaceholderDescription: "Nhập mô tả",
  formLabelTags: "Nhãn",
  formPlaceholderTags: "Nhập nhãn",
  formLabelCareTime: "Khoảng thời gian quan tâm",
  formPlaceholderCareTime: "Chọn khoảng thời gian quan tâm",
  formLabelNameCondition: "Tên",
  formMessageNameCondition: "Vui lòng nhập tên nhóm điều kiện",
  formPlaceholderNameCondition: "Nhập tên nhóm điều kiện",
  formLabelElement: "Yếu tố",
  formMessageElement: "Vui lòng chọn yếu tố",
  formPlaceholderElement: "Chọn yếu tố",
  formLabelOperator: "Phép so sánh",
  formMessageOperator: "Vui lòng chọn phép so sánh",
  formPlaceholderOperator: "Chọn phép so sánh",
  formLabelValue: "Giá trị",
  formMessageValue: "Vui lòng nhập giá trị",
  formPlaceholderValue: "Nhập giá trị",
  buttonAddCondition: "Thêm điều kiện",
  buttonAddGroupCondition: "+ Thêm nhóm điều kiện",
  SearchToolTipTitle: "Tìm kiếm danh sách các cảnh báo theo tên",
  SearchLabelCommon: "Tìm kiếm",
  SearchPlaceholderCommon: "Tìm kiếm cảnh báo",
  SearchLabelLevelOfImpact: "Mức độ tác động",
  SearchPlaceholderLevelOfImpact: "Chọn mức độ tác động",
  SearchButton: "Tìm kiếm",
  TableColumnName: "Tên khuyến nghị",
  TableColumnDescription: "Điều kiện thời tiết",
  TableColumnLevel: "Mức độ quan trọng",
  TableColumnPoints: "Gán địa điểm",
  TableColumnHashtags: "Nhãn",
  TableColumnAction: "Tác vụ",
  TableColumnActionEdit: "Sửa",
  TableColumnActionCopy: "Sao chép",
  TableColumnActionDelete: "Xóa",
  TableColumnActionDeleteTitle: "Bạn chắc chắn muốn xoá?",
  TableColumnActionDeleteOkText: "Xóa",
  TableColumnActionDeleteCancelText: "Không",
  buttonAddDashboard: "Thêm bảng điều khiển",
  ModalTitle: "Lựa chọn địa điểm bạn muốn gán\n" + "cho các khuyến nghị này",
  ModalSearchPlaceholder: "Tìm kiếm địa điểm",
  ModalOkText: "Thêm khuyến nghị",
  ModalCancelText: "Hủy bỏ",
  ModalContent: "Danh sách các địa điểm có thể áp dụng",
  NoData: "Không có dữ liệu",
  PlaceholderCareer: "Chọn ngành nghề",
  And: "VÀ",
  Or: "HOẶC",
  formLabelBasicElement: "Chọn yếu tố thời tiết",
  formLabelBasicCondition: "Điều kiện thời tiết",
  formMessageBasicCondition: "Vui lòng chọn điều kiện thời tiết",
  formPlaceholderBasicCondition: "Chọn điều kiện thời tiết",
  TableColumnChosePoint: "Gán địa điểm cho khuyến nghị của bạn",
};

export default locale;
