import React from "react";
import { Col, Row } from "antd";
import NoticeTrade from "../../assets/img/icon_new/notice_trade.svg";
import ProtectedDMCA from "../../assets/img/icon_new/protected_dmca.svg";
import LocationFooter from "../../assets/img/icon_new/location_footer_landing_page.svg";
import PhoneFooter from "../../assets/img/icon_new/phone_footer_landing_page.svg";
import SendFooter from "../../assets/img/icon_new/send_footer_landing_page.svg";
import { isMobile } from "react-device-detect";
import imgAppStore from "../../assets/img/icon_new/Download_on_the_App_Store.png";
import imgGooglePlay from "../../assets/img/icon_new/Google_Play_Store.png";

const FooterLandingPage = () => {
  return (
    <Row className={"layout-footer-landing-page"}>
      <Col span={24} className={"main-container-footer-landing-page"}>
        <Row>
          <Col
            span={!isMobile ? 8 : 24}
            style={
              !isMobile
                ? { paddingRight: "20px" }
                : { padding: "30px 20px 30px 0px" }
            }
          >
            <Row>
              <Col span={24} className={"title-footer-landing-page"}>
                <span>CÔNG TY CỔ PHẦN GIẢI PHÁP THỜI TIẾT WEATHERPLUS</span>
              </Col>
              <Col span={24} className={"text-footer-landing-page"}>
                <span>Mã số doanh nghiệp: 0106597121</span>
              </Col>
              <Col span={24} className={"text-footer-landing-page"}>
                <span>
                  Cấp bởi: Phòng Đăng ký kinh doanh - Sở Kế hoạch và đầu tư
                  Thành phố Hà Nội ngày 09/07/2014
                </span>
              </Col>
              <Col span={24} className={"text-footer-landing-page"}>
                <span>Người đại diện theo pháp luật: Ông Tô Đức Hải</span>
              </Col>
              <Col span={24} className={"text-footer-landing-page"}>
                <span>
                  Giấy phép hoạt động dự báo, cảnh báo khí tượng thuỷ văn số 01
                  năm 2017 do Bộ Tài nguyên và môi trường cấp ngày 03/04/2017
                </span>
              </Col>
              <Col span={24} className={"text-footer-landing-page"}>
                <span>
                  Giấy phép hoạt động dự báo, cảnh báo khí tượng thuỷ văn (sửa
                  đổi lần thứ nhất) số: 14/GP-BTNMT do Bộ Tài nguyên và môi
                  trường cấp ngày 21/01/2021.
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={!isMobile ? 8 : 24}>
            <Row className={"container-rules-footer"}>
              <Col
                span={!isMobile ? 20 : 24}
                offset={!isMobile ? 2 : 0}
                className={"title-footer-landing-page"}
              >
                <span>Các điều khoản và chính sách</span>
              </Col>
              <Col
                span={!isMobile ? 20 : 24}
                offset={!isMobile ? 2 : 0}
                className={"text-footer-landing-page"}
              >
                <a
                  href={
                    "https://about.weatherplus.vn/chinh-sach-quy-dinh-chung"
                  }
                >
                  Chính sách & quy định chung
                </a>
              </Col>
              <Col
                span={!isMobile ? 20 : 24}
                offset={!isMobile ? 2 : 0}
                className={"text-footer-landing-page"}
              >
                <a href="https://about.weatherplus.vn/quy-dinh-ve-hinh-thuc-thanh-toan/">
                  Quy định về phương thức thanh toán
                </a>
              </Col>
              <Col
                span={!isMobile ? 20 : 24}
                offset={!isMobile ? 2 : 0}
                className={"text-footer-landing-page"}
              >
                <a href="https://about.weatherplus.vn/chinh-sach-van-chuyen-va-giao-nhan/">
                  Chính sách vận chuyển và giao nhận
                </a>
              </Col>
              <Col
                span={!isMobile ? 20 : 24}
                offset={!isMobile ? 2 : 0}
                className={"text-footer-landing-page"}
              >
                <a href="https://about.weatherplus.vn/chinh-sach-bao-mat-thong-tin/">
                  Chính sách bảo mật thông tin
                </a>
              </Col>
              <Col span={24}>
                <Row>
                  <Col
                    span={12}
                    onClick={() => {
                      window.open("http://online.gov.vn/Home/WebDetails/84850");
                    }}
                    className={"container-img-footer"}
                  >
                    <img src={NoticeTrade} alt={"NoticeTrade"} width={140} />
                  </Col>
                  <Col
                    span={12}
                    className={"container-img-footer"}
                    id={"contact"}
                    onClick={() => {
                      window.open(
                        "https://www.dmca.com/Protection/Status.aspx?ID=a52b5736-192b-4e79-b5d7-2fd229593a0b"
                      );
                    }}
                  >
                    <img
                      src={ProtectedDMCA}
                      alt={"ProtectedDMCA"}
                      width={150}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            span={!isMobile ? 8 : 24}
            style={{ padding: !isMobile ? "0px 20px" : "30px 0px" }}
          >
            <Row>
              <Col span={2}></Col>
              <Col span={22} className={"title-footer-landing-page"}>
                <span>Thông tin liên hệ</span>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={2}>
                    <img
                      src={LocationFooter}
                      alt={"LocationFooter"}
                      width={18}
                    />
                  </Col>
                  <Col span={22} className={"text-footer-landing-page"}>
                    <span>
                      Tầng 18, Tháp A, Tòa nhà Sông Đà, Phạm Hùng, Nam Từ Liêm,
                      Hà Nội
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={2}>
                    <img src={PhoneFooter} alt={"PhoneFooter"} width={14} />
                  </Col>
                  <Col span={22} className={"text-footer-landing-page"}>
                    <span>(+84) 24 37 955 069</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={2}>
                    <img src={PhoneFooter} alt={"PhoneFooter"} width={14} />
                  </Col>
                  <Col span={22} className={"text-footer-landing-page"}>
                    <span>(+84) 822 091 091</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={2}>
                    <img src={SendFooter} alt={"SendFooter"} width={18} />
                  </Col>
                  <Col span={22} className={"text-footer-landing-page"}>
                    <span>kinhdoanh@weatherplus.vn</span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={11}>
                    <a
                      href={
                        "https://apps.apple.com/vn/app/metplus-th%E1%BB%9Di-ti%E1%BA%BFt-tin-c%E1%BA%ADy/id1661769860?platform=iphone"
                      }
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      <img
                        src={imgAppStore}
                        alt={"imgAppStore"}
                        width={isMobile ? "100%" : "auto"}
                      />
                    </a>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.weatherplus.metplus&hl=en&gl=US"
                      }
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      <img
                        src={imgGooglePlay}
                        alt={"imgGooglePlay"}
                        width={isMobile ? "100%" : "auto"}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FooterLandingPage;
