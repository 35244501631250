import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Row, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";
import * as InsightActions from "../../main/insights/store/actions/insight.action";

const Steps3Mobile = React.memo((props) => {
  const { prev, infoSteps, formStep3, onSubmit } = props;
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.accountData.career.careers);
  // const [checkedList, setCheckedList] = useState([]);
  const [checkedListSource, setCheckedListSource] = useState([]);

  const packageInsight = useSelector(
    (state) => state.accountData.insight.packageInsight
  );

  const prevAction = () => {
    prev();
    dispatch(
      InsightActions.setPackageInsight({
        data: null,
        isShowPackageDetail: false,
        isOnBoarding: false,
      })
    );
  };

  useEffect(() => {
    const insights = [];
    if (packageInsight.isOnBoarding) {
      packageInsight.data.insights
        ?.filter((insight) => insight.priority > 0)
        .forEach((insight, index) => {
          if (index <= 6) {
            insights.push(insight);
          }
        });
      if (insights.length > 0) {
        setCheckedListSource(insights);
      }
    }
  }, [packageInsight]);

  useEffect(() => {
    // const insights = [];
    data?.content
      // ?.filter((career) => career.id === infoSteps.step2.career)
      ?.forEach((career) => {
        // if (infoSteps.step2.isSubSector === false) {
        //   career.subSectors.forEach((sub) => {
        //     sub.packageInsights?.forEach((groupInsight) => {
        //       dispatch(
        //         InsightActions.setPackageInsight({
        //           id: groupInsight?.id,
        //           careerName: career.careerName,
        //           isShowPackageDetail: false,
        //           isOnBoarding: true,
        //         })
        //       );
        //     });
        //   });
        // } else {
        career.subSectors
          .filter((sub) => sub?.id === infoSteps.step2.subSectors)
          .forEach((sub) => {
            sub.packageInsights?.forEach((groupInsight) => {
              dispatch(
                InsightActions.setPackageInsight({
                  id: groupInsight?.id,
                  careerName: career.careerName,
                  isShowPackageDetail: false,
                  isOnBoarding: true,
                })
              );
            });
          });
        // }
      });
    // setCheckedListSource(insights);
  }, [data, infoSteps, dispatch]);

  const validateForm = () => {
    return (
      formStep3.getFieldValue("insights")?.length < 3 ||
      formStep3.getFieldValue("insights") === undefined
    );
  };

  const onFinish = (values) => {
    const filterInsights = checkedListSource.filter((insight) =>
      values.insights.includes(insight.id)
    );
    onSubmit({ insights: filterInsights });
  };

  return (
    <div>
      <ArrowLeftOutlined
        onClick={() => prevAction()}
        style={{
          color: "#FFFFFF",
          fontSize: 26,
          position: "absolute",
          top: 40,
        }}
      />
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-7"
          style={{ fontSize: "16px", textAlign: "center", color: "#000000" }}
        >
          Chọn khuyến nghị hành động mẫu theo ngành
        </Col>
        <Col
          className="font-text font-weight-4 font-size"
          style={{
            textAlign: "center",
            opacity: 0.9,
            fontSize: "14px",
            color: "#000000",
          }}
        >
          (Bạn có thể sửa khuyến nghị hoặc thêm mới các khuyến nghị của mình
          sau)
        </Col>
      </Row>
      <Form
        name="list-insight"
        form={formStep3}
        onFinish={onFinish}
        // className="style-add-order"
        initialValues={{}}
      >
        <Element
          style={{
            position: "relative",
            height: 360,
            // paddingRight: "20px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          alt={"width"}
        >
          <Form.Item name="insights">
            <Checkbox.Group
              value={infoSteps.step3?.insights?.map((insight) => insight.id)}
            >
              <Row justify="start" gutter={20}>
                {checkedListSource?.map((insight, index) => {
                  return (
                    <Col
                      key={index}
                      span={24}
                      // style={{
                      //   paddingBottom: 10,
                      // }}
                    >
                      <Checkbox
                        value={insight.id}
                        style={{ lineHeight: "16px" }}
                        className="checkbox-custom checkbox-custom-mobile"
                        // indeterminate={true}
                      >
                        <Typography.Paragraph
                          className={"font-text font-weight-6"}
                          style={{ fontSize: 16, color: "#000000" }}
                          ellipsis={{
                            rows: 2,
                            expandable: false,
                            tooltip: insight.name,
                          }}
                        >
                          {insight.name}
                        </Typography.Paragraph>
                      </Checkbox>
                      {/*<label className="tick-chose-insight">*/}
                      {/*  <Typography.Paragraph*/}
                      {/*    className={*/}
                      {/*      "font-text color-text font-weight-4"*/}
                      {/*    }*/}
                      {/*    ellipsis={{*/}
                      {/*      rows: 2,*/}
                      {/*      expandable: false,*/}
                      {/*      tooltip: insight.name,*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    {insight.name}*/}
                      {/*  </Typography.Paragraph>*/}
                      {/*  <input*/}
                      {/*    type="checkbox"*/}
                      {/*    value={insight.id}*/}
                      {/*    onChange={onChangeCheckBox}*/}
                      {/*  />*/}
                      {/*  <span className="tick-chose-insight-checkmark" />*/}
                      {/*</label>*/}
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Element>
        <Row style={{ marginBottom: 20 }} justify="space-around" align="middle">
          <Col
            className="font-text font-weight-4 font-size"
            style={{ textAlign: "center", color: "#ffffff" }}
          >
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  className="button-first mst-onboarding-step-3"
                  type="text"
                  htmlType="submit"
                  disabled={validateForm()}
                >
                  Tiếp theo
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

export default Steps3Mobile;
