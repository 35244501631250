const locale = {
  dashboardAdd: "Tạo bảng điều khiển",
  dashboardEdit: "Sửa bảng điều khiển",
  dashboardButtonCreate: "Thêm khuyến nghị",
  dashboardButtonAdd: "Thêm",
  dashboardButtonEdit: "Lưu",
  dashboardButtonCancel: "Huỷ",
  dashboardSearchInsight: "Cảnh báo",
  "Choose dashboard": "Chọn bảng điểu khiển",
  "Add New": "Thêm mới",
  "Edit dashboard": "Sửa bảng điều khiển",
  "Delete dashboard": "Xoá bảng điều khiển",
  labelName: "Tên bảng điều khiển",
  messageName: "Vui lòng nhập tên bảng điều khiển",
  placeholderName: "Nhập tên bảng điều khiển",
  labelDescription: "Mô tả",
  placeholderDescription: "Nhập mô tả",
  labelStatus: "Trạng thái",
  valueStatus: "Kích hoạt",
  titleSetup: "Lựa chọn các cảnh báo và địa điểm",
  labelInsight: "Chọn khuyến nghị",
  labelLocation: "Chọn địa điểm",
  messageInsight: "Vui lòng chọn cảnh báo",
  placeholderInsight: "Chọn cảnh báo",
  labelPoint: "Địa điểm",
  messagePoint: "Vui lòng chọn địa điểm",
  placeholderPoint: "Chọn địa điểm",
  labelMainControlPanel: "Đặt làm bảng điều khiển chính",
  valueMainControlPanel: "Kích hoạt",
  titleConfirmDelete: "Bạn chắc chắn muốn xoá?",
  okTextConfirmDelete: "Xoá",
  cancelTextConfirmDelete: "Không",
  updatingData: "Đang cập nhật dữ liệu",
  titleHeaderInsight: "cảnh báo hoạt động",
  dateDashboard: "Ngày ",
  comeBack: "Vui lòng quay lại sau khoảng 1 phút",
  dashboardTable: "Bảng khuyến nghị hành động",
  insight: "Khuyến nghị",
  day: "Ngày",
  week: "Tuần",
  location: "Địa điểm",
  active: "Kích hoạt",
  CreateTitleHeader: "Thêm người nhận",
  ButtonCreate: "Thêm người nhận",
  ButtonCancel: "Huỷ bỏ",
  FormLabelContentAlert:
    "Lời nhắn cho người nhận khuyến nghị (sẽ được gửi qua Email/Zalo)",
  FormLabelContentAlertInsight: "Lời nhắn cho người nhận khuyến nghị",
  FormPlaceholderContacts: "Chọn người nhận",
  FormLabelChannelAlert: "Nhận khuyến nghị qua",
  FormLabelBeforeTime: "Cảnh báo trước",
  FormPlaceholderBeforeTime: "12h",
  addContact: "Thêm người dùng mới",
  FormPlaceholderContentAlert: "Khuyến nghị hành động cho người nhận",
  FormPlaceholderInsight: "Chọn khuyến nghị",
  FormMessageContacts: "Vui lòng chọn người nhận",
  FormMessageBeforeTime: "Vui lòng chọn thời gian",
  FormMessageAlertContent: "Vui lòng nhập lời nhắn cho người nhận khuyến nghị",
  FormMessageChannelAlert: "Vui lòng chọn nhận khuyến nghị qua Email hoặc Zalo",
  FormPlaceholderInsightThunderstorms: "Cảnh báo mưa dông sét",
  FormPlaceholderBeforeTimeThunderstorms: "Tùy theo hạn cảnh báo của gói",
  FormPlaceholderContentAlertThunderstorms:
    "Nội dung cảnh báo sẽ được hệ thống tự động sinh ra",
};

export default locale;
