import React from "react";
// import User from "assets/img/icon_new/user.png";
import Lock from "assets/img/icon_new/lock.png";
import PackageInfo from "assets/img/icon_new/card-tick.png";
import Logout from "assets/img/icon_new/logout.png";
import Home from "assets/img/icon_new/home.svg";
import Function from "assets/img/icon_new/function.svg";
import PriceList from "assets/img/icon_new/price_list.svg";
import Newspaper from "assets/img/icon_new/newspaper_menu.svg";
import Contact from "assets/img/icon_new/contact_home.svg";

const constants = {
  TIMEOUT_REQUEST: 60000,

  FORMAT_DATE_TIME: "DD/MM/YYYY HH:mm:ss",
  MAX_FILE_SIZE_UPLOAD: 10 * 1024 * 1024,
  FORMAT_DATE_TIME_TYPE_NOT_HOUR: "DD/MM/YYYY",
  FORMAT_DATE_TIME_TYPE: "YYYY-MM-DD",
  FORMAT_DATE_TIME_START_DATE: "DD/MM/YYYY 00:00:00",
  FORMAT_DATE_TIME_END_DATE: "DD/MM/YYYY 23:59:59",

  ROWS_PER_PAGE: "9999999",

  TIME_EXPORT_EXCEL: 31,

  MACOS_PLATFORMS: ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  WINDOW_PLATFORMS: ["Win32", "Win64", "Windows", "WinCE"],
  IOS_PLATFORM: ["iPhone", "iPad", "iPod"],

  INIT_SELECT: [
    {
      key: null,
      value: "Tất cả",
    },
  ],

  HOME_MENU_ITEMS: [
    {
      name: "Trang chủ",
      icon: <img src={Home} alt={"Home"} width={20} height={20} />,
      url: "#home",
    },
    {
      name: "Tính năng",
      icon: <img src={Function} alt={"Function"} width={20} height={20} />,
      url: "#function",
    },
    {
      name: "Bảng giá",
      icon: <img src={PriceList} alt={"PriceList"} width={20} height={20} />,
      url: "#price-list",
    },
    {
      name: "Báo chí",
      icon: <img src={Newspaper} alt={"Newspaper"} width={20} height={20} />,
      url: "#newspaper",
    },
    {
      name: "Liên hệ",
      icon: <img src={Contact} alt={"Contact"} width={20} height={20} />,
      url: "#contact",
    },
  ],

  PROFILE_MENU_ITEMS: [
    // {
    //   name: "userInfo",
    //   icon: <img src={User} alt="User" width="20px" height="20px" />,
    //   url: `/profile`,
    // },
    {
      name: "mstPackageInfo",
      icon: (
        <img src={PackageInfo} alt="PackageInfo" width="20px" height="20px" />
      ),
      url: `/profile/upgrade`,
    },
    {
      name: "changeLightDark",
    },
    {
      name: "changePassword",
      icon: (
        <img
          className={"ft-cl-icon-location-search-weather"}
          src={Lock}
          alt="User"
          width="20px"
          height="20px"
          style={{ opacity: "0.5" }}
        />
      ),
      url: `/account`,
    },
    {
      name: "logout",
      icon: <img src={Logout} alt="Logout" width="20px" height="20px" />,
      function: "logout",
    },
  ],

  SUCCESS: 200,

  CODE_SUCCESS: "00",
  CODE_NOT_ACCESS_CHANNEL: "02",
  CODE_RECEIVED_OTP: "25",

  CODE_CHANGE_PASSWORD_FAIL: "37",
  CODE_TOKEN_FAIL: "11",
  CODE_UNAUTHORIZE: "10",
  CODE_TOKEN_HASH_EXPIRE: "-3",
  NO_DATA_EXPORT: "17",
  INTERNAL_SYSTEM_ERROR: "99",
  CODE_PERMISSION: "35",

  SOCKET_TOPIC: "/user/queue/notification",
  SOCKET_NUMBER_RECORD: 30,

  PRIMARY_THEMES: "1",
  PAY_CHANNEL: [
    {
      key: "02",
      value: "QR - Cổng",
    },
    {
      key: "04",
      value: "QR - Billing",
    },
    {
      key: "05",
      value: "QR - Sản phẩm",
    },
    {
      key: "06",
      value: "QR - Offline",
    },
    {
      key: "09",
      value: "QR - Customer",
    },
    {
      key: "1",
      value: "Thanh toán qua Web",
    },
    {
      key: "3",
      value: "Thanh toán qua QRCode",
    },
    {
      key: "2",
      value: "Thanh toán Token",
    },
  ],
  PHONE_CONTACT: "0822091091",
};

export default constants;
