import React from "react";

import { authRoles } from "app/auth";

import vi from "./i18n/vi";
import en from "./i18n/en";
import i18next from "i18next";
import { isMobileOnly } from "react-device-detect";

i18next.addResourceBundle("vi", "contacts", vi);
i18next.addResourceBundle("en", "contacts", en);

const ProfileConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/contacts",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./contacts.mobile") : import("./contacts")
      ),
    },
  ],
};

export default ProfileConfig;
