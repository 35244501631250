const locale = {
  contactName: "Quản lý người dùng",
  contactLib: "Thư viện cảnh báo",
  contactAdd: "Thêm người dùng",
  contactEdit: "Sửa người dùng",
  contactButtonAdd: "Thêm mới",
  contactButtonEdit: "Lưu",
  contactButtonCancel: "Huỷ bỏ",
  labelName: "Họ và tên",
  messageName: "Vui lòng nhập họ và tên",
  placeholderName: "Nhập họ và tên",
  labelEmail: "Email (Nhập chính xác để nhận cảnh báo qua email)",
  messageEmail: "Vui lòng nhập email",
  messageValidateEmail: "E-mail không đúng định dạng",
  placeholderEmail: "Nhập email",
  labelPhone: "Tên đăng nhập",
  messagePhone: "Vui lòng nhập tên đăng nhập",
  messageValidatePhone: "Tên đăng nhập định dạng chưa đúng",
  placeholderPhone: "Tên đăng nhập là Email hoặc Số điện thoại đăng nhập",
  labelPhoneZalo: "Số điện thoại sử dụng zalo (để nhận được cảnh báo qua zalo)",
  placeholderPhoneZalo: "Để trống nếu trùng số điện thoại đăng nhập",
  labelTelco: "Nhà mạng",
  messageTelco: "Vui lòng chọn nhà mạng",
  placeholderTelco: "Chọn nhà mạng",
  labelPoints: "Địa điểm hiệu lực (để trống là được truy cập tất cả)",
  placeholderPoints: "Chọn địa điểm",
  labelPassword: "Mật khẩu (nếu không nhập hệ thống sẽ tạo tự động)",
  labelPasswordConfirm: "Nhập lại mật khẩu",
  labelPasswordEdit: "Mật khẩu (hệ thống sẽ gửi email đến người dùng)",
  placeholderPassword: "Nhập mật khẩu",
  placeholderPasswordOld: "Nhập mật khẩu cũ",
  labelStatus: "Trạng thái",
  valueStatus: "Kích hoạt",
  FormCheckBoxStatusOff: "Ngừng kích hoạt",
  buttonAddContact: "Thêm người dùng",
  SearchToolTipTitle: "Tìm kiếm danh sách các người dùng theo tên",
  SearchLabelCommon: "Tìm ki ếm",
  SearchPlaceholderCommon: "Tìm kiếm theo tên",
  SearchButton: "Tìm kiếm",
  TableColumnName: "Họ và tên",
  TableColumnUserName: "Tên đăng nhập",
  TableColumnEmail: "Email",
  TableColumnPhone: "Số điện thoại",
  TableColumnPhoneZalo: "Số zalo",
  TableColumnTelco: "Nhà mạng",
  TableColumnType: "Loại tài khoản",
  TableColumnStatus: "Trạng thái",
  TableColumnAction: "Tác vụ",
  TableColumnActionEdit: "Chỉnh sửa",
  TableColumnActionDelete: "Xóa",
  TableColumnActionDeleteConfirm: "Bạn chắc chắn muốn xoá?",
  TableColumnActionDeleteConfirmYes: "Xoá",
  TableColumnActionDeleteConfirmNo: "Không",
  NoData: "Không có dữ liệu",
  labelPhoneDashboard: "Điện thoại",
  placeholderPhoneDashboard: "Nhập số điện thoại",
  placeholderPasswordConfirm: "Vui lòng nhập lại mật khẩu của bạn",
  labelPasswordNow: "Mật khẩu hiện tại",
  labelPasswordEditConfirm: "Nhập lại mật khẩu mới",
  labelPasswordEditNew: "Mật khẩu mới",
  labelPasswordEditOld: "Mật khẩu cũ",
  placeholderPasswordEdit: "Nhập mật khẩu hiện tại",
  placeholderPasswordEditNew: "Nhập mật khẩu mới",
  placeholderPasswordEditNewConfirm: "Xác nhận lại mật khẩu mới",
};

export default locale;
