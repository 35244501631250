const locale = {
  dashboardAdd: "Tạo bảng điều khiển",
  dashboardEdit: "Sửa bảng điều khiển",
  dashboardButtonCreate: "Add new insights",
  dashboardButtonAdd: "Save",
  dashboardButtonEdit: "Edit",
  dashboardButtonCancel: "Cancel",
  dashboardSearchInsight: "Cảnh báo",
  "Choose dashboard": "Chọn bảng điểu khiển",
  "Add New": "Thêm mới",
  "Edit dashboard": "Sửa bảng điều khiển",
  "Delete dashboard": "Xoá bảng điều khiển",
  labelName: "Tên bảng điều khiển",
  messageName: "Vui lòng nhập tên bảng điều khiển",
  placeholderName: "Nhập tên bảng điều khiển",
  labelDescription: "Mô tả",
  placeholderDescription: "Nhập mô tả",
  labelStatus: "Trạng thái",
  valueStatus: "Kích hoạt",
  titleSetup: "Lựa chọn các khuyến nghị và địa điểm",
  labelInsight: "Khuyến nghị",
  messageInsight: "Vui lòng chọn khuyến nghị",
  placeholderInsight: "Chọn khuyến nghị",
  labelPoint: "Địa điểm",
  messagePoint: "Vui lòng chọn địa điểm",
  placeholderPoint: "Chọn địa điểm",
  labelMainControlPanel: "Đặt làm bảng điều khiển chính",
  valueMainControlPanel: "Kích hoạt",
  titleConfirmDelete: "Bạn chắc chắn muốn xoá?",
  okTextConfirmDelete: "Xoá",
  cancelTextConfirmDelete: "Không",
  updatingData: "Updating data...",
  titleHeaderInsight: "active insights",
  dateDashboard: "Day ",
  comeBack: "Please, come back after 1 Minutes",
  dashboardTable: "Dashboard",
  search: "Search",
  day: "Day",
  week: "Week",
  location: "Location",
  active: "Activated",
};

export default locale;
