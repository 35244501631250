import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";

export const NOTIFY_RESET = "NOTIFY_RESET";
export const NOTIFY_ERROR = "NOTIFY_ERROR";

export const NOTIFY_FETCHING = "NOTIFY_FETCHING";
export const NOTIFY_FETCHED = "NOTIFY_FETCHED";
export const NOTIFY_COUNT = "NOTIFY_COUNT";
export const NOTIFY_UPDATE = "NOTIFY_UPDATE";

export const getAllNotify = () => async (dispatch) => {
  dispatch({ type: NOTIFY_FETCHING });
  try {
    // axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const dataBody = {
      searchCommon: "",
      sortBy: "createdAt",
      rowsPerpage: 10,
    };
    const res = await axios.post("user/list-log-notify", dataBody);
    dispatch({
      type: NOTIFY_FETCHED,
      payload: {
        data: res.data.data,
        status: res.data.status,
        message: res.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const getPageNotify = (page) => async (dispatch) => {
  dispatch({ type: NOTIFY_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;

    const dataBody = {
      searchCommon: "",
      sortBy: "createdAt",
      page: page,
      rowsPerpage: 10,
    };
    const res = await axios.post("user/list-log-notify", dataBody);

    dispatch({
      type: NOTIFY_FETCHED,
      payload: {
        data: res.data.data,
        status: res.data.status,
        message: res.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};
export const getTotalNotify = () => async (dispatch) => {
  dispatch({ type: NOTIFY_FETCHING });
  try {
    // axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get("user/total-log-notify");

    dispatch({
      type: NOTIFY_COUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const updateAllNotify = () => async (dispatch) => {
  dispatch({ type: NOTIFY_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const resCheck = await axios.get("user/update-all-notify");

    dispatch({
      type: NOTIFY_UPDATE,
      payload: {
        data: resCheck.data.data,
        status: resCheck.data.status,
        message: resCheck.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const updateNotify = (id) => async (dispatch) => {
  dispatch({ type: NOTIFY_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const resCheck = await axios.post(`user/update-log-notify/${id}`);
    dispatch({
      type: NOTIFY_UPDATE,
      payload: {
        data: resCheck.data.data,
        status: resCheck.data.status,
        message: resCheck.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};
export const reset = () => (dispatch) => dispatch({ type: NOTIFY_RESET });
