import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";
import { ACCOUNT_ERROR } from "./account.action";

export const INFO_ERROR = "INFO_ERROR";

export const INFO_FETCHING = "INFO_FETCHING";
export const INFO_UPDATE = "INFO_UPDATE";
export const INFO_RESET = "INFO_RESET";
export const INFO_DETAIL = "INFO_DETAIL";
// export const LOGIN_DETAIL ="LOGIN_DETAIL";

export const updateLoginDetail = (params) => async () => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    axios.post("user/detail-login-from-everywhere", params);
  } catch (error) {
    window.alert(error);
    throw error;
  }
};
//createOrUpdate
export const createOrUpdateInfo = (id, params) => async (dispatch) => {
  dispatch({ type: INFO_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    let req = id
      ? axios.post("user/update-info", params)
      : axios.post("user/save-info", params);

    const resCreate = await req;

    const data = resCreate.data;

    dispatch({
      type: INFO_UPDATE,
      payload: {
        message: data.message,
        statusUpdate: data.status,
        data: data.data,
      },
    });
  } catch (error) {
    dispatch({
      type: INFO_ERROR,
      payload: error,
    });
    window.alert(error);
    throw error;
  }
};

export const getCompanyInfoByUser = () => async (dispatch) => {
  dispatch({ type: INFO_FETCHING });

  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.get(`user/detail-info`);
    dispatch({
      type: INFO_DETAIL,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: INFO_RESET });
