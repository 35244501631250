import React from "react";
import {
  Button,
  Col,
  Empty,
  Input,
  notification,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import iconStatusOn from "../../assets/img/icon_new/on.png";
import iconStatusOff from "../../assets/img/icon_new/off.png";
import { isMobileOnly } from "react-device-detect";
import uuid from "react-uuid";
import ellipse_red from "../../assets/img/icon-mobile/ellipse_red.png";
import ellipse_or from "../../assets/img/icon-mobile/ellipse_or.png";
import ellipse_y from "../../assets/img/icon-mobile/ellipse_y.png";

export const setLocalStorage = (key, value) =>
  localStorage.setItem(
    key,
    typeof value === "object" ? JSON.stringify(value) : value
  );

export const getLocalStorage = (key) => localStorage.getItem(key) || null;

export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const recursionOpenKeys = (items, openKeys, pathName) => {
  items.forEach((item) => {
    if (item.children && pathName.indexOf(item.url) !== -1) {
      openKeys.push(item.url);
      recursionOpenKeys(item.children, openKeys, pathName);
    }
  });
  return openKeys;
};

export const notificationPopup = (type, messageText) => {
  // try {
  //   notification.destroy();
  // } catch (e) {
  //   console.log(e);
  // }
  notification[type]({
    message: "Thông báo",
    description: messageText,
    duration: 3,
  });
};

export const getNameEnterprise = (id, arrEnterprise) => {
  const nameEnterprise =
    arrEnterprise && arrEnterprise.filter((item) => item.id === id);
  return (
    (nameEnterprise && nameEnterprise.length && nameEnterprise[0].name) || ""
  );
};

export const renderColorElementType = (status) => {
  let color = null;
  let backgroundColor = null;
  switch (status.code) {
    case 0:
      color = "#29A339";
      backgroundColor = "#EEF6EF";
      break;
    case 1:
      color = "#C91D1D";
      backgroundColor = "#FAEAEA";
      break;
    case 2:
      color = "#C91D1D";
      backgroundColor = "#FAEAEA";
      break;
    case 3:
      color = "#F5F7F9";
      backgroundColor = "#20a8d8";
      break;
    case 4:
      color = "#F5F7F9";
      backgroundColor = "#5A7087";
      break;
    default:
      break;
  }
  const styleStatus = {
    color,
    background: backgroundColor,
    borderRadius: "10px",
    display: "inline-block",
    padding: "2px 8px",
  };

  return color ? (
    <span style={styleStatus}>{status.description}</span>
  ) : (
    <span>{status}</span>
  );
};

export const renderStatusColorTransaction = (status) => {
  let color = null;
  let backgroundColor = null;
  switch (status.code) {
    case 1:
      color = "#29A339";
      backgroundColor = "#EEF6EF";
      break;
    case 0:
      color = "#C91D1D";
      backgroundColor = "#FAEAEA";
      break;
    case 2 || -1:
      color = "#C91D1D";
      backgroundColor = "#FAEAEA";
      break;
    case 5:
      color = "#F5F7F9";
      backgroundColor = "#20a8d8";
      break;
    case 4:
      color = "#F5F7F9";
      backgroundColor = "#5A7087";
      break;
    case 3:
      color = "#E07E06";
      backgroundColor = "#FEF3E7";
      break;
    case 6:
      color = "#E07E06";
      backgroundColor = "#FEF3E7";
      break;
    default:
      break;
  }
  const styleStatus = {
    color,
    background: backgroundColor,
    borderRadius: "10px",
    display: "inline-block",
    padding: "2px 8px",
  };

  return color ? (
    <span style={styleStatus}>{status.description}</span>
  ) : (
    <span>{status}</span>
  );
};

export const renderStatusColor = (status) => {
  let color = null;
  switch (status) {
    case "Success":
      color = "#4dbd74";
      break;
    case "Fail":
      color = "#f86c6b";
      break;
    case "Check":
      color = "#20a8d8";
      break;
    case "Reversal P3":
      color = "#a569bd";
      break;
    case "Khởi tạo":
      color = "#a569bd";
      break;
    case "Hoạt động":
      color = "#4dbd74";
      break;
    case "Chờ duyệt":
      color = "#20a8d8";
      break;
    case "Từ chối":
      color = "#f5b041";
      break;
    case "Tạm khóa":
      color = "#f86c6b";
      break;
    case "Hết hạn":
      color = "#c0392b";
      break;
    case "Đã đóng":
      color = "#f86c6b";
      break;
    case "Chờ kích hoạt":
      color = "#20a8d8";
      break;
    default:
      break;
  }

  return color ? <Tag color={color}>{status}</Tag> : <span>{status}</span>;
};

export const renderRefundStatusColor = (code) => {
  let renderHtml = "";
  const styleStatusRefund = {
    display: "inline-block",
    borderRadius: "10px",
    padding: "2px 8px",
  };
  switch (code) {
    case 3:
      renderHtml = (
        <span
          style={{
            backgroundColor: "#FAEAEA",
            color: "#C91D1D",
            ...styleStatusRefund,
          }}
        >
          Từ chối hoàn
        </span>
      );
      break;
    case 2:
      renderHtml = (
        <span
          style={{
            backgroundColor: "#EEF6EF",
            color: "#29A339",
            ...styleStatusRefund,
          }}
        >
          Đã duyệt hoàn
        </span>
      );
      break;
    case 1:
      renderHtml = (
        <span
          style={{
            backgroundColor: "#FEF3E7",
            color: "#E07E06",
            ...styleStatusRefund,
          }}
        >
          Chờ duyệt hoàn
        </span>
      );
      break;
    default:
      break;
  }
  return renderHtml;
};

export const convertStatusCode = (code, key) => {
  const STATUS_TRANSACTION = [
    {
      code: 1,
      description: "Thành công",
    },
    {
      code: 2,
      description: "Không thành công",
    },
    {
      code: 3,
      description: "Chờ kiểm tra",
    },
    {
      code: 4,
      description: "Hủy không thành công",
    },
    {
      code: 5,
      description: "Hủy thành công",
    },
    {
      code: 6,
      description: "Đang thực hiện",
    },
  ];
  const statusList = JSON.parse(getLocalStorage(key)) || STATUS_TRANSACTION;

  const statusFiltered =
    statusList && statusList.filter((item) => item.code === code);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderStatusColorTransaction(statusFiltered[0])
  );
};

export const convertStatusElementNew = (status) => {
  return (
    <Row>
      <Col span={3} style={{ marginRight: 10 }}>
        <img
          style={{ width: "32px", height: "16.84px" }}
          src={status === 1 ? iconStatusOn : iconStatusOff}
          alt={"status"}
        />
      </Col>
      <Col span={18} className={"status-text"}>
        {status === 1 ? "Kích hoạt" : "Ngừng kích hoạt"}
      </Col>
    </Row>
  );
};

export const convertStatusElement = (code) => {
  const STATUS = [
    {
      code: 1,
      description: "Kích hoạt",
    },
    {
      code: 0,
      description: "Dừng lại",
    },
  ];
  const statusFiltered = STATUS && STATUS.filter((item) => item.code === code);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderStatusColorTransaction(statusFiltered[0])
  );
};

export const convertLevelOfImpactColor = (level) => {
  return levelOfImpact.filter((l) => l.key === level)[0].color;
};

export const convertLevelOfImpactColorMobile = (level) => {
  return levelOfImpact.filter((l) => l.key === level)[0].link;
};

export const typeNewsTemplate = [
  {
    code: 0,
    description: "Text",
  },
  {
    code: 1,
    description: "Bảng",
  },
];

export const getConditionList = (listInferCondition) => {
  if (listInferCondition === null) {
    return "";
  } else {
    return listInferCondition
      .map((condition) => {
        const { element, hour, date, operator, value } = condition;
        return (
          "(" +
          getConditionConvert(
            element.elementName,
            hour,
            date,
            operator,
            value
          ) +
          ")"
        );
      })
      .reduce((prev, curr) => [prev, " AND ", curr]);
  }
};

export const getConditionConvert = (
  elementName,
  hour,
  date,
  operator,
  value
) => {
  let condition = elementName;
  if (hour) {
    condition = condition + "&" + hour;
  }
  if (date !== null) {
    condition = condition + "&" + date;
  }
  const opera = operatorList.filter((op) => op.key === operator)[0];
  condition = condition + opera.value + value;
  return condition;
};

export const operatorList = [
  {
    key: 0,
    value: "=",
  },
  {
    key: 1,
    value: "<",
  },
  {
    key: 2,
    value: "<=",
  },
  {
    key: 3,
    value: ">",
  },
  {
    key: 4,
    value: ">=",
  },
  {
    key: 5,
    value: "=!",
  },
];

export const elementList = [
  {
    key: 3,
    value: "Mưa",
  },
  {
    key: 4,
    value: "Gió",
  },
  {
    key: 5,
    value: "Nhiệt độ",
  },
  {
    key: 6,
    value: "Độ ẩm",
  },
  {
    key: 7,
    value: "Nhiệt độ cảm nhận",
  },
  {
    key: 8,
    value: "Mây",
  },
];

export const hours = [
  {
    key: 1,
    value: "01:00",
  },
  {
    key: 2,
    value: "02:00",
  },
  {
    key: 3,
    value: "03:00",
  },
  {
    key: 4,
    value: "04:00",
  },
  {
    key: 5,
    value: "05:00",
  },
  {
    key: 6,
    value: "06:00",
  },
  {
    key: 7,
    value: "07:00",
  },
  {
    key: 8,
    value: "08:00",
  },
  {
    key: 9,
    value: "09:00",
  },
  {
    key: 10,
    value: "10:00",
  },
  {
    key: 11,
    value: "11:00",
  },
  {
    key: 12,
    value: "12:00",
  },
  {
    key: 13,
    value: "13:00",
  },
  {
    key: 14,
    value: "14:00",
  },
  {
    key: 15,
    value: "15:00",
  },
  {
    key: 16,
    value: "16:00",
  },
  {
    key: 17,
    value: "17:00",
  },
  {
    key: 18,
    value: "18:00",
  },
  {
    key: 19,
    value: "19:00",
  },
  {
    key: 20,
    value: "20:00",
  },
  {
    key: 21,
    value: "21:00",
  },
  {
    key: 22,
    value: "22:00",
  },
  {
    key: 23,
    value: "23:00",
  },
  {
    key: 0,
    value: "00::00",
  },
];

const state = {
  searchText: "",
  searchedColumn: "",
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

const handleReset = (clearFilters) => {
  clearFilters();
  this.setState({ searchText: "" });
};

let searchInput;

export const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Tìm kiếm ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Tìm kiếm
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: (text) =>
    state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

export const dateForecastedList = [
  {
    key: 0,
    value: "Ngày báo cáo",
  },
  {
    key: 1,
    value: "Ngày báo cáo + 1",
  },
  {
    key: 2,
    value: "Ngày báo cáo + 2",
  },
];

export const typeUser = [
  {
    key: 0,
    value: "Quản trị viên",
  },
  {
    key: 1,
    value: "Chỉ đọc",
  },
];

export const repeatAfterList = [
  {
    key: 2,
    value: "Không lặp lại",
  },
  {
    key: 1,
    value: "Lặp lại hằng ngày",
  },
  {
    key: 3,
    value: "Lặp lại hằng tuần",
  },
  {
    key: 4,
    value: "Lặp lại hằng tháng",
  },
];

export const listElement = [
  {
    key: 0,
    value: "Thời tiết",
  },
  {
    key: 1,
    value: "Hệ thống",
  },
  {
    key: 2,
    value: "Nội suy logic",
  },
  {
    key: 3,
    value: "Thứ cấp",
  },
  {
    key: 4,
    value: "Thời gian",
  },
];

export const alertChannels = [
  {
    value: "SMS",
  },
  {
    value: "Email",
  },
];

export const alertTimes = [
  {
    key: 1,
    value: "1h",
  },
  {
    key: 2,
    value: "2h",
  },
  {
    key: 3,
    value: "3h",
  },
  {
    key: 6,
    value: "6h",
  },
  {
    key: 12,
    value: "12h",
  },
  {
    key: 24,
    value: "24h",
  },
];

export const relationships = [
  {
    key: 0,
    value: "AND",
  },
  {
    key: 1,
    value: "OR",
  },
];

export const listGroupBy = [
  {
    key: 0,
    value: "Không nhóm",
  },
  {
    key: 1,
    value: "Theo điểm",
  },
  {
    key: 2,
    value: "Theo ngày",
  },
];

export const listDateGetData = [
  {
    key: 0,
    value: "Ngày được dự báo",
  },
  {
    key: 1,
    value: "Ngày dự báo +1",
  },
  {
    key: 2,
    value: "Ngày dự báo +2",
  },
];

export const typeFile = [
  {
    key: 0,
    value: "Excel",
  },
  {
    key: 1,
    value: "Word",
  },
  {
    key: 2,
    value: "PDF",
  },
];

export const periodList = [
  // {
  //   key: 0,
  //   value: null,
  //   name: "Tất cả"
  // },
  {
    key: 1,
    value: "60",
    name: "60",
  },
  {
    key: 1,
    value: "180",
    name: "180",
  },
  {
    key: 1,
    value: "360",
    name: "360",
  },
  {
    key: 1,
    value: "720",
    name: "720",
  },
  {
    key: 1,
    value: "1440",
    name: "1440",
  },
];

export const telcoList = [
  {
    value: "Vinaphone",
  },
  {
    value: "Viettel",
  },
  {
    value: "Mobifone",
  },
];

export const statusList = [
  {
    key: 0,
    value: "Ngừng kích hoạt",
  },
  {
    key: 1,
    value: "Kích hoạt",
  },
];

export const levelOfImpact = [
  {
    key: 0,
    value: "Quan trọng",
    color: "#FFA800",
    link: ellipse_y,
  },
  {
    key: 1,
    value: "Rất quan trọng",
    color: "#FC7A32",
    link: ellipse_or,
  },
  {
    key: 2,
    value: "Cực kỳ quan trọng",
    color: "#EB0713",
    link: ellipse_red,
  },
];

export const listCareTime = [
  {
    key: "0-24",
    value: "Cả ngày",
  },
  {
    key: "6-18",
    value: "Thời gian làm việc hành chính",
  },
  {
    key: "0-6",
    value: "Ban đêm",
  },
];

export const listTime = [
  {
    key: 0,
    value: "00:00",
  },
  {
    key: 1,
    value: "01:00",
  },
  {
    key: 2,
    value: "02:00",
  },
  {
    key: 3,
    value: "03:00",
  },
  {
    key: 4,
    value: "04:00",
  },
  {
    key: 5,
    value: "05:00",
  },
  {
    key: 6,
    value: "06:00",
  },
  {
    key: 7,
    value: "07:00",
  },
  {
    key: 8,
    value: "08:00",
  },
  {
    key: 9,
    value: "09:00",
  },
  {
    key: 10,
    value: "10:00",
  },
  {
    key: 11,
    value: "11:00",
  },
  {
    key: 12,
    value: "12:00",
  },
  {
    key: 13,
    value: "13:00",
  },
  {
    key: 14,
    value: "14:00",
  },
  {
    key: 15,
    value: "15:00",
  },
  {
    key: 16,
    value: "16:00",
  },
  {
    key: 17,
    value: "17:00",
  },
  {
    key: 18,
    value: "18:00",
  },
  {
    key: 19,
    value: "19:00",
  },
  {
    key: 20,
    value: "20:00",
  },
  {
    key: 21,
    value: "21:00",
  },
  {
    key: 22,
    value: "22:00",
  },
  {
    key: 23,
    value: "23:00",
  },
];

export const convertTypeNewsTemplate = (recipeName) => {
  const statusFiltered =
    typeNewsTemplate &&
    typeNewsTemplate.filter((item) => item.code === recipeName);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderStatusColorTransaction(statusFiltered[0])
  );
};

export const convertTypeElement = (code) => {
  const STATUS = [
    {
      code: 0,
      description: "Loại yếu tố thời tiết",
    },
    {
      code: 1,
      description: "Loại yếu tố hệ thống",
    },
    {
      code: 2,
      description: "Loại yếu tố nội suy logic",
    },
    {
      code: 3,
      description: "Loại yếu tố thứ cấp",
    },
    {
      code: 3,
      description: "Loại yếu tố thời gian",
    },
  ];
  const statusFiltered = STATUS && STATUS.filter((item) => item.code === code);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderColorElementType(statusFiltered[0])
  );
};

export const convertMasterData = (field, value, key, resultField) => {
  const masterDataList = JSON.parse(getLocalStorage(key));
  const masterDataFiltered =
    masterDataList &&
    masterDataList.length > 0 &&
    masterDataList.filter((item) => item[field] === value);

  return (
    masterDataFiltered &&
    masterDataFiltered.length > 0 &&
    masterDataFiltered[0][resultField]
  );
};

export const numberWithDots = (dot) =>
  dot && dot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

export const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const getPathPosition = (string, subString, index) =>
  string.split(subString, index).join(subString).length;

export const convertStatusToTagColor = (status = -1, type = 1) => {
  let color = null;
  let content = null;
  switch (status) {
    case -1:
      color = "#f86c6b";
      content = "Đang Đóng";
      break;
    case 1:
      color = "#82af6f";
      if (type === 1) {
        // content = "Hoạt động update";
        content = "Hoat động";
      } else {
        content = "Hoat động";
      }
      break;
    case 2:
      color = "#20a8d8";
      content = "Khởi tạo";
      break;
    case 3:
      color = "#ffc107";
      content = "Chờ KD duyệt";
      break;
    case 4:
      color = "#ffc107";
      content = "Chờ KT duyệt";
      break;
    case 5:
      color = "#989898";
      content = "KD từ chối";
      break;
    case 6:
      color = "#989898";
      content = "KT từ chối";
      break;
    case -2:
      color = "#000";
      content = "Đã hủy";
      break;
    default:
      break;
  }

  return <Tag color={color}>{content}</Tag>;
};

export const convertStatusInstallment = (code, description) => {
  let color = "";
  switch (code) {
    case 0:
      color = "#a569bd";
      break;
    case 1:
      color = "#20a8d8";
      break;
    case 2:
      color = "#E07E06";
      break;
    case 3:
      color = "#f86c6b";
      break;
    case 4:
      color = "rgba(248,107,168,0.99)";
      break;
    case 5:
      color = "#c0392b";
      break;
    case 6:
      color = "#4dbd74";
      break;
    case 7:
      color = "#b60d0d";
      break;
    default:
      break;
  }

  return <span style={{ color }}>{description}</span>;
};

export const convertStatusInstallmentCtt = (code, description) => {
  let color = "";
  switch (code) {
    case 0:
      color = "#4dbd74";
      break;
    case 1:
      color = "#E07E06";
      break;
    case 2:
      color = "#20a8d8";
      break;
    case 3:
      color = "#c0392b";
      break;
    case 4:
      color = "#a569bd";
      break;
    default:
      break;
  }

  return <span style={{ color }}>{description}</span>;
};

export const getDescriptionReceiveMoneyMethod = (type = "1") => {
  let receiveMethod = "Phương thức nhận tiền bằng tài khoản ngân hàng";
  if (type === "2") {
    receiveMethod = "Phương thức nhận tiền bằng tài khoản ví";
  }
  return receiveMethod;
};

let delayTimer;

export const handleDelay = (e, searchFunc) => {
  if (e && e.length > 2) {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      searchFunc();
    }, 1000);
  }
};

export const listUpdateStatusTransaction = (status) => {
  let arrayStatusTrans = [];
  switch (status) {
    case 8:
      arrayStatusTrans = [
        {
          code: 8,
          name: "Check",
        },
        {
          code: 0,
          name: "Succes",
        },
        {
          code: 1,
          name: "Fail",
        },
        {
          code: 13,
          name: "Reversal P3",
        },
        {
          code: 15,
          name: "Dispute",
        },
      ];
      break;
    case 14:
      arrayStatusTrans = [
        {
          code: 14,
          name: "Check by user",
        },
        {
          code: 15,
          name: "Dispute",
        },
      ];
      break;
    case 15:
      arrayStatusTrans = [
        {
          code: 15,
          name: "Dispute",
        },
        {
          code: 13,
          name: "Reversal P3",
        },
      ];
      break;
    default:
      break;
  }

  return arrayStatusTrans;
};

export const listUpdateStatusTransactionRefund = (
  status,
  debitAmount,
  realAmount,
  typeRefund
) => {
  let arrayStatusTransRefund = [];

  switch (status) {
    case 0:
      if (debitAmount - realAmount > 0 && typeRefund === 2) {
        arrayStatusTransRefund = [
          {
            code: 0,
            name: "Pending Reversal",
          },
          {
            code: 4,
            name: "Reversal Rejected",
          },
        ];
        break;
      }
      arrayStatusTransRefund = [
        {
          code: 0,
          name: "Pending Reversal",
        },
        {
          code: 2,
          name: "Reversal Manual",
        },
        {
          code: 4,
          name: "Reversal Rejected",
        },
        {
          code: 16,
          name: "Reversal Auto",
        },
      ];
      break;
    case 4:
      arrayStatusTransRefund = [
        {
          code: 4,
          name: "Reversal Rejected",
        },
        {
          code: 2,
          name: "Reversal Manual",
        },
        {
          code: 16,
          name: "Reversal Auto",
        },
      ];
      break;
    case 17:
      arrayStatusTransRefund = [
        {
          code: 17,
          name: "Reversal Fail",
        },
        {
          code: 2,
          name: "Reversal Manual",
        },
        {
          code: 16,
          name: "Reversal Auto",
        },
      ];
      break;
    case 18:
      arrayStatusTransRefund = [
        {
          code: 18,
          name: "Reversal Timeout",
        },
        {
          code: 16,
          name: "Reversal Auto",
        },
        {
          code: 17,
          name: "Reversal Fail",
        },
      ];
      break;
    default:
      break;
  }

  return arrayStatusTransRefund;
};

export const convertValueToName = (code, key) => {
  const CodeAndNameList =
    getLocalStorage(key) && JSON.parse(getLocalStorage(key));
  const statusFiltered =
    CodeAndNameList && CodeAndNameList.filter((item) => item.value === code);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderStatusColor(statusFiltered[0].description)
  );
};

export const convertCodeToName = (code, key) => {
  const CodeAndNameList =
    getLocalStorage(key) && JSON.parse(getLocalStorage(key));
  const statusFiltered =
    CodeAndNameList && CodeAndNameList.filter((item) => item.code === code);
  return (
    statusFiltered &&
    statusFiltered.length > 0 &&
    renderStatusColor(statusFiltered[0].description)
  );
};

export const onClickRow = (record, setState) => ({
  onClick: () => {
    setState(record.id);
  },
});

export const setRowClassName = (record, state) =>
  record.id === state ? "clickRowStyl" : "";

export const convertTypeSourceTransaction = (arrTypeSource, values) => {
  let tempTypeSource = [];
  const localStorageTypeSource = getLocalStorage("TYPESOURCE")
    ? JSON.parse(getLocalStorage("TYPESOURCE"))
    : [];

  if (arrTypeSource && arrTypeSource.length) {
    if (arrTypeSource.length === localStorageTypeSource.length + 1) {
      arrTypeSource.shift();
    }
    values.viewTypeSource = arrTypeSource;
    tempTypeSource = arrTypeSource.map((item) => item.value);
    values.typeSourceList = tempTypeSource;
    values.typeSource = tempTypeSource;
  }
  return values;
};

export const checkRangeMonths = (
  fromDate,
  toDate,
  range,
  type = "Tìm kiếm"
) => {
  const toDateMoment = moment(toDate, "DD/MM/YYYY HH:mm:ss").subtract(
    range,
    "months"
  );
  const checkTime =
    new Date(moment(fromDate, "DD/MM/YYYY HH:mm:ss")).getTime() <
    new Date(toDateMoment).getTime();
  const checkTimeError =
    moment(fromDate, "DD/MM/YYYY HH:mm:ss") >
    moment(toDate, "DD/MM/YYYY HH:mm:ss");
  if (checkTimeError) {
    notificationPopup("error", "Từ ngày không được lớn hơn đến ngày");
  } else if (checkTime) {
    notificationPopup(
      "error",
      `Khoảng thời gian ${type} trong vòng ${range} tháng.`
    );
  }
  return checkTime || checkTimeError;
};

export const checkRangeDays = (fromDate, toDate, range, type = "Tìm kiếm") => {
  const toDateMoment = moment(toDate).subtract(range, "days");
  const checkTime =
    new Date(moment(fromDate, "DD/MM/YYYY HH:mm:ss")).getTime() <
    new Date(toDateMoment).getTime();
  const checkTimeError =
    moment(fromDate, "DD/MM/YYYY HH:mm:ss") >
    moment(toDate, "DD/MM/YYYY HH:mm:ss");
  if (checkTimeError) {
    notificationPopup("error", "Từ ngày không được lớn hơn đến ngày");
  } else if (checkTime && range) {
    notificationPopup(
      "error",
      `Khoảng thời gian ${type} không quá ${range} ngày.`
    );
  }
  return checkTime || checkTimeError;
};

export const checkTimeErrorSearch = (fromDate, toDate) => {
  const checkTimeError =
    moment(fromDate, "DD/MM/YYYY HH:mm:ss") >
    moment(toDate, "DD/MM/YYYY HH:mm:ss");
  if (checkTimeError) {
    notificationPopup("error", "Từ ngày không được lớn hơn đến ngày");
  }
  return checkTimeError;
};

export const convertStringMultiSelect = (data) => {
  const list = JSON.parse(getLocalStorage("TYPE_SOURCE"));
  if (!data) {
    return "";
  }
  const arrayValue = data && data.map((x) => x.value);
  if (
    arrayValue &&
    (arrayValue.length === list.length + 1 || arrayValue.length === 0)
  ) {
    return "";
  }
  return arrayValue.join();
};

export const makePassword = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatPrice = (price) =>
  price && price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const messageRequired = (field) =>
  `Trường thông tin ${field} bắt buộc điền, vui lòng nhập.`;

export const handleDataSelect = (key, value) => ({
  key,
  value,
});

export const addAllOption = (key, setState) => {
  if (getLocalStorage(key)) {
    const list = JSON.parse(getLocalStorage(key));
    if (list) {
      if (key === "TYPESOURCE") {
        const tempTypeSource =
          list &&
          list.map((item) => ({ value: item.code, label: item.description }));
        setState(tempTypeSource);
      } else {
        list.unshift({ code: "", description: "Tất cả" });
        setState(list || []);
      }
    }
  }
};

export const validateDateTransaction = (dateTransaction, rangerDate) => {
  if (
    moment(dateTransaction[0]).startOf("day").add(rangerDate, "day") <
    moment(dateTransaction[1]).endOf("day")
  ) {
    notificationPopup(
      "error",
      `Chỉ cho phép thời gian tìm kiếm trong khoảng ${rangerDate} ngày`
    );
    return false;
  }
  if (moment(dateTransaction[0]) > moment(dateTransaction[1])) {
    notificationPopup("error", "Khoảng thời gian tìm kiếm không hợp lệ");
    return false;
  }
  return true;
};

export const setStateMerchantTerminal = (
  initSelectMerchant,
  initSelectTerminal,
  setShopList,
  setTerminalList
) => {
  const shops = getLocalStorage("SHOPS")
    ? JSON.parse(getLocalStorage("SHOPS"))
    : [];
  const terminal = getLocalStorage("TERMINALS")
    ? JSON.parse(getLocalStorage("TERMINALS"))
    : [];
  setShopList(
    shops.length ? [...initSelectMerchant, ...shops] : [...initSelectMerchant]
  );
  setTerminalList(
    terminal.length
      ? [...initSelectTerminal, ...terminal]
      : [...initSelectTerminal]
  );
};

export const convertPayTypeToName = (paymentSource) => {
  let namePaymentSource = "";
  switch (paymentSource) {
    case 1:
      namePaymentSource = "VNPAY_QR";
      break;
    case 2:
      namePaymentSource = "CTT";
      break;
    case 3:
      namePaymentSource = "SPOS";
      break;
    default:
      break;
  }
  return namePaymentSource;
};

export const convertPayLoadCttChannel = (payType) => {
  let namePayLoadCtt = "";
  switch (payType) {
    case 1:
      namePayLoadCtt = "Thanh toán qua web";
      break;
    case 2:
      namePayLoadCtt = "Thanh toán Token";
      break;
    case 3:
      namePayLoadCtt = "Thanh toán qua QrCode";
      break;
    default:
      break;
  }
  return namePayLoadCtt;
};

export const convertPayLoadMmsChannel = (serviceCode) => {
  let namePayLoadMms = "";
  switch (serviceCode) {
    case 2:
      namePayLoadMms = "Qr-Cổng";
      break;
    case 4:
      namePayLoadMms = "Qr-Billing";
      break;
    case 5:
      namePayLoadMms = "Qr-Sản phẩm";
      break;
    case 6:
      namePayLoadMms = "Qr-Terminal";
      break;
    default:
      break;
  }
  return namePayLoadMms;
};

export const convertTerminalInShop = (listTerminal, listShop) => {
  const listTerminalInShop = {
    shops: [],
  };
  for (let i = 0; i < listShop.length; i += 1) {
    const arrayTerminal = [];
    arrayTerminal.length = 0;
    for (let j = 0; j < listTerminal.length; j += 1) {
      if (listShop[i].id === listTerminal[j].shopId) {
        const objTerminal = {
          terminalCode: listTerminal[j].terminalCode,
          id: listTerminal[j].id,
          shopId: listShop[i].id,
          terminalName: listTerminal[j].terminalName,
        };
        arrayTerminal.push(objTerminal);
      }
    }
    const objShop = {
      id: listShop[i].id,
      shopBrand: listShop[i].shopBrand,
      shopCode: listShop[i].shopCode,
      shopName: listShop[i].shopName,
      terminalEls: JSON.parse(JSON.stringify(arrayTerminal)),
    };
    listTerminalInShop.shops.push(objShop);
  }
  return listTerminalInShop;
};

export const convertTerminalHaveShop = (listTerminal, listShop) => {
  const arrayTerminal = [];
  for (let i = 0; i < listShop.length; i += 1) {
    for (let j = 0; j < listTerminal.length; j += 1) {
      if (listTerminal[j].shopId && listShop[i].id === listTerminal[j].shopId) {
        const objTerminal = {
          terminalId: listTerminal[j].id,
          shopId: listShop[i].id,
        };
        arrayTerminal.push(objTerminal);
      }
    }
  }
  return arrayTerminal;
};

export const multiplyNumbers = (x, y) => x * y;

export const clearLocalStorage = () => {
  localStorage.removeItem("lastLogin");
  localStorage.removeItem("access_token");
  localStorage.removeItem("token_ums");
  localStorage.removeItem("refreshToken_mcv");
  localStorage.removeItem("username_mcv");
  localStorage.removeItem("user");
  localStorage.removeItem("expTimeToken_mcv");
  localStorage.removeItem("expTimeRefreshToken_mcv");
  localStorage.removeItem("aboutTimeToken_mcv");
  localStorage.removeItem("aboutTimeRefreshToken_mcv");
  localStorage.removeItem("ACCOUNT_TYPE");
  localStorage.removeItem("PAYMENT_SOURCE");
  localStorage.removeItem("TRANSACTION_ROLES");
  localStorage.removeItem("TRANSACTION");
  localStorage.removeItem("REFUND");
  localStorage.removeItem("TYPESOURCE");
  localStorage.removeItem("PROVINCE");
  localStorage.removeItem("PAYCHANNEL");
  localStorage.removeItem("AGENCY_SELECTED");
  localStorage.removeItem("AGENCY_LIST");
  localStorage.removeItem("SHOPS");
  localStorage.removeItem("TERMINALS");
  localStorage.removeItem("transactionInfo");
  localStorage.removeItem("transactionInfoRefund");
  localStorage.removeItem("WALLET_ROLES");
  localStorage.removeItem("BUSINESS_JOB");
  localStorage.removeItem("BUSINESS_TYPE");
  localStorage.removeItem("qrItemProduct");
  localStorage.removeItem("INSTALLMENT_STATUS");
  localStorage.removeItem("QRCODE_UPDATE");
  localStorage.removeItem("TYPE_PAPER");
  localStorage.removeItem("INSTALLMENT_STATUS_CTT");
  localStorage.removeItem("BANKS");
  localStorage.removeItem("access_token");
};

export const divFlex = (flex, index) => {
  return <div key={index} style={{ flexGrow: flex }} />;
};

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const locale = (description) => {
  return { emptyText: <Empty description={description} /> };
};

export const descriptionConvert = (description) => {
  return (
    <EllipsisCustomer
      rows={3}
      expandable={false}
      className={`insight-page-contain-description cl-text-coins-insight`}
    >
      {description}
    </EllipsisCustomer>
  );
};

export const titleConvert = (title) => {
  return (
    <EllipsisCustomer
      rows={1}
      expandable={false}
      className={`insight-page-contain-title cl-main-title`}
    >
      {title}
    </EllipsisCustomer>
  );
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const listCareer = [
  {
    value: "NONG_NGHIEP",
    name: "formListCareer1",
  },
  {
    value: "XAY_DUNG",
    name: "formListCareer2",
  },
  {
    value: "KHAI_KHOANG",
    name: "formListCareer3",
  },
  {
    value: "DU_LICH",
    name: "formListCareer4",
  },
  {
    value: "TO_CHUC_SU_KIEN",
    name: "formListCareer5",
  },
  {
    value: "VAN_TAI",
    name: "formListCareer6",
  },
  {
    value: "DICH_VU",
    name: "formListCareer7",
  },
  {
    value: "NHA_HANG",
    name: "formListCareer8",
  },
  {
    value: "BAN_LE",
    name: "formListCareer9",
  },
  {
    value: "BAO_HIEM",
    name: "formListCareer10",
  },
  {
    value: "VIEN_THONG",
    name: "formListCareer11",
  },
  {
    value: "MARKETING",
    name: "formListCareer12",
  },
  {
    value: "NANG_LUONG_TAI_TAO",
    name: "formListCareer13",
  },
  {
    value: "GIAO_DUC",
    name: "formListCareer14",
  },
  {
    value: "PHONG_CHONG_THIEN_TAI",
    name: "formListCareer15",
  },
  {
    value: "THE_THAO",
    name: "formListCareer16",
  },
  {
    value: "THOI_TRANG",
    name: "formListCareer18",
  },
  {
    value: "THUY_SAN",
    name: "formListCareer19",
  },
  {
    value: "KHAI_THAC_DAU_KHI",
    name: "formListCareer20",
  },
  {
    value: "NGANH_KHAC",
    name: "formListCareer17",
  },
];

export const EllipsisCustomer = ({ rows, expandable, className, children }) => {
  return (
    <Typography.Paragraph
      className={className}
      key={uuid()}
      ellipsis={{ rows: rows, expandable: expandable, symbol: "Xem thêm" }}
      style={{ height: "auto" }}
    >
      {children}
    </Typography.Paragraph>
  );
};

export const convertDirection = (windDirectionEn) => {
  let windDirection;
  switch (windDirectionEn) {
    case "NNE":
      windDirection = 22.5;
      break;
    case "NE":
      windDirection = 45;
      break;
    case "ENE":
      windDirection = 67.5;
      break;
    case "E":
      windDirection = 90;
      break;
    case "ESE":
      windDirection = 112.5;
      break;
    case "SE":
      windDirection = 135;
      break;
    case "SSE":
      windDirection = 157.5;
      break;
    case "S":
      windDirection = 180;
      break;
    case "SSW":
      windDirection = 202.5;
      break;
    case "SW":
      windDirection = 225;
      break;
    case "WSW":
      windDirection = 247.5;
      break;
    case "W":
      windDirection = 270;
      break;
    case "WNW":
      windDirection = 292.5;
      break;
    case "NW":
      windDirection = 315;
      break;
    case "NNW":
      windDirection = 337.5;
      break;
    case "N":
      windDirection = 0;
      break;
    default:
      windDirection = 90;
  }
  return windDirection;
};

export const convertTitleAdd = (title, note) => {
  return (
    <Row justify={"start"}>
      <Col
        xl={note === 1 ? (isMobileOnly ? 11 : 11) : isMobileOnly ? 24 : 24}
        lg={note === 1 ? (isMobileOnly ? 11 : 11) : isMobileOnly ? 24 : 6}
        md={note === 1 ? (isMobileOnly ? 11 : 11) : isMobileOnly ? 24 : 7}
        sm={note === 1 ? (isMobileOnly ? 11 : 11) : isMobileOnly ? 24 : 9}
        className={"title-add"}
      >
        {title}
      </Col>
      {note === 1
        ? !isMobileOnly && (
            <Col
              span={isMobileOnly ? 11 : 5}
              className={"tool-tip-add"}
              style={{ marginRight: 0 }}
            >
              Mục có dấu <span>*</span> là bắt buộc
            </Col>
          )
        : ""}
    </Row>
  );
};

export const convertConditionOnboarding = (record) => {
  const listGroupCondition = [];
  record.groupConditions.forEach((groupCondition) => {
    let listCondition = [];
    groupCondition.conditions.forEach((condition) => {
      let stringConvert;
      if (record.advanced === 1) {
        const opera = operatorList.filter(
          (op) => op.key === condition.operator
        )[0];
        stringConvert =
          "(" +
          condition.elementId.description +
          opera?.value +
          condition.value +
          condition.elementId?.measureUnit +
          ")";
      } else {
        stringConvert = condition.elementId?.elementName;
      }
      listCondition.push(stringConvert);
    });
    let joinCondition = listCondition.join(" & ");
    // joinCondition = "[" + joinCondition + "]";
    listGroupCondition.push(joinCondition);
    console.log(" listGroupCondition :", listGroupCondition);
  });
  return listGroupCondition.map((condition, index) => {
    return (
      <>
        {/*className={"container-condition-table"}*/}
        <span>
          [
          <span className={"condition-mobile"} key={index}>
            {condition}
          </span>
          ]
        </span>
        {listGroupCondition.length - 1 === index ? "" : <span>{" hoặc "}</span>}
      </>
    );
  });
};

export const convertCondition = (record) => {
  const listGroupCondition = [];
  record.groupConditions.forEach((groupCondition) => {
    let listCondition = [];
    groupCondition.conditions.forEach((condition) => {
      let stringConvert;
      if (record.advanced === 1) {
        const opera = operatorList.filter(
          (op) => op.key === condition.operator
        )[0];
        stringConvert =
          "(" +
          condition.elementId.description +
          opera?.value +
          condition.value +
          condition.elementId?.measureUnit +
          ")";
      } else {
        stringConvert = condition.elementId?.elementName;
      }
      listCondition.push(stringConvert);
    });
    let joinCondition = listCondition.join(" & ");
    // joinCondition = "[" + joinCondition + "]";
    listGroupCondition.push(joinCondition);
    console.log(" listGroupCondition :", listGroupCondition);
  });
  return listGroupCondition.map((condition, index) => {
    return (
      <>
        {/*className={"container-condition-table"}*/}
        <span>
          [
          <span
            className={"condition-mobile cl-text-condition-weather"}
            key={index}
          >
            {condition}
          </span>
          ]
        </span>
        {listGroupCondition.length - 1 === index ? "" : <span>{" hoặc "}</span>}
      </>
    );
  });
};

export const convertConditionMobile = (record) => {
  const listGroupCondition = [];
  record.groupConditions.forEach((groupCondition) => {
    let listCondition = [];
    groupCondition.conditions.forEach((condition) => {
      let stringConvert;
      if (record.advanced === 1) {
        const opera = operatorList.filter(
          (op) => op.key === condition.operator
        )[0];
        stringConvert =
          "(" +
          condition.elementId.description +
          opera?.value +
          condition.value +
          condition.elementId?.measureUnit +
          ")";
      } else {
        stringConvert = condition.elementId?.elementName;
      }
      listCondition.push(stringConvert);
    });
    let joinCondition = listCondition.join(" & ");
    // joinCondition = "[" + joinCondition + "]";
    listGroupCondition.push(joinCondition);
  });
  // return listGroupCondition.join(" hoặc ");
  return listGroupCondition.map((condition, index) => {
    return (
      <>
        <span>
          [
          <span
            className={"condition-mobile cl-text-condition-weather"}
            key={index}
          >
            {condition}
          </span>
          ]
        </span>
        {listGroupCondition.length - 1 === index ? "" : <span>{" hoặc "}</span>}
      </>
    );
  });
};
