import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AccountActions from "../../main/account/store/actions";
import * as DashboardActions from "../../main/dashboard/store/actions";
import * as LocationActions from "../../main/location/store/actions";
import { notificationPopup } from "../../helpers/common";
import InfoGraphic from "./infographic";

const Steps6OneClickMobile = React.memo((props) => {
  const { setIsModalVisible, infoSteps, formStep6, data } = props;
  const history = useHistory();
  const onBoarding = useSelector(
    (state) => state.accountData.location.onBoarding
  );
  const dispatch = useDispatch();
  const [checkSubmit, setCheckSubmit] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!onBoarding.fetching && onBoarding.statusCode === 200) {
        dispatch(AccountActions.getAccountById());
        history.push("/dashboards");
        setIsModalVisible(onBoarding.fetching);
        dispatch(
          DashboardActions.fetch({
            sortBy: "",
            rowsPerPage: 999999,
          })
        );
        dispatch(DashboardActions.isOnBoarding(true));
      } else if (!onBoarding.fetching && onBoarding.statusCode === 204) {
        notificationPopup("error", onBoarding.message);
        notificationPopup(
          "info",
          "Bạn có thể xác thực số điện thoại sau bằng cách để trống."
        );
        setCheckSubmit(false);
        dispatch(LocationActions.resetOnBoarding());
      }
    }, 10000);
  }, [onBoarding, setIsModalVisible, history, dispatch]);

  const onFinish = (values) => {
    const dataBody = {
      step2: infoSteps.step2,
      step3: {
        insights: infoSteps.step5.insights.map((insight) => {
          return {
            ...insight,
            id: null,
            type: 1,
            packageInsight: null,
            groupConditions: insight.groupConditions.map((groupCondition) => {
              return {
                conditions: groupCondition.conditions.map((condition) => {
                  return {
                    elementId: condition.elementId.id,
                    operator: condition.operator,
                    value: condition.value,
                  };
                }),
              };
            }),
          };
        }),
      },
      phone: values.phone,
      key: sessionStorage.getItem("key"),
      username: data?.userDetail?.username,
    };
    dispatch(LocationActions.fetchOnBoarding(dataBody));
    setCheckSubmit(true);
  };

  return (
    <div>
      <Row justify="space-around" align="middle">
        <Col
          className="font-text color-text font-weight-7"
          style={{ fontSize: "20px", paddingTop: 20, color: "#000000" }}
        >
          Chúc mừng!
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-5"
          style={{
            fontSize: "16px",
            paddingBottom: 15,
            textAlign: "center",
            color: "#000000",
          }}
        >
          Bạn đã hoàn thành thiết lập ban đầu!
        </Col>
      </Row>
      <InfoGraphic />

      <Form
        name="update-information"
        form={formStep6}
        onFinish={onFinish}
        className="style-add-order"
        initialValues={{}}
      >
        {!checkSubmit ? (
          <Row justify="space-around" align="middle">
            <Col
              span={24}
              className="font-text font-weight-4"
              style={{
                fontSize: "16px",
                textAlign: "center",
                paddingBottom: 20,
                color: "#000000",
              }}
            >
              Nhập số điện thoại để nhận cảnh báo thời tiết
            </Col>
            <Col
              span={23}
              className="font-text font-weight-4"
              style={{
                fontSize: "18px",
                textAlign: "center",
                paddingBottom: 20,
                color: "#000000",
              }}
            >
              <Form.Item name="phone" className={"form-item-step6"}>
                <Input
                  allowClear
                  placeholder={"Nhập số điện thoại"}
                  className={"input-step6"}
                />
              </Form.Item>
            </Col>
            <Col
              className="font-text font-weight-4 font-size"
              style={{ textAlign: "center", color: "#ffffff" }}
            >
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    className="button-first"
                    type="text"
                    htmlType="submit"
                    disabled={false}
                  >
                    Bắt đầu
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Form>

      {checkSubmit ? (
        <>
          <Row justify="space-around" align="middle">
            <Col
              span={20}
              className="font-text font-weight-4"
              style={{
                fontSize: "18px",
                textAlign: "center",
                paddingBottom: 20,
                color: "#000000",
              }}
            >
              Metplus đang thiết lập Bảng khuyến nghị hành động cho bạn
            </Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col
              span={20}
              className="font-text font-weight-4"
              style={{
                fontSize: "18px",
                textAlign: "center",
                paddingBottom: 31,
                color: "#000000",
              }}
            >
              Hãy xem 1 số tính năng tuyệt vời của Metplus nào...
            </Col>
          </Row>
          <Row justify="space-around" align="middle">
            <Col
              span={20}
              className="font-text font-weight-4 cl-main-title"
              style={{ fontSize: "18px", textAlign: "center" }}
            >
              <Spin className="spin-step5" size="large" />
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
    </div>
  );
});

export default Steps6OneClickMobile;
