import * as Actions from "../actions";

const initialState = () => ({
  fetching: true,
  dashboards: {
    fetching: true,
    data: {
      content: [],
    },
    statusCode: null,
    message: null,
  },
  dashboardCreateByCareer: {
    fetching: true,
    data: null,
    statusCode: null,
    message: null,
  },
  dashboardAddInsightLocation: {
    fetching: true,
    data: null,
    statusCode: null,
    message: null,
  },
  isOnBoarding: false,
});

const dashboardReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.DASHBOARD_SET_DATA_SEARCH:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    case Actions.DASHBOARD_SET:
      return {
        ...state,
        post: payload,
      };
    case Actions.ON_BOARDING:
      return {
        ...state,
        isOnBoarding: payload,
      };
    case Actions.DASHBOARD_CATEGORIES_FETCHED:
      return {
        ...state,
        fetching: false,
        dashboards: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.DASHBOARD_UPDATE_NEW:
      return {
        ...state,
        dashboardAddInsightLocation: {
          fetching: false,
          data: payload.data,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.DASHBOARD_FETCHED:
      return {
        ...state,
        dashboards: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.DASHBOARD_CREATE_BY_CAREER:
      return {
        ...state,
        dashboardCreateByCareer: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.DASHBOARD_FETCHING:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          fetching: true,
        },
      };
    case Actions.DASHBOARD_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.DASHBOARD_RESET:
      return initialState();
    default:
      return state;
  }
};

export default dashboardReducer;
