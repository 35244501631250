import AuthConfig from "./auth/auth.config";
import TagConfig from "./tag/tag.config";
import PostConfig from "./post/post.config";
import AdminConfig from "./contact/contact.config";
import DashboardConfig from "./dashboard/dashboard.config";
import WeatherConfig from "./weather/weather.config";
import AlertConfig from "./alerts/alert.config";
import LocationConfig from "./location/location.config";
import InsightConfig from "./insights/insight.config";
import HelpConfig from "./help/help.config";
import AccountConfig from "./account/account.config";
import ProfileConfig from "./profile/profile.config";
import LogNotifyConfig from "./log_notify/log_notify.config";
import Report from "./report/report.config";
import LandingPageConfig from "./landing_page/landing-page.config";
import HomeConfig from "./landing_page/home.config";

const mainConfigs = [
  AuthConfig,
  TagConfig,
  PostConfig,
  AdminConfig,
  DashboardConfig,
  WeatherConfig,
  AlertConfig,
  LocationConfig,
  InsightConfig,
  HelpConfig,
  AccountConfig,
  ProfileConfig,
  LogNotifyConfig,
  Report,
  LandingPageConfig,
  HomeConfig,
];

export default mainConfigs;
