import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "./header.scss";
import Logo from "assets/img/icon_new/logo_dark.png";
import Vector from "assets/img/icon_new/Vector.png";
import { Col, Row, Dropdown, Affix, Badge } from "antd";
// import { useTranslation } from "react-i18next";
import { MenuOutlined } from "@ant-design/icons";
import MenuNotifyMobile from "./Menus/menuNotify.mobile";
// const { Option } = Select;

const HeaderMobile = React.memo((props) => {
  // const history = useHistory();
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [checkClick, setCheckClick] = useState(0);

  const handleCancelShowDropdown = () => {
    setIsShowDropdown(false);
  };
  const clickSetDropdownVisible = () => {
    setCheckClick(1);
    setIsShowDropdown(!isShowDropdown);
  };
  const {
    handleCollapse,
    dataNotify,
    countNotify,
    handleOnClickUpdateAll,
    handleOnClickUpdateNotify,
  } = props;
  // const { t } = useTranslation("common");

  const onClickMenu = () => {
    handleCollapse(true);
  };

  let useClickOutSide = (ref, handleChangeClick) => {
    useEffect(() => {
      let handleClickOutDrop = (e) => {
        if (!ref.current.contains(e.target)) {
          handleChangeClick();
        }
      };
      document.addEventListener("mousedown", handleClickOutDrop);
      return () => {
        document.removeEventListener("mousedown", handleClickOutDrop);
      };
    });
  };
  let ref = useRef();
  const handleChangeClick = () => {
    setCheckClick(0);
  };
  useClickOutSide(ref, handleChangeClick);
  const menu = (
    <MenuNotifyMobile
      dataNotify={dataNotify}
      handleOnClickUpdateAll={handleOnClickUpdateAll}
      handleOnClickUpdateNotify={handleOnClickUpdateNotify}
      handleCancelShowDropdown={handleCancelShowDropdown}
      useClickOutSide={useClickOutSide}
    />
  );
  return (
    <Affix offsetTop={1}>
      <Row className="main-header-mobile bg-cl-header-offical">
        <Col span={12}>
          <Row justify={"start"} align="middle">
            <Col
              span={7}
              style={{ cursor: "pointer", padding: "21px" }}
              onClick={onClickMenu}
            >
              <MenuOutlined
                style={{
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
              />
            </Col>
            <Col span={15}>
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ marginTop: "15px" }}>
          <Row justify={"end"} className="container-notify-mobile">
            <Col span={3} style={{ marginRight: "15px" }} ref={ref}>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                visible={isShowDropdown}
                width={"100%"}
                overlayStyle={{ paddingTop: "30px" }}
              >
                <span
                  // onClick={() => history.push("/alerts")}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    checkClick === 0
                      ? clickSetDropdownVisible()
                      : (handleCancelShowDropdown(), setCheckClick(0))
                  }
                >
                  <Badge
                    count={countNotify}
                    color={"#008000"}
                    offset={[-3, 0]}
                    showZero
                  >
                    <img src={Vector} alt="avatar" style={{ width: "100%" }} />
                  </Badge>
                </span>
              </Dropdown>
            </Col>
            {/* <Col span={8} style={{ marginRight: "5px" }}>
              <Select
                defaultValue={"VNI"}
                style={{
                  fontFamily: "SF Pro Text",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "#F0F0F0",
                  alignItems: "center",
                  letterSpacing: "0.02em",
                  padding: 0,
                }}
                className={"select"}
                dropdownStyle={{ background: "#2c3034" }}
                bordered={false}
              >
                <Option className="option" value={"EN"}>
                  EN
                </Option>
                <Option className="option" value={"VNI"}>
                  VNI
                </Option>
              </Select>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Affix>
  );
});

HeaderMobile.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(HeaderMobile);
