import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import "./header.scss";
import Logo from "assets/img/icon_new/logo_dark.png";
// import LogoLight from "assets/img/icon_new/logo_light.png";
import subtract from "assets/img/icon_new/subtract.png";
import Vector from "assets/img/icon_new/Vector.png";
import Avatar from "assets/img/icon_new/Avatar.png";
import { Col, Dropdown, Row, Badge } from "antd";
import { menu } from "./Menus/menu";
import MenuNotify from "./Menus/menuNotify";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import HeaderMobile from "./header.mobile";
// import Profile from "../../app/main/profile/profile";
import AlertExceedPackage from "../../app/main/profile/alert_excees_package";
import { ListTour } from "./Menus/listTour";
import { isTablet } from "react-device-detect";
// const { Option } = Select;
import { useLocation } from "react-router";

const Header = React.memo((props) => {
  const history = useHistory();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isModalVisibleProfile, setIsModalVisibleProfile] = useState(false);
  const [checkClick, setCheckClick] = useState(0);
  const location = useLocation();
  const {
    handleCollapse,
    dataUser,
    dataNotify,
    countNotify,
    handleOnClickUpdateAll,
    handleOnClickUpdateNotify,
  } = props;

  const { t } = useTranslation("common");
  const clickSetDropdownVisible = () => {
    setCheckClick(1);
    setIsDropdownVisible(!isDropdownVisible);
  };
  const handleCancelShowDropdown = () => {
    setIsDropdownVisible(false);
  };
  const handleClickClosePopupInfo = () => {
    setIsModalVisibleProfile(false);
  };

  let useClickOutSide = (ref, handleChangeClick) => {
    useEffect(() => {
      let handleClickOutDrop = (e) => {
        if (!isMobileOnly ? !ref.current.contains(e.target) : true) {
          handleChangeClick();
        }
      };
      document.addEventListener("mousedown", handleClickOutDrop);
      return () => {
        document.removeEventListener("mousedown", handleClickOutDrop);
      };
    });
  };
  let ref = useRef();
  const handleChangeClick = () => {
    setCheckClick(0);
  };
  useClickOutSide(ref, handleChangeClick);
  const menuNotify = (
    <MenuNotify
      dataNotify={dataNotify}
      handleOnClickUpdateAll={handleOnClickUpdateAll}
      handleOnClickUpdateNotify={handleOnClickUpdateNotify}
      handleShowDropdown={handleCancelShowDropdown}
      useClickOutSide={useClickOutSide}
    />
  );
  useEffect(() => {
    location?.state?.isCreated ? setIsModalVisibleProfile(true) : "";
  }, [setIsModalVisibleProfile, location]);

  return !isMobileOnly ? (
    <Row className="main-header fix-header bg-cl-header-offical">
      <Col span={8}>
        <img
          src={Logo}
          alt="logo"
          style={{
            height: "80px",
            paddingTop: "14px",
            paddingLeft: "33px",
            paddingBottom: "23px",
          }}
        />
      </Col>
      <Col span={16}>
        <Row justify={"end"} style={{ paddingTop: 15 }} className="mr-20">
          <Col style={{ paddingTop: 10 }} className="mr-20 mst-instruction">
            <Dropdown
              overlay={ListTour(history, t, dataUser)}
              placement="bottomRight"
              style={{ width: "400px", height: "600px" }}
            >
              <img
                style={{ width: "22px", cursor: "pointer" }}
                className="avatar"
                src={subtract}
                alt="avatar"
              />
            </Dropdown>
          </Col>
          <Col
            style={{ paddingTop: 10 }}
            className="ant-dropdown-notify mr-20"
            ref={ref}
          >
            <Dropdown
              overlay={menuNotify}
              trigger={["click"]}
              visible={isDropdownVisible}
            >
              <span
                className="ant-dropdown-link cursor-pointer"
                style={{ cursor: "pointer", position: "relative" }}
                onClick={() =>
                  checkClick === 0
                    ? clickSetDropdownVisible()
                    : (handleCancelShowDropdown(), setCheckClick(0))
                }
              >
                <Badge
                  count={countNotify}
                  color={"#008000"}
                  offset={[-3, 0]}
                  showZero
                >
                  <img className="avatar" src={Vector} alt="avatar" />
                </Badge>
              </span>
            </Dropdown>
          </Col>
          <Col className="mr-20">
            <Dropdown
              trigger={isTablet ? ["click"] : ["hover"]}
              overlay={menu(history, t, dataUser)}
              placement="bottomLeft"
              style={{ width: "400px", height: "600px" }}
            >
              <span
                className="ant-dropdown-link cursor-pointer"
                style={{ cursor: "pointer" }}
              >
                <img
                  className="avatar-name"
                  src={Avatar}
                  alt="avatar"
                  width="100%"
                  // onClick={
                  //   isTablet
                  //     ? (e) => e.preventDefault()
                  //     : () => handleClickAvatar()
                  // }
                />
              </span>
            </Dropdown>
          </Col>
          {/* <Col span={4} style={{ paddingTop: "20px", paddingLeft: "0px" }}>
            <Select
              defaultValue={"VNI"}
              style={{
                fontFamily: "SF Pro Text",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                color: "#F0F0F0",
                alignItems: "center",
                letterSpacing: "0.02em",
              }}
              dropdownStyle={{ background: "#2c3034" }}
              bordered={false}
            >
              <Option className="option" value={"EN"}>
                EN
              </Option>
              <Option className="option" value={"VNI"}>
                VNI
              </Option>
            </Select>
          </Col> */}
        </Row>
      </Col>
      <AlertExceedPackage
        isModalVisibleProfile={isModalVisibleProfile}
        handleClickClosePopupInfo={handleClickClosePopupInfo}
      />
    </Row>
  ) : (
    <>
      <HeaderMobile
        handleCollapse={handleCollapse}
        dataNotify={dataNotify}
        countNotify={countNotify}
        handleOnClickUpdateAll={handleOnClickUpdateAll}
        handleOnClickUpdateNotify={handleOnClickUpdateNotify}
      />
      <AlertExceedPackage
        isModalVisibleProfile={isModalVisibleProfile}
        handleClickClosePopupInfo={handleClickClosePopupInfo}
      />
    </>
  );
});

Header.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
