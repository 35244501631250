import React from "react";
import i18next from "i18next";

import { authRoles } from "app/auth";
import en from "./i18n/en";

i18next.addResourceBundle("en", "login", en);

const AuthConfig = {
  settings: { layout: "auth" },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/auth/login",
      exact: true,
      component: React.lazy(() => import("./login")),
    },
    {
      path: "/auto-login",
      exact: true,
      component: React.lazy(() => import("./auto_login")),
    },
    {
      path: "/register",
      exact: true,
      component: React.lazy(() => import("./register")),
    },
    {
      path: "/auth/logout",
      exact: true,
      component: React.lazy(() => import("./logout")),
    },
    {
      path: "/terms-of-service",
      exact: true,
      component: React.lazy(() => import("./terms_of_service")),
    },
  ],
  // Don't need login
  guestPath: [
    "/auth/login",
    "/auth/logout",
    "/register",
    "/terms-of-service",
    "/auto-login",
  ],
};

export default AuthConfig;
