import React from "react";
import { authRoles } from "app/auth";

import i18next from "i18next";
import en from "./i18n/en";

i18next.addResourceBundle("en", "landingPage", en);

const LandingPageConfig = {
  settings: { layout: "landing-page" },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/nong-nghiep",
      exact: true,
      component: React.lazy(() => import("./agricultural")),
    },
    // component: () => <Redirect to="/" />,
  ],
  // Don't need login
  guestPath: ["/nong-nghiep"],
};

export default LandingPageConfig;
