const locale = {
  ButtonAddLocation: "Add location",
  Weather: "Weather forecast",
  Weather72h: "Weather next 72 hours",
  Weather24h: "Weather next 24 hours",
  Chart72h: "Chart Weather next 72h",
  Chart24h: "Chart Weather next 24h",
  Weather14Day: "14 days weather",
  "Forecast information": "Forecast information",
  Report: "Report",
  Location: "Location",
  "Choose Location": "Choose Location",
  Humidity: "Humidity",
  Wind: "Wind Speed",
  "Probability Rain": "Probability Rain",
  Precipitation: "Precipitation",
  RealFeel: "RealFeel",
  Normal: "Normal",
  Warning: "Warning",
  Dangerous: "Dangerous",
  "Cloud cover": "Cloud cover",
  PreviewReport: "Preview Report",
  CancelTextPreviewReport: "",
  NoData: "No Data",
  Date: "Date",
  ReportTableTitleDate: "Time",
  ReportTableTitleFileName: "File Name",
  ReportTableTitleFileDownload: "Download File",
  ReportTableTitleAction: "Action",
  CreateTitleHeader: "Create Location",
  ButtonCancel: "Cancel",
  ButtonCreate: "Add location",
  PlaceholderAddress: "Enter the place you want to search",
};

export default locale;
