import React from "react";
import { Col, Row, Drawer, Menu } from "antd";
import constants from "../../app/helpers/constants";
import Avatar from "../../assets/img/icon_new/Avatar.png";
import "./index.scss";

const SidebarHome = (props) => {
  const { isShowDrawer, onCloseDrawer } = props;
  return (
    <Drawer
      title={
        <Row>
          <Col span={8}>
            <img src={Avatar} alt={"Avatar"} width={30} height={30} />
          </Col>
          <Col span={16} className={"container-sidebar-home"}>
            <span className={"title-sidebar-home"}>Metplus</span>
          </Col>
        </Row>
      }
      placement={"left"}
      visible={isShowDrawer}
      closable={false}
      onClose={onCloseDrawer}
      width={"55%"}
      className="drawer-custom-home"
      style={{ zIndex: 12000, boxShadow: "none" }}
    >
      <Menu mode={"inline"} className="sidebar-right-custom-home">
        {constants.HOME_MENU_ITEMS.map((item) => (
          <Menu.Item
            key={item.url}
            className={"menu-home-page"}
            style={{ paddingLeft: "0px !important" }}
          >
            <a href={item.url} onClick={onCloseDrawer}>
              <Row className={"container-menu-sidebar"}>
                <Col span={24}>
                  <Row className={"content-menu-sidebar"}>
                    <Col span={6} className={"icon-sidebar-home"}>
                      {item.icon}
                    </Col>
                    <Col span={18} className={"sub-title-sidebar-home"}>
                      <span>{item.name}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </a>
          </Menu.Item>
        ))}
      </Menu>
    </Drawer>
  );
};
export default SidebarHome;
