// const domain = "https://api-web-dev.weatherplus.vn";
const domain =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://api-gateway-dev.weatherplus.vn/metplus"
    : "https://api-gateway.weatherplus.vn/metplus";
const domainHome =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://dev.metplus.vn"
    : "https://metplus.vn";
const keycloak =
  process.env.REACT_APP_ENV !== "prod"
    ? "/keycloak.json"
    : "/keycloak-prod.json";

const baseURL = `${domain}/api/`;
const accessTokenKey = "jwt_access_token";
const loginGoogleURL =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://login-dev.metplus.vn/auth/realms/login-metplus/protocol/openid-connect/auth?client_id=metplus&redirect_uri=https://dev.metplus.vn/dashboards&response_type=code&scope=openid&kc_idp_hint=google"
    : "https://login.metplus.vn/auth/realms/login-metplus/protocol/openid-connect/auth?client_id=metplus&redirect_uri=https://metplus.vn/dashboards&response_type=code&scope=openid&kc_idp_hint=google";

const loginFacebookURL =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://login-dev.metplus.vn/auth/realms/login-metplus/protocol/openid-connect/auth?client_id=metplus&redirect_uri=https://dev.metplus.vn/dashboards&response_type=code&scope=openid&kc_idp_hint=facebook"
    : "https://login.metplus.vn/auth/realms/login-metplus/protocol/openid-connect/auth?client_id=metplus&redirect_uri=https://metplus.vn/dashboards&response_type=code&scope=openid&kc_idp_hint=facebook";

const preFixUrlFile =
  process.env.REACT_APP_ENV !== "prod"
    ? "https://api-gateway-dev.weatherplus.vn/metplus/api/guest/public"
    : "https://api-gateway.weatherplus.vn/metplus/api/guest/public";
const apiConfig = {
  baseURL,
  accessTokenKey,
  keycloak,
  loginGoogleURL,
  loginFacebookURL,
  domainHome,
  preFixUrlFile,
};

export default apiConfig;
