const locale = {
  titleHeader: "Help",
  downloadFile: "Download",
  titleHeaderVideo1: "Introducing the Metplus platform",
  titleHeaderVideo2: "Instructions for using the features in Metplus",
  titleHeaderContent1: "1. Configuration steps for new account",
  titleHeaderContent2: "2. User manual Metplus",
  titleHeaderContent3: "3. Data packages",
  titleHeaderContent4: "4. Frequently asked questions",
  titleHeaderContent5: "5. About WeatherPlus",
  titleHeaderContent6: "Terms of use",
};

export default locale;
