export const STEP_RESET = "STEP_RESET";
export const STEP_ERROR = "STEP_ERROR";

export const STEP_FETCHING = "STEP_FETCHING";
export const STEP_FETCHED_DASHBOARD = "STEP_FETCHED_DASHBOARD";
export const STEP_FETCHED_WEATHER = "STEP_FETCHED_WEATHER";
export const STEP_FETCHED_INSIGHT = "STEP_FETCHED_INSIGHT";
export const STEP_FETCHED_INSIGHT_CAREER = "STEP_FETCHED_INSIGHT_CAREER";
export const STEP_FETCHED_INSIGHT_ADD = "STEP_FETCHED_INSIGHT_ADD";
export const STEP_FETCHED_MENU = "STEP_FETCHED_MENU";
export const STEP_FETCHED_INSIGHT_ADD_ADVANCED =
  "STEP_FETCHED_INSIGHT_ADD_ADVANCED";

export const fetch = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_DASHBOARD,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchWeather = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_WEATHER,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchInsight = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_INSIGHT,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchInsightCareer = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_INSIGHT_CAREER,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchInsightAdd = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_INSIGHT_ADD,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchInsightAddAdvanced = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_INSIGHT_ADD_ADVANCED,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const fetchMenu = (params) => async (dispatch) => {
  dispatch({
    type: STEP_FETCHING,
  });

  try {
    dispatch({
      type: STEP_FETCHED_MENU,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: STEP_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: STEP_RESET });
