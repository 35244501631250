import axios from "axios";
import { message } from "antd";
import AuthService from "../../../../auth-keycloak/authservice";
import { NOTIFY_ERROR } from "../../../log_notify/store/actions";
import apiConfig from "app/configs/api.config";
import * as AccountActions from "../../../../main/account/store/actions";

export const PAYMENT_GET_URL = "PAYMENT_URL";
export const PAYMENT_RECURRING = "PAYMENT_URL";
export const PAYMENT_ERROR = "PAYMENT_URL";
export const PAYMENT_FETCH = "PAYMENT_FETCH";
export const PAYMENT_RESET = "ACCOUNT_RESET";

export const getPaymentUrl = (params) => async (dispatch) => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    let req = axios.post(`user/payment`, params);

    const res = await req;

    dispatch({
      type: PAYMENT_GET_URL,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const getRecurringPayment = (params) => async (dispatch) => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    let req;
    if (params?.methodApi === "POST") {
      req = axios.post(params?.urlApi, params);
    } else if (params?.methodApi === "GET") {
      req = axios.get(params?.urlApi);
    }
    const res = await req;

    dispatch({
      type: PAYMENT_RECURRING,
      payload: res.data,
    });
    if (params?.inputCard === true) {
      window.open(
        apiConfig.domainHome +
          "/payment.html" +
          "?ispTxnId=" +
          res.data.ispTxnId +
          "&dataKey=" +
          res.data.dataKey +
          "&tmnCode=" +
          res.data.tmnCode,
        "_self",
        "noopener,noreferrer"
      );
    }
    if (params?.inputCard === false) {
      if (res?.data?.status === 200) {
        message.success("Xóa thẻ thành công", 2);
        dispatch(AccountActions.getAccountById());
      } else {
        message.error("Xóa thẻ thất bại", 2);
      }
    }
  } catch (error) {
    dispatch({
      type: PAYMENT_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const getAllHistoryPayment = (params) => async (dispatch) => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const res = await axios.post(`user/payment/list`, params);

    dispatch({ type: PAYMENT_FETCH, payload: res.data });
  } catch (error) {
    dispatch({
      type: PAYMENT_ERROR,
      payload: error,
    });
    throw error;
  }
};
export const getPageHistoryPayment = (page) => async (dispatch) => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const dataBody = {
      status: 0,
      searchCommon: "",
      page: page,
      sortBy: "createdAt",
      rowsPerPage: 10,
    };
    const res = await axios.post(`user/payment/list`, dataBody);

    dispatch({ type: PAYMENT_FETCH, payload: res.data });
  } catch (error) {
    dispatch({
      type: NOTIFY_ERROR,
      payload: error,
    });
    throw error;
  }
};
export const reset = () => (dispatch) => dispatch({ type: PAYMENT_RESET });
