import React, { useEffect } from "react";
import PropTypes from "prop-types";
import routes from "app/configs/routes.config";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./layout_home.scss";
import "./index.scss";
import { ConfigProvider, Layout, Col, Row } from "antd";
import { Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import Logo from "../../assets/img/icon_new/logo_dark.png";
import Logo from "../../assets/img/icon_new/logo_service_weather.svg";
import apiConfig from "app/configs/api.config";
import HeaderHome from "../../components/Header/header_home";
import FooterHome from "../../components/Footer/FooterHome";
import FooterLandingPage from "../../components/Footer/FooterLandingPage";
const { Content } = Layout;

const LayoutHome = () => {
  const loading = (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </div>
  );
  useEffect(() => {
    document.body.classList.add("body-home-page");
  }, []);
  return (
    <div id={"layouthome-id"}>
      {/*style={{ height: "100vh" }}*/}
      <ConfigProvider>
        {!isMobile ? (
          <Row className="main-header-layout-home">
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <Row justify={"start"}>
                <Col>
                  <a href={apiConfig.domainHome}>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{
                        width: "196px",
                        // paddingTop: "14px",
                        // paddingLeft: "33px",
                        // paddingBottom: "23px",
                        marginLeft: "50px",
                      }}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              <HeaderHome />
            </Col>
          </Row>
        ) : (
          <Row className="main-header-layout-home">
            <Col span={24}>
              <HeaderHome />
            </Col>
          </Row>
        )}
        <Layout
          className={"main-layout-home"}
          style={{
            clear: "both",
          }}
        >
          <Layout className="site-layout-home">
            <Content
              className={isMobile ? "main-content-home" : "main-content-home"}
              style={{
                background: "url('background-register.png') !important",
              }}
            >
              <React.Suspense fallback={loading}>
                <Switch>
                  {routes.map((route, idx) =>
                    route.component ? (
                      <Route
                        key={String(idx)}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(renderProps) => (
                          <route.component {...renderProps} />
                        )}
                      />
                    ) : null
                  )}
                </Switch>
              </React.Suspense>
            </Content>
            {!isMobile ? <FooterHome /> : <FooterLandingPage />}
          </Layout>
        </Layout>
      </ConfigProvider>
    </div>
  );
};

LayoutHome.propTypes = {
  children: PropTypes.element,
};

export default LayoutHome;
