import jwtDecode from "jwt-decode";
import axios from "axios";

import history from "@history";

import Utils from "app/helpers/utils";
import apiConfig from "app/configs/api.config";
import AuthService from "../auth-keycloak/authservice";

axios.defaults.baseURL = apiConfig.baseURL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept-Language"] = "vi";
axios.defaults.withCredentials = true;

class JwtService extends Utils.EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) =>
    new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });

  signInWithEmailAndPassword = (email, password) =>
    axios.post("/auth/login", { email, password }).then((res) => {
      if (!res.data.token) throw res.data.error;

      this.setSession(res.data.token);
      const user = {
        data: res.data.admin,
        role: "USER",
        redirectUrl: "/",
      };

      return user;
    });

  signInWithToken = () =>
    axios
      .get("/account")
      .then((res) => {
        if (!res.data) {
          throw new Error("Failed to login with token.");
        }

        this.setSession(res.data.token);
        return {
          data: res.data.user,
          role: "USER",
        };
      })
      .catch(() => {
        this.logout();
        history.push("/auth/login");
        throw new Error("Failed to login with token.");
      });

  updateUserData = (user) => axios.post("/api/auth/user/update", { user });

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem(apiConfig.accessTokenKey, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(apiConfig.accessTokenKey);
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      history.push("/auth/login");
      return false;
    }

    return true;
  };

  // getAccessToken = () => window.localStorage.getItem(apiConfig.accessTokenKey);
  getAccessToken = () => AuthService.getToken();
}

const instance = new JwtService();

export default instance;
