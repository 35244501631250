import * as Actions from "../actions";

const initialState = () => ({
  insights: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
  loadDashboard: {
    isFormDashboard: false,
  },
  editInsight: {
    fetching: false,
    data: null,
    isShowModal: false,
    isAdvanced: false,
    isCopy: false,
    isContact: false,
  },
  detailInsight: {
    fetching: false,
    data: null,
    isShowModal: false,
    isAdvanced: false,
    isCopy: false,
    isContact: false,
  },
  countData: null,
  careers: {
    fetching: true,
    data: {
      content: [
        {
          subSectors: [
            {
              packageInsights: [
                {
                  insights: [],
                },
              ],
            },
          ],
        },
      ],
    },
    statusCode: null,
    message: null,
  },
  removeInsights: {
    fetching: true,
    statusCode: null,
    message: null,
  },
  packageInsight: {
    fetching: true,
    data: null,
    isShowPackageDetail: false,
    isOnBoarding: false,
  },
  insightOnBoarding: {
    fetching: true,
    data: null,
  },
});

const insightReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.INSIGHT_SET:
      return {
        ...state,
        editInsight: {
          fetching: false,
          data: payload.data,
          isShowModal: payload.isShowModal,
          isCopy: payload.isCopy,
          isContact: payload.isContact,
          isAdvanced: payload.isAdvanced,
        },
      };
    case Actions.INSIGHT_SET_DETAIL:
      return {
        ...state,
        detailInsight: {
          fetching: false,
          data: payload.data,
          isShowModal: payload.isShowModal,
          isCopy: payload.isCopy,
          isContact: payload.isContact,
          isAdvanced: payload.isAdvanced,
        },
      };
    case Actions.INSIGHT_SET_DETAIL_FETCHING:
      return {
        ...state,
        detailInsight: {
          ...state.detailInsight,
          fetching: true,
        },
      };
    case Actions.LOAD_DASHBOARD:
      return {
        ...state,
        loadDashboard: {
          isFormDashboard: payload.isFormDashboard,
        },
      };
    case Actions.INSIGHT_ON_BOARDING:
      return {
        ...state,
        insightOnBoarding: {
          fetching: payload.fetching,
          data: payload.data,
        },
      };
    case Actions.PACKAGE_INSIGHT_SET:
      return {
        ...state,
        packageInsight: {
          fetching: false,
          data: payload.data,
          isShowPackageDetail: payload.isShowPackageDetail,
          isOnBoarding: payload.isOnBoarding,
        },
      };
    case Actions.INSIGHT_REMOVES:
      return {
        ...state,
        removeInsights: {
          fetching: false,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.RESET_INSIGHT_REMOVES:
      return {
        ...state,
        removeInsights: {
          fetching: true,
          statusCode: null,
          message: null,
        },
      };
    case Actions.INSIGHT_FETCHED:
      return {
        ...state,
        insights: {
          fetching: payload.fetching,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.CAREER_FETCHED:
      return {
        ...state,
        careers: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.CAREER_FETCHING:
      return {
        ...state,
        // fetching: true,
      };
    case Actions.INSIGHT_FETCHING:
      return {
        ...state,
        // fetching: true,
      };
    case Actions.INSIGHT_FETCHING_ADMIN:
      return {
        ...state,
        insights: {
          fetching: true,
          data: { ...state.insights.data },
          statusCode: state.insights.status,
          message: state.insights.message,
        },
      };
    case Actions.INSIGHT_COUNT:
      return {
        ...state,
        fetching: true,
        countData: payload.data.content?.length,
      };
    case Actions.INSIGHT_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.CAREER_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.INSIGHT_RESET:
      return initialState();
    default:
      return state;
  }
};

export default insightReducer;
