import React from "react";

import { authRoles } from "app/auth";

import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import { isMobileOnly } from "react-device-detect";

i18next.addResourceBundle("vi", "locations", vi);
i18next.addResourceBundle("en", "locations", en);

const LocationConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/location",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./locations.mobile") : import("./locations")
      ),
    },
  ],
};

export default LocationConfig;
