import React from "react";
import { authRoles } from "app/auth";
import i18next from "i18next";
import vi from "./i18n/vi";
import en from "./i18n/en";
import { isMobileOnly } from "react-device-detect";

i18next.addResourceBundle("vi", "alerts", vi);
i18next.addResourceBundle("en", "alerts", en);

const AlertConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/alerts",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./alerts.mobile") : import("./alerts")
      ),
    },
    {
      path: "/alerts/new",
      exact: true,
      component: React.lazy(() => import("./alert")),
    },
    {
      path: "/alerts/edit/:id",
      exact: true,
      component: React.lazy(() => import("./alert")),
    },
  ],
};

export default AlertConfig;
