import React, { useEffect, useRef, useState } from "react";
import GoogleConfig from "../../configs/google.config";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import * as LocationActions from "../../main/location/store/actions";
import { useDispatch, useSelector } from "react-redux";
// import { notificationPopup } from "../../helpers/common";
// import { Tag } from "antd";
// import { PlusOutlined } from "@ant-design/icons";

const AddLocationNew = React.memo((props) => {
  const { insight, infoSteps, setInfoSteps, setDisablePoint, checkFocus } =
    props;
  const [tags, setTags] = useState([]);
  // const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  // const [editInputIndex, setEditInputIndex] = useState(-1);
  // const [editInputValue, setEditInputValue] = useState("");
  const editInputRef = useRef(null);
  let inputRef = useRef(null);
  const locationCreateUpdate = useSelector(
    (state) => state.accountData.location.locationCreateUpdate
  );

  useEffect(() => {
    if (checkFocus) {
      inputRef.current?.focus();
    }
  }, [checkFocus]);

  useEffect(() => {
    if (locationCreateUpdate.statusCode === 200) {
      setInfoSteps({
        ...infoSteps,
        step5: {
          insights: infoSteps.step5.insights.map((i) => {
            if (i.id === insight.id || i.points.length === 0) {
              return {
                ...i,
                points: [...i.points, locationCreateUpdate.data.id],
              };
            }
            return i;
          }),
        },
      });
      dispatch(LocationActions.reset());
    }
  }, [locationCreateUpdate, infoSteps, setInfoSteps, insight, dispatch]);

  useEffect(() => {
    // if (inputVisible) {
    inputRef.current?.focus();
    // }
  }, [inputRef]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    setTags(
      infoSteps.step5.insights.filter((i) => i.id === insight.id)[0].points
    );
  }, [infoSteps, insight]);

  // const handleClose = (removedTag) => {
  //   const newTags = tags.filter((tag) => tag.address !== removedTag);
  //   setTags(newTags);
  //   updatePoint(newTags);
  // };

  // const showInput = () => {
  //   setInputVisible(true);
  // };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setDisablePoint(true);
  };

  // const showInput = () => {
  //   setInputVisible(true);
  // };

  // const updatePoint = (value) => {
  //   setInfoSteps({
  //     ...infoSteps,
  //     step3: {
  //       insights: infoSteps.step3.insights.map((i) => {
  //         if (i.id === insight.id || i.points.length === 0) {
  //           return {
  //             ...i,
  //             points: [...i.points, value],
  //           };
  //         }
  //         return i;
  //       }),
  //     },
  //   });
  // };

  const handleInputConfirm = (place) => {
    if (
      place?.geometry?.location !== "" &&
      place?.geometry?.location !== undefined &&
      place?.geometry?.location !== null
    ) {
      // if (!tags.some((item) => place?.formatted_address === item.address)) {
      const value = [
        ...tags,
        {
          latitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lat,
          longtitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lng,
          address: place?.formatted_address,
          pointName: place?.formatted_address,
        },
      ];
      setTags(value);
      dispatch(
        LocationActions.createOrUpdate(null, {
          latitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lat,
          longtitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lng,
          address: place?.formatted_address,
          pointName: place?.formatted_address,
          status: 1,
        })
      );
      // updatePoint(value);
      // setInputVisible(false);
      setInputValue("");
      setDisablePoint(false);
      // } else {
      //   notificationPopup(
      //     "error",
      //     "Đã tồn tại địa điểm '" +
      //       place?.formatted_address +
      //       "' trong khuyến nghị '" +
      //       insight.name +
      //       "' của bạn."
      //   );
      // }
    }
  };

  // const handleEditInputChange = (e) => {
  //   setEditInputValue(e.target.value);
  // };

  // const handleEditInputConfirm = (place) => {
  //   if (
  //     place?.geometry?.location !== "" &&
  //     place?.geometry?.location !== undefined &&
  //     place?.geometry?.location !== null
  //   ) {
  //     if (!tags.some((item) => place?.formatted_address === item.address)) {
  //       const newTags = [...tags];
  //       newTags[editInputIndex] = {
  //         latitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lat,
  //         longtitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lng,
  //         address: place?.formatted_address,
  //         pointName: place?.formatted_address,
  //       };
  //       setTags(newTags);
  //       updatePoint(newTags);
  //       setEditInputIndex(-1);
  //       setInputValue("");
  //       setInputVisible(false);
  //     } else {
  //       notificationPopup(
  //         "error",
  //         "Đã tồn tại địa điểm '" +
  //           place?.formatted_address +
  //           "' trong khuyến nghị '" +
  //           insight.name +
  //           "' của bạn."
  //       );
  //     }
  //   }
  // };

  const { t } = useTranslation("account");

  // const changePlace = (place) => {
  //   if (
  //     place?.geometry?.location !== "" &&
  //     place?.geometry?.location !== undefined &&
  //     place?.geometry?.location !== null
  //   ) {
  //     console.log("place", place);
  //   }
  // };

  return (
    <div>
      <Autocomplete
        ref={inputRef}
        mode="multiple"
        value={inputValue}
        apiKey={GoogleConfig.googleApiKey}
        onPlaceSelected={(place) => {
          handleInputConfirm(place);
        }}
        placeholder={t("FormPlaceholderAddress")}
        options={{
          types: [],
          componentRestrictions: { country: "vn" },
        }}
        // onChange={onChangeComplete}
        zIndex={10000000000}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        // onPressEnter={handleInputConfirm}
      />
    </div>
  );
});

export default AddLocationNew;
