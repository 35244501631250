import * as Actions from "../actions";

const initialState = () => ({
  reports: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
  updateReport: {
    dataUpdate: {
      content: null,
    },
    statusUpdateReport: null,
    messageUpdate: null,
  },
  activeKey: "1",
  defaultValueSelect: "weather-data",
});

const reportReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.REPORT_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case Actions.REPORT_FETCHED:
      return {
        ...state,
        reports: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.HELP_CHECK:
      return {
        ...state,
        fetching: false,
        activeKey: payload,
      };
    case Actions.HELP_UPDATE:
      return {
        ...state,
        fetching: false,
        defaultValueSelect: payload,
      };
    case Actions.REPORT_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };

    default:
      return state;
  }
};
export default reportReducer;
