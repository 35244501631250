const locale = {
  titleHeader: "Thay đổi mật khẩu",
  formLabelUserName: "Tài khoản",
  formLabelPasswordOld: "Mật khẩu cũ",
  formMessagePasswordOld: "Vui lòng nhập mật khẩu cũ",
  formPlaceholderPasswordOld: "Nhập mật khẩu cũ",
  formLabelPasswordNew: "Mật khẩu mới",
  formMessagePasswordNew: "Vui lòng nhập mật khẩu mới",
  formPlaceholderPasswordNew: "Nhập mật khẩu mới",
  buttonUpdate: "Cập nhật",

  titleHeaderInfo: "Cập nhập thông tin",
  formLabelCompanyName: "Tên công ty",
  formLabelCareer: "Ngành / Lĩnh vực bạn quan tâm",
  formLabelSubSector: "Lĩnh vực",
  formLabelNumberMember: "Quy mô công ty",
  labelPhone: "Số điện thoại",
  placeholderPhone: "Nhập số điện thoại. Ví dụ: 09xxxxxxxx, 03xxxxxxxx, ...",
  messagePhone: "Vui lòng nhập số điện thoại",
  messageValidatePhone: "Số điện thoại chưa đúng",
  FormPlaceholderAddress: "Nhập địa điểm muốn thêm tại đây",

  formPlaceholderCompanyName: "Nhập tên công ty",
  formPlaceholderCareer: "Chọn ngành",
  formPlaceholderNumberMember: "Chọn số thành viên",

  buttonSave: "Lưu thông tin",

  formMessageCompanyName: "Vui lòng nhập Tên công ty của bạn",
  formMessageCareer: "Vui lòng chọn ngành nghề",
  formMessageNumberMember: "Vui lòng chọn số thành viên",

  messageUpdate: "Cập nhập thông tin thành công !!!",

  formListCareer1: "Nông nghiệp",
  formListCareer2: "Xây dựng",
  formListCareer3: "Khai khoáng",
  formListCareer4: "Du lịch",
  formListCareer5: "Tổ chức sự kiện",
  formListCareer6: "Vận tải",
  formListCareer7: "Dịch vụ",
  formListCareer8: "Nhà hàng",
  formListCareer9: "Bán lẻ",
  formListCareer10: "Bảo hiểm",
  formListCareer11: "Viễn thông",
  formListCareer12: "Marketing",
  formListCareer13: "Năng lượng tái tạo",
  formListCareer14: "Giáo dục",
  formListCareer15: "Phòng chống thiên tai",
  formListCareer16: "Thể thao",
  formListCareer17: "Ngành khác",
  formListCareer18: "Thời trang",
  formListCareer19: "Thuỷ sản",
  formListCareer20: "Khai thác dầu khí",
  FormPlaceholderPoint: "Thêm địa điểm",
};

export default locale;
