import axios from "axios";
import AuthService from "../../../../auth-keycloak/authservice";
import { notificationPopup } from "../../../../helpers/common";
import history from "@history";

axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
export const LOCATION_RESET = "LOCATION_RESET";
export const RESET_ON_BOARDING = "RESET_ON_BOARDING";
export const LOCATION_ERROR = "LOCATION_ERROR";

export const LOCATION_FETCHING = "LOCATION_FETCHING";
export const LOCATION_FETCHED = "LOCATION_FETCHED";
export const LOCATION_CREATE_UPDATE = "LOCATION_CREATE_UPDATE";
export const LOCATION_CREATE_UPDATE_OF_DASHBOARD =
  "LOCATION_CREATE_UPDATE_OF_DASHBOARD";
export const LOCATION_COUNT = "LOCATION_COUNT";
export const LOCATION_THUNDERSTORMS = "LOCATION_THUNDERSTORMS";
export const LOCATION_SET = "LOCATION_SET";
export const ON_BOARDING_CREATE = "ON_BOARDING_CREATE";
export const ON_BOARDING_CREATE_FETCHING = "ON_BOARDING_CREATE_FETCHING";
export const LOCATION_REMOVES = "LOCATION_REMOVES";
export const RESET_LOCATION_REMOVES = "RESET_LOCATION_REMOVES";

export const setLocation = (location) => (dispatch) => {
  dispatch({
    type: LOCATION_SET,
    payload: location,
  });
};
export const fetchOnBoarding = (data) => async (dispatch) => {
  dispatch({ type: ON_BOARDING_CREATE_FETCHING });

  try {
    let req = axios.post("point/create-on-boarding", data);

    const res = await req;

    dispatch({
      type: ON_BOARDING_CREATE,
      payload: {
        data: res.data.data,
        message: res.data.message,
        status: res.data.status,
      },
    });
  } catch (error) {
    if (error?.response?.data?.status === -1) {
      history.push({
        pathname: "/profile/upgrade",
        state: { isCreated: true, type: "địa điểm", check: true },
      });
    } else {
      dispatch({
        type: LOCATION_ERROR,
        payload: error,
      });

      const arrayError = [];
      if (error.response.data?.validMsgList?.pointName !== undefined) {
        arrayError.push(
          error.response.data?.validMsgList?.pointName?.join(",")
        );
      }
      const contentError = arrayError.join(",");
      notificationPopup("error", contentError);
      throw error;
    }
  }
};

export const createOrUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    let req = id
      ? axios.post(`point/update/${id}`, { ...data, id: id })
      : axios.post("point/create", data);

    const resCreate = await req;

    dispatch({
      type: LOCATION_CREATE_UPDATE,
      payload: {
        data: resCreate.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });

    const dataBody = {
      searchCommon: "",
      sortBy: "createdAt",
      rowsPerPage: 999999,
    };

    const res = await axios.post("point/list", dataBody);

    dispatch({
      type: LOCATION_FETCHED,
      payload: {
        data: res.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });
  } catch (error) {
    if (error?.response?.data?.status === -1) {
      history.push({
        // pathname: "/profile/upgrade",
        state: { isCreated: true, type: "địa điểm", check: true },
      });
    } else {
      dispatch({
        type: LOCATION_ERROR,
        payload: error,
      });

      const arrayError = [];
      if (error.response.data?.validMsgList?.pointName !== undefined) {
        arrayError.push(
          error.response.data?.validMsgList?.pointName?.join(",")
        );
      }
      const contentError = arrayError.join(",");
      notificationPopup("error", contentError);
      throw error;
    }
  }
};

export const update = (id, data) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    let req = axios.post(`point/update/${id}`, { ...data, id: id });

    const resCreate = await req;

    dispatch({
      type: LOCATION_CREATE_UPDATE_OF_DASHBOARD,
      payload: {
        data: resCreate.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const getLocationById = (id) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    const res = await axios.get(`point/detail/${id}`);
    if (res.data.status === 200) {
      dispatch({
        type: LOCATION_SET,
        payload: { data: res.data.data, isShowModal: true, isCopy: false },
      });
    } else {
      notificationPopup("error", res.data.message);
    }
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const remove = (id) => async (dispatch, getState) => {
  dispatch({ type: LOCATION_FETCHING });

  const { locations } = getState().locationData.location;
  locations.data.content = locations.data.content.filter((e) => e.id !== id);

  try {
    const res = await axios.get(`point/delete/${id}`);
    if (res.data.status === 200) {
      const dataBody = {
        searchCommon: "",
        sortBy: "createdAt",
        rowsPerPage: 999999,
      };

      const res = await axios.post("point/list", dataBody);

      dispatch({
        type: LOCATION_FETCHED,
        payload: res.data,
      });
    }
    dispatch({
      type: LOCATION_REMOVES,
      payload: { status: res.data.status, message: res.data.status },
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};
export const removeList = (param) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  // const { locations } = getState().locationData.location;
  // param.map((location) => (
  //     locations.data.content = locations.data.content.filter((e) => e.id !== location)
  // ))
  try {
    const res = await axios.post("point/delete-by-list", param);

    dispatch({
      type: LOCATION_REMOVES,
      payload: { status: res.data.status, message: res.data.status },
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const fetch = (params) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    const res = await axios.post("point/list", params);

    dispatch({
      type: LOCATION_FETCHED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const count = (params) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    const res = await axios.post("point/list", params);

    dispatch({
      type: LOCATION_COUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const thunderstorms = (id) => async (dispatch) => {
  dispatch({ type: LOCATION_FETCHING });

  try {
    const res = await axios.get(`point/thunderstorms/${id}`);

    dispatch({
      type: LOCATION_THUNDERSTORMS,
      payload: { ...res.data, pointId: id },
    });
  } catch (error) {
    dispatch({
      type: LOCATION_ERROR,
      payload: error,
    });

    throw error;
  }
};
export const reset = () => (dispatch) => dispatch({ type: LOCATION_RESET });
export const resetOnBoarding = () => (dispatch) =>
  dispatch({ type: RESET_ON_BOARDING });
export const resetRemoveLocations = () => (dispatch) =>
  dispatch({ type: RESET_LOCATION_REMOVES });
