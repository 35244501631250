import axios from "axios";
import { tagCategories } from "../../../../values/categories";
import { languages } from "app/values/languages";
import AuthService from "../../../../auth-keycloak/authservice";
import history from "@history";

axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;

export const DASHBOARD_RESET = "DASHBOARD_RESET";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const DASHBOARD_FETCHING = "DASHBOARD_FETCHING";
export const DASHBOARD_FETCHED = "DASHBOARD_FETCHED";
export const DASHBOARD_CREATE_BY_CAREER = "DASHBOARD_CREATE_BY_CAREER";
export const DASHBOARD_CATEGORIES_FETCHED = "DASHBOARD_CATEGORIES_FETCHED";
export const DASHBOARD_SET = "DASHBOARD_SET";
export const DASHBOARD_SET_DATA_SEARCH = "DASHBOARD_SET_DATA_SEARCH";
export const ON_BOARDING = "ON_BOARDING";
export const DASHBOARD_UPDATE_NEW = "DASHBOARD_UPDATE_NEW";
export const publish = (id) => async (dispatch) => {
  dispatch({ type: DASHBOARD_FETCHING });

  try {
    await axios.put(`/post/${id}/publish`);

    dispatch(fetch());
  } catch (error) {
    dispatch({
      type: DASHBOARD_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const isOnBoarding = (data) => (dispatch) => {
  dispatch({
    type: ON_BOARDING,
    payload: data,
  });
};

export const setDataSearch = (data) => (dispatch) =>
  dispatch({
    type: DASHBOARD_SET_DATA_SEARCH,
    payload: data,
  });

export const setPost = (post) => (dispatch) => {
  const arr = post.tags.filter((e) => e.type === tagCategories.tag);

  const tags = [];
  for (const i of arr) {
    const vi = i.data.find((e) => e.language === languages.vi);
    tags.push({
      id: vi.tagId,
      name: vi.name,
    });
  }

  post.tag = tags;

  dispatch({
    type: DASHBOARD_SET,
    payload: post,
  });
};
export const addAssignLocation = (data) => async (dispatch) => {
  dispatch({ type: DASHBOARD_FETCHING });

  try {
    let req = axios.post("dashboard/update/new", data);
    const resCreate = await req;
    {
      resCreate.status === 200 ? history.push("/dashboards") : "";
    }
    dispatch({
      type: DASHBOARD_UPDATE_NEW,
      payload: {
        data: resCreate.data,
        message: resCreate.message,
        status: resCreate.status,
      },
    });
    const dataBody = {
      searchCommon: "",
      sortBy: "",
      status: 1,
    };

    const res = await axios.post("dashboard/list", dataBody);

    dispatch({
      type: DASHBOARD_FETCHED,
      payload: {
        data: res.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });
  } catch (error) {
    if (error?.response?.data?.status === -1) {
      history.push({
        // pathname: "/profile/upgrade",
        state: { isCreated: true, type: "khuyến nghị", check: true },
      });
    } else {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: error,
      });

      window.alert(error);
      throw error;
    }
  }
};
export const createOrUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: DASHBOARD_FETCHING });

  try {
    let req = id
      ? axios.post(`dashboard/update/${id}`, data)
      : axios.post("dashboard/create", data);

    const resCreate = await req;

    const dataBody = {
      searchCommon: "",
      sortBy: "",
      status: 1,
    };

    const res = await axios.post("dashboard/list", dataBody);

    dispatch({
      type: DASHBOARD_FETCHED,
      payload: {
        data: res.data.data,
        message: resCreate.data.message,
        status: resCreate.data.status,
      },
    });
  } catch (error) {
    if (error?.response?.data?.status === -1) {
      history.push({
        pathname: "/profile/upgrade",
        state: { isCreated: true, type: "tài nguyên hệ thống", check: true },
      });
    } else {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: error,
      });

      window.alert(error);
      throw error;
    }
  }
};

export const remove = (id) => async (dispatch, getState) => {
  dispatch({ type: DASHBOARD_FETCHING });

  const { data } = getState().dashboardData.dashboard.dashboards;
  data.content = data.content.filter((e) => e.id !== id);

  try {
    const res = await axios.get(`dashboard/delete/${id}`);

    dispatch({
      type: DASHBOARD_FETCHED,
      payload: {
        data: data,
        message: res.data.message,
        status: res.data.status,
      },
    });
  } catch (error) {
    dispatch({
      type: DASHBOARD_ERROR,
      payload: error,
    });

    window.alert(error);
    throw error;
  }
};

export const fetch = (params) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_FETCHING,
  });

  try {
    const dataBody = {
      ...params,
    };
    const res = await axios.post("dashboard/list", dataBody);

    dispatch({
      type: DASHBOARD_FETCHED,
      payload: res.data,
    });
  } catch (error) {
    if (error?.response?.data?.status === -1) {
      history.push({
        state: { isCreated: true, type: "quyền lợi", check: true },
      });
    } else {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: error,
      });

      throw error;
    }
  }
};

export const createDashboardByCareer = (career) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_FETCHING,
  });

  try {
    const dataBody = { career: career };
    const res = await axios.post("dashboard/create-by-career", dataBody);

    dispatch({
      type: DASHBOARD_CREATE_BY_CAREER,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DASHBOARD_ERROR,
      payload: error,
    });

    throw error;
  }
};

export const reset = () => (dispatch) => dispatch({ type: DASHBOARD_RESET });
