const locale = {
  titleHeader: "Trợ giúp",
  downloadFile: "Tải về hướng dẫn",
  titleHeaderVideo1: "Giới thiệu nền tảng Metplus",
  titleHeaderVideo2: "Hướng dẫn sử dụng các tính năng trong Metplus",
  titleHeaderContent1: "Các bước cấu hình cho tài khoản mới",
  titleHeaderContent2: "Hướng dẫn sử dụng Metplus",
  titleHeaderContent3: "Các gói dữ liệu",
  titleHeaderContent4: "Các câu hỏi thường gặp",
  titleHeaderContent5: "Giới thiệu Weatherplus",
  titleHeaderContent6: "Điều khoản sử dụng",
};

export default locale;
