import { React, useRef } from "react";
import { Col, Row, Menu } from "antd";
import Vector from "assets/img/icon_new/Vector.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./menu.scss";

const MenuNotify = (props) => {
  const {
    dataNotify,
    handleOnClickUpdateAll,
    handleOnClickUpdateNotify,
    handleShowDropdown,
    useClickOutSide,
  } = props;

  const history = useHistory();

  let ref = useRef();
  const handleChangeClick = () => {
    handleShowDropdown();
  };
  useClickOutSide(ref, handleChangeClick);
  return (
    <div ref={ref}>
      <Menu className={"menu-notify bg-cl-header bs-menu-notify"}>
        <Menu.Item
          style={{
            height: "100%",
            // margin: "15px 0px 10px 0px",
          }}
          className={"bd-bottom-menu"}
        >
          <Row className="header-notify">
            <Col span={2}>
              <img
                src={Vector}
                alt="Thông báo"
                width={21}
                height={24}
                style={{ marginTop: 7 }}
              />
            </Col>
            <Col span={9}>
              <span className="title cl-main-title">Thông báo</span>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col span={9}>
                  <a
                    src=""
                    onClick={() => {
                      handleOnClickUpdateAll();
                    }}
                  >
                    <span className="seen-all">Đọc tất cả</span>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Menu.Item>
        <Menu style={{ maxHeight: "400px", overflow: "auto" }}>
          {dataNotify.map((dataNotify) => [
            <Menu.Item
              className={
                dataNotify.status === 1
                  ? "option-notify"
                  : "option-notify-disable"
              }
              key={dataNotify.id}
              onClick={() => {
                {
                  dataNotify.status === 1
                    ? handleOnClickUpdateNotify(dataNotify.id)
                    : null;
                }
              }}
            >
              <Col span={24}>
                <span className="name-notify">{dataNotify.nameNotify}</span>
              </Col>
              <Col span={24}>
                <span className="content-notify cl-text-description-insight">
                  {dataNotify.contentNotify}
                </span>
              </Col>
              <Col span={24}>
                <Row justify="start">
                  <Col span={2}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 16 16"
                      // mix-blend-mode="normal"
                      opacity="0.6"
                      className="clock"
                      style={
                        dataNotify.status === 1
                          ? { fill: "#9187F2" }
                          : { fill: "#777777" }
                      }
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>
                  </Col>
                  <Col span={16}>
                    <span className="time-notify">
                      Vào ngày{" "}
                      {moment(dataNotify.createdAt).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Menu.Item>,
          ])}
        </Menu>

        <Menu.Item
          // style={{ margin: "5px 0px 5px 0px" }}
          className={"bd-top-menu"}
        >
          <Row className="footer-notify">
            <Col span={24}>
              <a
                className="see-more"
                onClick={() =>
                  history.push("/log-notify", handleShowDropdown())
                }
              >
                Xem thêm
              </a>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MenuNotify;
