import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import routes from "app/configs/routes.config";

import "perfect-scrollbar/css/perfect-scrollbar.css";

import "./index.scss";
import "./admin.scss";
import "../main/location/components/index.scss";
import { ConfigProvider, Form, Layout, message, Modal, Steps } from "antd";
import { Route, Switch, useHistory } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import Header from "components/Header/header.js";
import { isMobileOnly, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../store/with_reducer";
import reducer from "../main/account/store/reducers";
import * as NotifyActions from "../main/log_notify/store/actions";
import { ReadOutlined, UserOutlined } from "@ant-design/icons";
import * as AccountActions from "../main/account/store/actions";
import { updateLoginDetail } from "../main/account/store/actions";
import * as DashboardActions from "../main/dashboard/store/actions";
import Steps1 from "./components/steps1";
import Steps3 from "./components/steps3";
import * as InsightActions from "../main/insights/store/actions";
import Steps5 from "./components/steps5";
import Steps6 from "./components/steps6";
import Steps1Mobile from "./components/steps1.mobile";
import Steps3Mobile from "./components/steps3.mobile";
import Steps5Mobile from "./components/steps5.mobile";
import Steps6Mobile from "./components/steps6.mobile";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import * as TourActions from "../main/help/store/actions";
import Steps6OneClick from "./components/steps6OneClick";
import Steps6OneClickMobile from "./components/steps6OneClick.mobile";
import backgroundOnboarding from "../../assets/icon/background-onboarding.jpg";
import backgroundOnboardingMobile from "../../assets/icon/bg-onboarding-mobile.jpg";
import moment from "moment";
import { Beforeunload } from "react-beforeunload";
import Constant from "../helpers/constants";
const { Step } = Steps;

const { Content } = Layout;

function Dashboard() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [typeDevice, setTypeDevice] = useState(null);
  const loading = (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </div>
  );

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const inTimeTemp = new Date();
    const inTimeString = moment(inTimeTemp).format("DD/MM/YYYY HH:mm:ss");
    localStorage.setItem("inTimeLogin", inTimeString);
  }, []);
  useEffect(() => {
    dispatch(AccountActions.getAccountById());
  }, [dispatch]);

  useEffect(() => {
    dispatch(NotifyActions.getAllNotify());
    dispatch(NotifyActions.getTotalNotify());
  }, [dispatch]);

  useEffect(() => {
    const getData = setInterval(() => {
      dispatch(NotifyActions.getAllNotify());
      dispatch(NotifyActions.getTotalNotify());
    }, 30000);
    return () => clearInterval(getData);
  }, [dispatch]);

  const handleOnClickUpdateAll = () => {
    dispatch(NotifyActions.updateAllNotify());
  };
  const handleOnClickUpdateNotify = (id) => {
    dispatch(NotifyActions.updateNotify(id));
  };

  const { statusUpdateNotify, dataUpdate } = useSelector(
    (state) => state.accountData.notify.updateNotify
  );

  const dataNotify = useSelector((state) => state.accountData.notify.notifys);
  const { countData } = useSelector((state) => state.accountData.notify);
  useEffect(() => {
    if (statusUpdateNotify === 200) {
      dispatch(NotifyActions.getAllNotify());
      dispatch(NotifyActions.getTotalNotify());
    }
  }, [dispatch, statusUpdateNotify, dataUpdate]);
  const { data } = useSelector((state) => state.accountData.account.account);
  const account = useSelector((state) => state.accountData.account.account);

  const accountLoginFirst = data?.packageDetail?.actionScreen?.loginFirst;

  const dashboardCreateByCareer = useSelector(
    (state) => state.accountData.dashboard.dashboardCreateByCareer
  );

  // useEffect(() => {
  //   if (data?.userDetail?.username != undefined) {
  //     // for (let i =10 ; i<60 ; i++) {
  //     //   const loginDetailReq = {loginDetail:`{'username':'0911221166','inTime':'17/02/2023 10:${i}:46','outTime':'17/02/2023 11:${i}:46','lastUseScreen':'${window.location.pathname}'}`}
  //     //   dispatch(updateLoginDetail(loginDetailReq))
  //     // }
  //
  //     window.addEventListener("beforeunload", () => {
  //       let outTimeTemp = new Date();
  //       const outTimeString = moment(outTimeTemp).format("DD/MM/YYYY HH:mm:ss");
  //       const loginDetailReq = {
  //         loginDetail: `{'username':'${
  //           data?.userDetail?.username
  //         }','inTime':'${localStorage.getItem(
  //           "inTimeLogin"
  //         )}','outTime':'${outTimeString}','lastUseScreen':'${
  //           window.location.pathname
  //         }'}`,
  //       };
  //       dispatch(updateLoginDetail(loginDetailReq));
  //       window.location("https://metplus.vn/nong-nghiep");
  //     });
  //     return () => {
  //       window.removeEventListener("beforeunload", () => {});
  //     };
  //   } else {
  //     return null;
  //   }
  // }, [dispatch, data?.userDetail?.username]);
  // console.log("data username :", data?.userDetail?.username != undefined);
  // useEffect(() => {
  //   if (statusUpdate === 200 && !accountLoginFirst) {
  //     message.success(t("messageUpdate"));
  //     setCurrent(1);
  //   }
  // }, [statusUpdate, t, accountLoginFirst]);

  useEffect(() => {
    let userAgent = window.navigator.userAgent;
    let typeOs = window.navigator.platform;
    if (isMobile === true) {
      Constant.IOS_PLATFORM.indexOf(typeOs) !== -1
        ? setTypeDevice("IOS")
        : /Android/.test(userAgent) === true
        ? setTypeDevice("Android")
        : Constant.WINDOW_PLATFORMS.indexOf(typeOs) !== -1
        ? setTypeDevice("Window Mobile")
        : setTypeDevice("Other devices");
    } else {
      Constant.WINDOW_PLATFORMS.indexOf(typeOs) !== -1
        ? setTypeDevice("Window PC")
        : Constant.MACOS_PLATFORMS.indexOf(typeOs) !== -1
        ? setTypeDevice("MacOS")
        : /Linux/.test(typeOs) === true
        ? setTypeDevice("Linux")
        : setTypeDevice("Other divices");
    }
  }, []);

  useEffect(() => {
    if (isMobile === true && data?.userDetail?.username != undefined) {
      // let typeOs = window.navigator.platform;
      // let typeDevice = null;
      // Constant.IOS_PLATFORM.indexOf(typeOs) !== -1? typeDevice = "IOS"
      //     : Constant.WINDOW_PLATFORMS.indexOf(typeOs) !== -1? typeDevice = "Android"
      //         : Constant.WINDOW_PLATFORMS.indexOf(typeOs) !== -1? typeDevice="Moible Window"
      //             : typeDevice="Other devices"
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          let outTimeTemp = new Date();
          const outTimeString = moment(outTimeTemp).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          const loginDetailReq = {
            loginDetail: `{'username':'${
              data?.userDetail?.username
            }','inTime':'${localStorage.getItem(
              "inTimeLogin"
            )}','outTime':'${outTimeString}','lastUseScreen':'${
              window.location.pathname
            }','typeDevice':'${typeDevice}'}`,
          };
          dispatch(updateLoginDetail(loginDetailReq));
        } else {
          console.log("User was online !!!");
        }
      });
      return () => {
        document.removeEventListener("visibilitychange", () => {});
      };
    } else {
      return null;
    }
  }, [dispatch, data?.userDetail?.username, typeDevice]);

  useEffect(() => {
    if (dashboardCreateByCareer.statusCode === 200 && !accountLoginFirst) {
      message.success(dashboardCreateByCareer.message);
      setIsModalVisible(false);
      history.push({
        pathname: "/dashboards",
        state: { check: true },
      });
      dispatch(
        DashboardActions.fetch({
          sortBy: "",
        })
      );
    }
  }, [dashboardCreateByCareer, history, accountLoginFirst, dispatch]);

  useEffect(() => {
    setIsModalVisible(!accountLoginFirst);
  }, [accountLoginFirst]);

  useEffect(() => {
    if (!accountLoginFirst) {
      dispatch(InsightActions.fetchCareer({ sortBy: "", rowsPerPage: 999999 }));
    }
  }, [accountLoginFirst, dispatch]);

  const [isModalVisible, setIsModalVisible] = useState(true);
  const [infoSteps, setInfoSteps] = useState({
    step2: {
      career: null,
      subSectors: null,
      isSubSector: false,
      companyName: "",
      numberMember: "",
    },
    step3: {},
    step5: {},
  });

  // const [currentOnBoarding, setCurrentOnBoarding] = React.useState(0);
  // const nextOnBoarding = () => {
  //   setCurrentOnBoarding(currentOnBoarding + 1);
  // };
  //
  // const prevOnBoarding = () => {
  //   setCurrentOnBoarding(currentOnBoarding - 1);
  // };

  const onSubmit = (param) => {
    // dispatch(InfoActions.updateInfo(id, param));
    setInfoSteps({
      ...infoSteps,
      step2: { ...infoSteps.step2, ...param },
    });
    next();
  };

  const onSubmitStep3 = (param) => {
    const step3 = {
      insights: param.insights.map((p) => {
        return {
          ...p,
          points: [],
          contacts: [],
        };
      }),
    };
    setInfoSteps({
      ...infoSteps,
      step3: step3,
      step5: step3,
    });
    next();
  };

  const [form] = Form.useForm();
  const [formStep3] = Form.useForm();
  const [formStep6] = Form.useForm();

  // const [point, setPoint] = useState({ name: "", index: 0 });

  // const changePlace = (place, index) => {
  //   console.log("place", place);
  //   console.log("index", index);
  //   if (
  //     place?.geometry?.location !== "" &&
  //     place?.geometry?.location !== undefined &&
  //     place?.geometry?.location !== null
  //   ) {
  //     setPoint({ name: place?.formatted_address, index });
  //     form.setFieldsValue({
  //       latitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lat,
  //       longtitude: JSON.parse(JSON.stringify(place?.geometry?.location)).lng,
  //       address: place?.formatted_address,
  //     });
  //   }
  // };

  const steps = [
    {
      title: "Giới thiệu",
      content: !isMobileOnly ? (
        <Steps1
          next={() => next()}
          data={data}
          form={form}
          onSubmit={(param) => onSubmit(param)}
        />
      ) : (
        <Steps1Mobile
          next={() => next()}
          data={data}
          form={form}
          onSubmit={(param) => onSubmit(param)}
        />
      ),
      icon: <UserOutlined />,
    },
    // {
    //   title: "Cập nhật thông tin",
    //   content: !isMobileOnly ? (
    //     <Steps2
    //       form={form}
    //       onSubmit={(id, param) => onSubmit(id, param)}
    //       userName={data?.userDetail?.username}
    //       infoSteps={infoSteps}
    //     />
    //   ) : (
    //     <Steps2Mobile
    //       form={form}
    //       onSubmit={(id, param) => onSubmit(id, param)}
    //       userName={data?.userDetail?.username}
    //     />
    //   ),
    //   icon: <UserOutlined />,
    // },
    {
      title: "Lựa chọn khuyến nghị",
      content: !isMobileOnly ? (
        <Steps3
          next={() => next()}
          prev={() => prev()}
          infoSteps={infoSteps}
          formStep3={formStep3}
          onSubmit={(param) => onSubmitStep3(param)}
        />
      ) : (
        <Steps3Mobile
          next={() => next()}
          prev={() => prev()}
          infoSteps={infoSteps}
          formStep3={formStep3}
          onSubmit={(param) => onSubmitStep3(param)}
        />
      ),
      icon: <ReadOutlined />,
    },
    // {
    //   title: "Cập nhật thông tin",
    //   content: !isMobileOnly ? (
    //     <Steps4
    //       next={() => next()}
    //       prev={() => prev()}
    //       infoSteps={infoSteps}
    //       setInfoSteps={setInfoSteps}
    //     />
    //   ) : (
    //     <Steps4Mobile
    //       next={() => next()}
    //       prev={() => prev()}
    //       infoSteps={infoSteps}
    //       setInfoSteps={setInfoSteps}
    //     />
    //   ),
    //   icon: <UserOutlined />,
    // },
    {
      title: "Cập nhật thông tin",
      content: !isMobileOnly ? (
        <Steps5
          next={() => next()}
          prev={() => prev()}
          infoSteps={infoSteps}
          setInfoSteps={setInfoSteps}
          account={data}
        />
      ) : (
        <Steps5Mobile
          next={() => next()}
          prev={() => prev()}
          infoSteps={infoSteps}
          setInfoSteps={setInfoSteps}
          account={data}
        />
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Hoàn thành",
      content: !isMobileOnly ? (
        data?.packageDetail?.isOneClick !== 1 ? (
          <Steps6 setIsModalVisible={setIsModalVisible} />
        ) : (
          <Steps6OneClick
            setIsModalVisible={setIsModalVisible}
            infoSteps={infoSteps}
            formStep6={formStep6}
            data={data}
          />
        )
      ) : data?.packageDetail?.isOneClick !== 1 ? (
        <Steps6Mobile setIsModalVisible={setIsModalVisible} />
      ) : (
        <Steps6OneClickMobile
          setIsModalVisible={setIsModalVisible}
          infoSteps={infoSteps}
          formStep6={formStep6}
          data={data}
        />
      ),
    },
  ];

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // const clickFinal = () => {
  //   dispatch(DashboardActions.createDashboardByCareer(career));
  // };

  // const tour = useSelector((state) => state.accountData.tour.tour);

  // const handleJoyrideCallback = (data: CallBackProps) => {
  //   const { openAddPoint } = tour;
  //   const { action, index, type, status } = data;
  //
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     dispatch(TourActions.reset());
  //   } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
  //     const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
  //
  //     if (action === ACTIONS.PREV && index === 9) {
  //       history.push("/weather");
  //       dispatch(
  //         TourActions.fetch({
  //           ...tour,
  //           run: false,
  //           stepIndex,
  //           tourCompleted: false,
  //         })
  //       );
  //     } else if (index === 9) {
  //       history.push("/location");
  //       dispatch(
  //         TourActions.fetch({
  //           ...tour,
  //           run: false,
  //           stepIndex,
  //           tourCompleted: false,
  //         })
  //       );
  //     } else if (index === 11 && openAddPoint) {
  //       dispatch(
  //         TourActions.fetch({
  //           ...tour,
  //           run: false,
  //           stepIndex,
  //           openAddPoint: false,
  //         })
  //       );
  //     } else if (stepIndex === 10 && action === ACTIONS.PREV) {
  //       dispatch(
  //         TourActions.fetch({
  //           ...tour,
  //           stepIndex,
  //           openAddPoint: false,
  //           isShowModalLocation: false,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         TourActions.fetch({
  //           ...tour,
  //           openAddPoint: false,
  //           stepIndex: stepIndex,
  //           isShowModalLocation: true,
  //           tourCompleted: true,
  //         })
  //       );
  //     }
  //   }
  //
  //   console.groupCollapsed(
  //     type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type
  //   );
  //   console.log(data);
  //   console.groupEnd();
  // };
  //
  // useEffect(() => {
  //   dispatch(
  //     TourActions.fetch({
  //       run: true,
  //       steps: [
  //         {
  //           content:
  //             "Cập nhật thông tin dự báo thời tiết hiện tại, 72h giờ tới và 14 ngày tới",
  //           placement: "left-start",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-step",
  //           title: "Màn hình thông tin dự báo thời tiết",
  //         },
  //         {
  //           content:
  //             "Là những báo cáo danh riêng cho những doanh nghiệp đặc thù",
  //           placement: "bottom",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".report-step",
  //           title: "Màn hình thông tin báo cáo",
  //         },
  //         {
  //           content:
  //             "Khi thay đổi địa điểm các thông tin thời tiết sẽ thay đổi theo lựa chọn của bạn",
  //           placement: "bottom",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-select-point-step",
  //           title: "Chọn địa điểm",
  //         },
  //         {
  //           content: "Bạn có thể thêm địa điểm mà mình muốn xem thời tiết",
  //           placement: "bottom",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-add-location-step",
  //           title: "Thêm địa điểm",
  //         },
  //         {
  //           content:
  //             "Đây là thông tin thời tiết hiện tại của địa điểm mà bạn chọn",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-info",
  //           title: "Thông tin thời tiết",
  //         },
  //         {
  //           content:
  //             "Đây là thông tin dự báo mưa trong 3 giờ tới. Nếu địa điểm trên bản đồ của bạn có những đám mây màu xanh chạy qua là đang mưa rồi đấy!",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-now-casting-step",
  //           title: "Thông tin thời tiết",
  //         },
  //         {
  //           content:
  //             "Đây là bảng thông tin thời tiết 72h. Với những yếu tố lượng mưa, nhiệt độ, độ ẩm, gió giật vượt mức cho phép sẽ có cảnh báo màu đỏ, cần chú ý là màu vàng và thuật lời là màu xanh",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-72h-table",
  //           title: "Thông tin thời tiết",
  //         },
  //         {
  //           content:
  //             "Đây là đồ thị thời tiết 72h tới, Hiển thị các yếu tố dưới dạng biểu đồ trực quan.",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".highcharts-container ",
  //           title: "Thông tin thời tiết",
  //         },
  //         {
  //           content:
  //             "Đây là thông tin thời tiết 14 ngày tới, Với mỗi khối là một ngày",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".weather-14days-title div",
  //           title: "Thông tin thời tiết",
  //         },
  //         {
  //           content: "Click vào 'Tiếp' để tới màn hình địa điểm",
  //           placement: "right",
  //           spotlightClicks: false,
  //           disableOverlayClose: true,
  //           target: ".sidePointName",
  //           title: "Thanh điều hướng",
  //         },
  //         {
  //           content: "Click vào đây để tạo địa điểm",
  //           placement: "top",
  //           spotlightClicks: true,
  //           target: ".step-location-button-add",
  //           title: "Thêm địa điểm",
  //           disableBeacon: true,
  //           disableOverlayClose: true,
  //           hideCloseButton: true,
  //           hideFooter: true,
  //         },
  //         {
  //           content: "Đây là hộp thoại thêm địa điểm.",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".contact-modal",
  //           title: "Thêm địa điểm",
  //         },
  //         {
  //           content:
  //             "Lựa chọn này sẽ lấy địa điểm hiện tại của bạn, có thể bạn cần cho phép trình duyệt truy cập vị trí hiện tại",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".step-location-gps",
  //           title: "Thêm địa điểm",
  //         },
  //         {
  //           content:
  //             "Lựa chọn này sẽ lấy địa chỉ bạn nhập vào làm căn cứ xác định tọa độ điểm",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".step-location-google",
  //           title: "Thêm địa điểm",
  //         },
  //         {
  //           content:
  //             "Lựa chọn này sẽ thêm chính xác tọa độ mà bạn muốn đưa vào hệ thống",
  //           placement: "top",
  //           spotlightClicks: true,
  //           disableOverlayClose: true,
  //           target: ".step-location-lat-long",
  //           title: "Thêm địa điểm",
  //         },
  //       ],
  //     })
  //   );
  // }, [dispatch]);

  // const [indexModal, setIndexModal] = useState();
  //
  // const onChangeBefore = (index) => {
  //   console.log("index", index);
  //   setIndexModal(index);
  // };

  const tour = useSelector((state) => state.accountData.tour.tourMenu);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status, size } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      if (tour.isStart !== true) {
        const params = {
          ...account?.data?.packageDetail?.actionScreen,
          tour: {
            ...account?.data?.packageDetail?.actionScreen.tour,
            tourMenu: true,
          },
        };
        dispatch(AccountActions.updateActionScreen(params));
      }
      dispatch(
        TourActions.fetchMenu({
          run: false,
          stepIndex: 0,
          steps: [],
          isStart: false,
        })
      );
    } else if (
      ([ACTIONS.PREV].includes(action) || [ACTIONS.NEXT].includes(action)) &&
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      dispatch(
        TourActions.fetchMenu({
          ...tour,
          stepIndex: stepIndex,
        })
      );
    } else if ([ACTIONS.CLOSE].includes(action)) {
      const stepIndex = size - 1;
      dispatch(
        TourActions.fetchMenu({
          ...tour,
          stepIndex: stepIndex,
        })
      );
    }
  };
  return (
    // <div id={"admin-id"} style={{ height: "100vh" }}>
    <ConfigProvider>
      <Beforeunload
        onBeforeunload={(event) => {
          event.preventDefault();
          let outTimeTemp = new Date();
          const outTimeString = moment(outTimeTemp).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          let lastUseScreen =
            data?.packageDetail?.actionScreen?.loginFirst != false
              ? window.location.pathname
              : "onboarding";
          const loginDetailReq = {
            loginDetail: `{'username':'${
              data?.userDetail?.username
            }','inTime':'${localStorage.getItem(
              "inTimeLogin"
            )}','outTime':'${outTimeString}','lastUseScreen':'${lastUseScreen}','typeDevice':'${typeDevice}'}`,
          };
          //,'typeDevice':'${typeDevice}'
          dispatch(updateLoginDetail(loginDetailReq));
        }}
      />
      <Header
        dataUser={data}
        dataNotify={dataNotify.data.content}
        countNotify={countData}
        handleOnClickUpdateAll={handleOnClickUpdateAll}
        handleOnClickUpdateNotify={handleOnClickUpdateNotify}
        handleCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
      />
      <Layout
        className={
          isMobileOnly
            ? "main-layout block bg-cl-main-layout"
            : "main-layout bg-cl-main-layout"
        }
        style={{ clear: "both" }}
      >
        <React.Suspense fallback={loading}>
          <Sidebar
            isCollapsed={isCollapsed}
            dataUser={data}
            handleCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          />
        </React.Suspense>

        <Layout
          className="site-layout bg-cl-main-layout"
          style={isMobileOnly ? { marginBottom: 0 } : { marginBottom: 30 }}
        >
          {/*<Breadcrumb>*/}
          {/*  {recursionBreadcrumb(breadcrumb.items)}*/}
          {/*</Breadcrumb>*/}
          {!accountLoginFirst && !isMobileOnly ? (
            <Modal
              visible={isModalVisible}
              centered
              closeIcon={true}
              className="popup-content-first"
              footer={null}
              zIndex={1001}
              style={{ width: "auto" }}
              maskStyle={{
                // background: "#ffffff",
                backgroundImage: `url(${backgroundOnboarding})`,
                // height: 880,
                padding: 0,
                // background-image: url("../../../assets/icon/background-register.png");
                backgroundSize: "100% 100%",
              }}
            >
              <>
                <Steps progressDot current={current} className="steps-content">
                  {steps.map((item) => (
                    <Step
                      key={item.title}
                      // title={item.title}
                      // icon={item.icon}
                    />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
              </>
            </Modal>
          ) : !accountLoginFirst && isMobileOnly ? (
            <Modal
              visible={isModalVisible}
              centered
              closeIcon={true}
              className="popup-content-first popup-mobile"
              footer={null}
              zIndex={1001}
              style={{ width: "auto" }}
              maskStyle={{
                // background:"linear-gradient(270deg, rgb(255 255 255) 89.77%, rgb(209 207 207 / 77%) 100%)",
                // background: "#adb4c9",
                backgroundImage: `url(${backgroundOnboardingMobile})`,
                backgroundSize: "100% 100%",
              }}
            >
              <>
                <Steps progressDot current={current} className="steps-content">
                  {steps.map((item) => (
                    <Step key={item.title} />
                  ))}
                </Steps>
                <div
                  className={
                    !isMobileOnly
                      ? "steps-content"
                      : "steps-content steps-content-mobile"
                  }
                >
                  {steps[current].content}
                </div>
              </>
            </Modal>
          ) : null}
          <Content className={isMobileOnly ? "" : "main-content"}>
            <React.Suspense fallback={loading}>
              <Switch>
                {routes.map((route, idx) =>
                  route.component ? (
                    <Route
                      key={String(idx)}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(renderProps) => (
                        <route.component {...renderProps} />
                      )}
                    />
                  ) : null
                )}
              </Switch>
            </React.Suspense>
          </Content>
        </Layout>
      </Layout>
      <Joyride
        continuous={true}
        run={tour.run}
        steps={tour.steps}
        stepIndex={tour.stepIndex}
        showProgress={true}
        showSkipButton={false}
        callback={(e) => handleJoyrideCallback(e)}
        locale={{
          back: "Quay lại",
          next: "Tiếp",
          skip: "Bỏ qua",
          last: "Hoàn thành",
        }}
        scrollOffset={800}
        styles={{
          options: {
            zIndex: 2000,
          },
          buttonNext: {
            background: "#4C449B",
            borderRadius: "5px",
          },
          buttonBack: {
            color: "#000000",
            border: "1px solid #4C449B",
            borderRadius: "5px",
          },
        }}
      />
    </ConfigProvider>
    // </div>
  );
}

Dashboard.propTypes = {
  children: PropTypes.element,
};

export default withReducer("accountData", reducer)(Dashboard);
