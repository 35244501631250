import { React, useRef } from "react";
import { Col, Row, Menu } from "antd";
import Vector from "assets/img/icon_new/Vector.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./menu.scss";

const MenuNotifyMobile = (props) => {
  const {
    dataNotify,
    handleOnClickUpdateAll,
    handleOnClickUpdateNotify,
    handleCancelShowDropdown,
    useClickOutSide,
  } = props;
  const history = useHistory();
  var widthBrowser = window.innerWidth;

  let ref = useRef();
  const handleChangeClick = () => {
    handleCancelShowDropdown();
  };
  useClickOutSide(ref, handleChangeClick);
  return (
    <div ref={ref}>
      <Menu
        className="menu-notify-mobile bg-cl-list-condition-add-insight"
        style={{ width: widthBrowser - 10 }}
        ref={ref}
      >
        <Menu.Item style={{ height: "100%" }} className={"bd-bottom-menu"}>
          <Row className="header-notify-mobile">
            <Col span={2}>
              <img
                src={Vector}
                alt="Thông báo"
                width={21}
                height={24}
                style={{ marginTop: 7 }}
              />
            </Col>
            <Col span={9}>
              <span className="title-mobile cl-main-title">Thông báo</span>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col span={10}>
                  <a
                    src=""
                    onClick={() => {
                      handleOnClickUpdateAll();
                    }}
                  >
                    <span className="seen-all-mobile">Đọc tất cả</span>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Menu.Item>
        <Menu style={{ maxHeight: "400px", overflow: "auto" }}>
          {dataNotify.map((dataNotify) => [
            <Menu.Item
              className={
                dataNotify.status === 1
                  ? "option-notify-mobile"
                  : "option-notify-disable-mobile"
              }
              key={dataNotify.id}
              onClick={() => {
                {
                  dataNotify.status === 1
                    ? handleOnClickUpdateNotify(dataNotify.id)
                    : null;
                }
              }}
            >
              <Col span={24}>
                <span className="name-notify-mobile">
                  {dataNotify.nameNotify}
                </span>
              </Col>
              <Col span={24}>
                <span className="content-notify-mobile cl-text-description-insight">
                  {dataNotify.contentNotify}
                </span>
              </Col>
              <Col span={24}>
                <Row justify="start">
                  <Col span={1}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 16 16"
                      className="clock"
                      style={
                        dataNotify.status === 1
                          ? { fill: "#544aac" }
                          : { fill: "#777777" }
                      }
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>
                  </Col>
                  <Col span={22}>
                    <span
                      className="time-notify-mobile"
                      style={{ marginLeft: "8px" }}
                    >
                      Vào ngày{" "}
                      {moment(dataNotify.createdAt).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Menu.Item>,
          ])}
        </Menu>

        <Menu.Item className={"bd-top-menu"}>
          <Row className="footer-notify-mobile">
            <Col span={24}>
              <a
                className="see-more-mobile"
                onClick={() => (
                  // e.preventDefault(),
                  history.push("/log-notify"), handleCancelShowDropdown()
                )}
              >
                Xem thêm
              </a>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MenuNotifyMobile;
