import * as Actions from "../actions";

const initialState = () => ({
  tourDashboard: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourWeather: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourInsight: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourInsightCareer: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourInsightAdd: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourInsightAddAdvanced: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
  tourMenu: {
    fetching: true,
    run: false,
    stepIndex: 0,
    steps: [],
    isStart: false,
  },
});

const stepReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.STEP_FETCHED_DASHBOARD:
      return {
        ...state,
        tourDashboard: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_WEATHER:
      return {
        ...state,
        tourWeather: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_INSIGHT:
      return {
        ...state,
        tourInsight: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_INSIGHT_CAREER:
      return {
        ...state,
        tourInsightCareer: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_INSIGHT_ADD:
      return {
        ...state,
        tourInsightAdd: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_INSIGHT_ADD_ADVANCED:
      return {
        ...state,
        tourInsightAddAdvanced: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHED_MENU:
      return {
        ...state,
        tourMenu: {
          fetching: false,
          run: payload.run,
          stepIndex: payload.stepIndex,
          steps: payload.steps,
          isStart: payload.isStart,
        },
      };
    case Actions.STEP_FETCHING:
      return {
        ...state,
        tour: {
          ...state.tour,
          fetching: true,
        },
      };
    case Actions.STEP_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.STEP_RESET:
      return initialState();
    default:
      return state;
  }
};

export default stepReducer;
