const locale = {
  "List Locations": "Địa điểm",
  ButtonAddLocation: "Thêm địa điểm",
  SearchToolTipTitle: "Tìm kiếm danh sách các địa điểm theo tên",
  SearchLabelCommon: "Tìm kiếm",
  SearchPlaceholderCommon: "Tìm kiếm theo tên",
  SearchLabelStatus: "Trạng thái",
  SearchPlaceholderStatus: "Chọn trạng thái",
  SearchButton: "Tìm kiếm",
  TableTitleColumnName: "Tên địa điểm",
  TableTitleColumnPointCode: "Mã",
  TableTitleColumnLongtitude: "Kinh độ",
  TableTitleColumnLatitude: "Vĩ độ",
  TableTitleColumnLatitudeLongtitude: "Lat/Long",
  TableTitleColumnAddress: "Địa chỉ",
  TableTitleColumnCreatedAt: "Thời gian tạo",
  TableTitleColumnStatus: "Trạng thái",
  TableTitleColumnAction: "Tác vụ",
  TableTitleColumnActionToolTipEdit: "Chỉnh sửa",
  TableTitleColumnActionToolTipDelete: "Xóa",
  TableTitleColumnActionToolTipDeleteConfirm: "Bạn chắc chắn muốn xoá?",
  TableTitleColumnActionToolTipDeleteCancel: "Không",
  TableTitleColumnActionToolTipDeleteYes: "Xóa",
  CreateTitleHeader: "Thêm địa điểm",
  EditTitleHeader: "Chỉnh sửa địa điểm",
  FormLabelPointName: "Tên địa điểm",
  FormMessagePointName: "Vui lòng nhập tên điểm",
  FormPlaceholderPointName: "Nhà máy sữa Mộc Châu",
  FormLabelPointCode: "Mã điểm",
  FormPlaceholderPointCode: "Nhập mã điểm",
  FormLabelLongitude: "Kinh độ",
  FormMessageLongitude: "Vui lòng nhập kinh độ",
  FormMessageGPS: "Vui lòng chọn định vị",
  FormPlaceholderLongitude: "Nhập kinh độ",
  FormMessageLimitLongitude:
    "Vui lòng nhập kinh độ nằm trong phạm vi lãnh thổ Việt Nam (101.5 -> 110)",
  FormLabelLatitude: "Vĩ độ",
  FormMessageLatitude: "Vui lòng nhập vĩ độ",
  FormPlaceholderLatitude: "Nhập vĩ độ",
  FormMessageLimitLatitude:
    "Vui lòng nhập vĩ độ nằm trong phạm vi lãnh thổ Việt Nam (8 -> 24)",
  FormLabelAddress: "Địa chỉ",
  FormPlaceholderAddress: "Thảo Nguyên, Phiêng Luông, Mộc Châu, Sơn La",
  FormLabelStatus: "Trạng thái",
  FormCheckBoxStatus: "Kích hoạt",
  FormCheckBoxStatusOff: "Ngừng kích hoạt",
  ButtonCancel: "Hủy",
  ButtonCreate: "Thêm mới",
  ButtonEdit: "Lưu",
  NoData: "Không có dữ liệu",
  ChooseMethodCreateLocation: "Tạo địa điểm mới theo:",
  GPS: "Vị trí hiện tại",
  GPSPositioning: "Định vị GPS",
  LatLong: "Lat/Long",
  FormMessageLocation: "Vui lòng nhập địa điểm",
  LoadingGps: "Đang lấy định vị",

  MessageGPS:
    "Lựa chọn này sẽ lấy địa điểm hiện tại của bạn, có thể bạn cần cho phép trình duyệt truy cập vị trí hiện tại",
  MessageLocation:
    "Lựa chọn này sẽ lấy địa chỉ bạn nhập vào làm căn cứ xác định tọa độ điểm",
  MessageLatLong:
    "Lựa chọn này sẽ thêm chính xác tọa độ mà bạn muốn đưa vào hệ thống",
  MessagePlaceName:
    "Đặt tên điểm * ( đặt để gợi nhớ, có thể thay đổi sau này )",
  MessageLatLongPlace: "(Mô tả thêm để bạn dễ nhận diện sau này)",
};

export default locale;
