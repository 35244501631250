import React, { useCallback, useEffect, useState } from "react";
import withReducer from "app/store/with_reducer";
import reducer from "./store/reducers";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
} from "antd";
import apiConfig from "../../configs/api.config";
import googleIcon from "../../../assets/icon/Google - Original.svg";
import facebookIcon from "../../../assets/icon/Facebook - Original.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthService from "../../auth-keycloak/authservice";
import * as RegisterActions from "./store/actions";
import { isMobile } from "react-device-detect";
import "../landing_page/index.scss";

const tailLayout = !isMobile
  ? {
      labelCol: {
        span: 24,
        offset: 0,
      },
      wrapperCol: {
        span: 24,
        offset: 0,
      },
    }
  : {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

const ModalRegister = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isShowModalRegister, handleShowModalRegister } = props;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [campaign, setCampaign] = useState("");
  const [channel, setChannel] = useState("");
  const [socialStatus, setSocialStatus] = useState("1");
  const [captcha, setCaptcha] = useState("");
  const [checkTerms, setCheckTerms] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log("Execute recaptcha not yet available");
      return;
    }

    const reCaptchaToken = await executeRecaptcha("register");
    // console.log("recapchaToken: " + reCaptchaToken);
    setCaptcha(reCaptchaToken);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const { register } = useSelector((state) => state.modalRegisterData.register);

  const handleUrlLandingPage = useCallback(() => {
    const url = window.location.href;
    const urlConvert = new URL(url);
    setCampaign(urlConvert.searchParams.get("campaign"));
    setChannel(urlConvert.searchParams.get("channel"));
    setSocialStatus(urlConvert.searchParams.get("social"));
  }, []);

  const verifyNumberPhone = (value) => {
    const phoneRex = /^(0?)(3[0-9]|5[0-9]|7[0-9]|8[0-9]|9[0-9])[0-9]{7}$/;

    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value) => {
    // eslint-disable-next-line max-len, prefer-named-capture-group
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    if (emailRex.test(value)) {
      setEmail(value);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (register?.status !== null) {
      if (register?.status?.status === 201) {
        openNotificationWithIcon("success");
        AuthService.setTokens({
          accessToken: register?.status?.accessToken,
          refreshToken: register?.status?.refreshToken,
        });
        // window.location = apiConfig.domainHome + "/dashboards";
        history.push("/dashboards");
      } else {
        openNotificationWithIcon("error");
      }
    }
  }, [dispatch, register, history]);

  const openNotificationWithIcon = (type) => {
    switch (type) {
      case "success":
        return notification[type]({
          message: "Đăng ký tài khoản thành công !!!",
          description:
            "Bạn đã đăng ký tài khoản thành công, hãy quay lại trang chủ và đăng nhập để sử dụng Metplus.",
        });
      case "error":
        return notification[type]({
          message: "Đăng ký tài khoản không thành công !!!",
          description:
            "Vui lòng kiểm tra lại tất cả các thông tin của tài khoản mà bạn vừa nhập .",
        });
      default:
        return type;
    }
  };

  const onChange = (e) => {
    setCheckTerms(e.target.checked);
  };
  const onSubmit = (e) => {
    handleShowModalRegister();
    handleReCaptchaVerify();
    // const campaign = location?.state?.campaign;

    dispatch(
      RegisterActions.registerAccount({
        ...e,
        captcha,
        campaign: campaign,
        channel: channel,
        email: email,
      })
    );
  };

  useEffect(() => {
    handleUrlLandingPage();
  }, [handleUrlLandingPage]);

  return (
    <Modal
      visible={isShowModalRegister}
      onCancel={handleShowModalRegister}
      footer={null}
      className={"form-register-from-landing-page"}
    >
      <Row>
        {socialStatus !== "0" && (
          <>
            <Col span={24} className="title-register-mode-light">
              Đăng nhập nhanh bằng tài khoản
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={11}>
                      <Button className="mst-social-button-mode-light">
                        <a href={apiConfig.loginGoogleURL}>
                          <img src={googleIcon} alt="google" />
                          <span className="text-button-mode-light">Google</span>
                        </a>
                      </Button>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Button className="mst-social-button-mode-light">
                        <a href={apiConfig.loginFacebookURL}>
                          <img src={facebookIcon} alt={"facebook"} />
                          <span className="text-button-mode-light">
                            Facebook
                          </span>
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} className={"form-register-mode-light"}>
              Hoặc đăng ký tài khoản mới
            </Col>
          </>
        )}
        {socialStatus === "0" && (
          <Col span={24} className="title-register-mode-light">
            Đăng ký tài khoản
          </Col>
        )}

        <Col span={24}>
          <Form onFinish={onSubmit}>
            {/*<Col span={24}>*/}
            <Form.Item
              name={"username"}
              {...tailLayout}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại của bạn!",
                },
                () => ({
                  validator(_, value) {
                    if (verifyEmail(value) || verifyNumberPhone(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Số điện thoại của bạn không phù hợp!")
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-register-mode-light"
                style={{ height: "46px", background: "transparent" }}
                placeholder="Số điện thoại *"
                value={username}
                onChange={(e) => setUsername(e)}
              ></Input>
            </Form.Item>
            {/*</Col>*/}

            <Form.Item
              name={"passwordNew"}
              {...tailLayout}
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mật khẩu của bạn !",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Mật khẩu"
                className="input-register-mode-light"
                style={{ height: "46px", background: "transparent" }}
              ></Input.Password>
            </Form.Item>

            <Form.Item
              name={"passwordOld"}
              dependencies={["passwordNew"]}
              hasFeedback
              {...tailLayout}
              rules={[
                {
                  required: true,
                  message: "Vui lòng xác nhận lại mật khẩu của bạn!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passwordNew") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Hai mật khẩu bạn vừa nhập không khớp!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Xác nhận mật khẩu"
                className="input-register-mode-light"
                style={{ height: "46px", background: "transparent" }}
              ></Input.Password>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Checkbox
                className="checkbox-custom checkbox-insight-right-light-mode checkbox-right-contact"
                onChange={(e) => onChange(e)}
              >
                <span style={{ color: "#777777" }}>
                  Tôi đồng ý với{" "}
                  <a
                    href="https://metplus.vn/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Điều khoản sử dụng
                  </a>{" "}
                  của Metplus
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: checkTerms ? "#6158B8" : "#8882C4" }}
                disabled={!checkTerms}
                className={"mst-button-create-account"}
              >
                <span className="text-button-create-mode-light">
                  Tạo tài khoản
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Col>

        {/*<Row>*/}
        <Col
          span={24}
          // offset={2}
          className="question-login-mode-light"
          // style={{ marginTop: "120px" }}
        >
          <span>
            Bạn đã có tài khoản Metplus?{" "}
            <a href={apiConfig.domainHome} className="login-mode-light">
              Đăng nhập
            </a>
          </span>
        </Col>
        {/*</Row>*/}
      </Row>
    </Modal>
  );
};

export default withReducer("modalRegisterData", reducer)(ModalRegister);
