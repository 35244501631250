import React from "react";

import { authRoles } from "app/auth";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import { isMobileOnly } from "react-device-detect";

i18next.addResourceBundle("en", "report", en);
i18next.addResourceBundle("vi", "report", vi);

const ReportConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/report",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./Report.mobile") : import("./Report")
      ),
    },
  ],
};

export default ReportConfig;
