import * as Actions from "../actions";

const initialState = () => ({
  locations: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
  countData: null,
  editLocation: {
    fetching: false,
    data: null,
    isShowModal: false,
  },
  removeLocations: {
    fetching: true,
    statusCode: null,
    message: null,
  },
  locationCreateUpdate: {
    fetching: true,
    data: [],
    statusCode: null,
    message: null,
  },
  locationCreateUpdateOfDashboard: {
    fetching: true,
    data: [],
    statusCode: null,
    message: null,
  },
  thunderstormsLocation: {
    fetching: true,
    data: null,
  },
  onBoarding: {
    fetching: true,
    data: null,
    statusCode: null,
    message: null,
  },
});

const locationReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.LOCATION_SET:
      return {
        ...state,
        editLocation: {
          fetching: false,
          data: payload.data,
          isShowModal: payload.isShowModal,
        },
      };
    case Actions.ON_BOARDING_CREATE:
      return {
        ...state,
        onBoarding: {
          fetching: false,
          data: payload.data,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.ON_BOARDING_CREATE_FETCHING:
      return {
        ...state,
        onBoarding: {
          fetching: true,
          ...state.onBoarding,
        },
      };
    case Actions.LOCATION_CREATE_UPDATE:
      return {
        ...state,
        locationCreateUpdate: {
          fetching: false,
          data: payload.data,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.LOCATION_REMOVES:
      return {
        ...state,
        removeLocations: {
          fetching: false,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.RESET_LOCATION_REMOVES:
      return {
        ...state,
        removeLocations: {
          fetching: true,
          statusCode: null,
          message: null,
        },
      };
    case Actions.LOCATION_CREATE_UPDATE_OF_DASHBOARD:
      return {
        ...state,
        locationCreateUpdateOfDashboard: {
          fetching: false,
          data: payload.data,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.LOCATION_FETCHED:
      return {
        ...state,
        locations: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.LOCATION_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case Actions.LOCATION_COUNT:
      return {
        ...state,
        fetching: true,
        countData: payload.data.content?.length,
      };
    case Actions.LOCATION_THUNDERSTORMS:
      return {
        ...state,
        thunderstormsLocation: {
          fetching: false,
          data: { ...payload.data, pointId: payload.pointId },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.LOCATION_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.LOCATION_RESET:
      return initialState();
    case Actions.RESET_ON_BOARDING:
      return {
        ...state,
        onBoarding: {
          fetching: true,
          data: null,
          statusCode: null,
          message: null,
        },
      };
    default:
      return state;
  }
};

export default locationReducer;
