import * as Actions from "../actions";

const initialState = () => ({
  notifys: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
  countData: null,
  updateNotify: {
    dataUpdate: {
      content: null,
    },
    statusUpdateNotify: null,
    messageUpdate: null,
  },
});

const notifyReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.NOTIFY_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case Actions.NOTIFY_FETCHED:
      return {
        ...state,
        notifys: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.NOTIFY_COUNT:
      return {
        ...state,
        fetching: true,
        countData: payload.data,
      };
    case Actions.NOTIFY_UPDATE:
      return {
        ...state,
        updateNotify: {
          dataUpdate: { ...payload.data },
          statusUpdateNotify: payload.status,
          message: payload.message,
        },
      };
    case Actions.NOTIFY_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.NOTIFY_RESET:
      return initialState();
    default:
      return state;
  }
};

export default notifyReducer;
