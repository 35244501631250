import * as Actions from "../actions";

const initialState = () => ({
  account: {
    fetching: true,
    data: {
      username: null,
      packageDetail: {
        actionScreen: {
          loginFirst: true,
          tour: {
            tourWeather: true,
            tourDashboard: true,
            tourInsight: true,
            tourInsightCareer: true,
            tourInsightAdd: true,
            tourInsightAddAdvanced: true,
            tourMenu: true,
          },
        },
      },
    },
    statusCode: null,
    message: null,
  },
  accountUpdate: {
    message: null,
    statusUpdate: null,
  },
  accountLoginFirst: true,
  accountCheckPackageDown: {
    fetching: true,
    data: {
      content: null,
    },
    statusCode: null,
    message: null,
  },
  accountPackageById: {
    fetching: true,
    message: null,
    statusCode: null,
    data: {
      content: null,
    },
  },
  checkPackageDownCommunity: {
    fetching: true,
    statusCode: null,
    message: null,
  },
  key: {
    fetching: true,
    statusCode: null,
    message: null,
    data: null,
  },
});

const accountReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case Actions.ACCOUNT_ID:
      return {
        ...state,
        account: {
          fetching: false,
          data: { ...payload.data },
          statusCode: payload.status,
          message: payload.message,
          statusUpdate: payload.statusUpdate,
        },
      };
    case Actions.ACCOUNT_CHECK_LOGIN_FIRST:
      return {
        ...state,
        accountLoginFirst: payload.data,
      };
    case Actions.ACCOUNT_UPDATE:
      return {
        ...state,
        accountUpdate: {
          message: payload.message,
          statusUpdate: payload.statusUpdate,
        },
      };
    case Actions.ACCOUNT_CHECK_PACKAGE_DOWN:
      return {
        ...state,
        accountCheckPackageDown: {
          data: { content: payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.ACCOUNT_GET_PACKAGE_BY_ID:
      return {
        ...state,
        accountPackageById: {
          fetching: false,
          data: { content: payload.data },
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.ACCOUNT_PACKAGE_DOWN_COMMUNITY:
      return {
        ...state,
        checkPackageDownCommunity: {
          fetching: false,
          statusCode: payload.status,
          message: payload.message,
        },
      };
    case Actions.ACCOUNT_DECRYPT:
      return {
        ...state,
        key: {
          fetching: false,
          statusCode: payload.status,
          message: payload.message,
          data: payload.data,
        },
      };
    case Actions.ACCOUNT_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case Actions.ACCOUNT_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case Actions.ACCOUNT_RESET:
      return {
        ...state,
        accountUpdate: {
          message: null,
          statusUpdate: null,
        },
      };
    case Actions.RESET_ACCOUNT_CHECK_PACKAGE_DOWN:
      return {
        ...state,
        accountCheckPackageDown: {
          fetching: true,
          data: {
            content: null,
          },
          statusCode: null,
          message: null,
        },
      };
    default:
      return state;
  }
};

export default accountReducer;
