import { Col, Menu, Row } from "antd";
import React from "react";
import "./menu.scss";
import Weather from "../../../assets/img/icon_new/sun-fog.png";
import Category from "../../../assets/img/icon_new/Category.png";
import Insight from "../../../assets/img/icon_new/chart.png";
import InsightCareer from "../../../assets/img/icon_new/subChart.png";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import advanceButton from "../../../assets/icon/advance-button.svg";
import { useHistory } from "react-router-dom";
import * as TourActions from "../../../app/main/help/store/actions";
import { useDispatch } from "react-redux";
import * as InsightActions from "../../../app/main/insights/store/actions/insight.action";
import { VideoCameraOutlined } from "@ant-design/icons";

export const ListTour = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const clickTourWeather = () => {
    history.push("/weather");
    dispatch(
      TourActions.fetchWeather({
        run: true,
        stepIndex: 0,
        isStart: true,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Có thể nhập điểm bất kỳ hoặc lựa chọn trong danh sách.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".weather-select-point-step",
            disableBeacon: true,
            title: "Hướng dẫn sử dụng trang thông tin thời tiết",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thông số thời tiết theo giờ đã được phân theo các ngưỡng: thuận
                lợi, chú ý, cảnh báo.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".weather-72h-note",
            // title: "Chú ý, xin đừng bỏ qua hướng dẫn này",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả các hướng dẫn tại đây
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
            // title: "Chú ý, xin đừng bỏ qua hướng dẫn này",
          },
        ],
      })
    );
  };
  const clickTourDashboard = () => {
    history.push("/dashboards");
    dispatch(
      TourActions.fetch({
        run: true,
        isStart: true,
        stepIndex: 0,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Các ô màu khuyến nghị hành động sẽ hiển thị ở khung thời gian
                thỏa mãn điều kiện, cho bạn thông tin về việc cần phải làm để
                ứng phó.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableBeacon: true,
            disableOverlayClose: true,
            target: ".insight-location-container",
            title: "Hướng dẫn sử dụng bảng khuyến nghị hành động",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Các địa điểm của bạn đã gán với khuyến nghị.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".header-title-point",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Có thể xem theo tuần/ngày và lọc theo địa điểm/khuyến nghị.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".bar-filter-pc",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Quy định màu khuyến nghị, có thể chọn hoặc thay đổi theo ý muốn.
              </span>
            ),
            placement: "bottom",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".bar-filter-pc-level",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Số lượng khuyến nghị đã dùng trên tổng khuyến nghị của gói.
              </span>
            ),
            placement: "bottom",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".container-number-benefit-used",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Tạo thêm khuyến nghị (danh sách khuyến nghị đã chọn chỉ là gợi
                ý).
              </span>
            ),
            placement: "bottom",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".btn-add-padding",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả hướng dẫn tại đây.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };
  const clickTourInsight = () => {
    history.push({ pathname: "/insights", state: { isTourInsight: true } });
    dispatch(
      TourActions.fetchInsight({
        run: true,
        stepIndex: 0,
        isStart: true,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Danh sách các khuyến nghị của bạn đang sử dụng.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            target: ".ant-table-tbody .ant-table-cell-ellipsis",
            title: "Hướng dẫn sử dụng Thư viện khuyến nghị",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Có thể gán các khuyến nghị với các địa điểm bất kỳ hoặc trong
                danh sách.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-create-point-input",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Tạo khuyến nghị mới theo ý của bạn
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".btn-add-padding",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Hoặc sử dụng các khuyến nghị có sẵn theo ngành mà weatherplus
                gợi ý
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".button-insight-career",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Số lượng khuyến nghị của bạn đang sử dụng trên số khuyến nghị
                tối đa của gói hiện tại
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".container-number-benefit-used",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả các hướng dẫn tại đây
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };
  const clickTourInsightCareer = () => {
    history.push({ pathname: "/insights", state: { check: true } });
    dispatch(
      InsightActions.setPackageInsight({
        data: null,
        isShowPackageDetail: false,
      })
    );
    dispatch(
      TourActions.fetchInsightCareer({
        run: true,
        stepIndex: 0,
        isStart: true,
        steps: [
          {
            content: <span style={{ fontSize: 20 }}>Click vào đây</span>,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            placement: "bottom",
            spotlightClicks: true,
            target: ".ant-card-hoverable",
            title: "Hướng dẫn sử dụng Khuyến nghị theo ngành",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Hãy chọn những khuyến nghị phù hợp nhất.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-page-content-item",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Sau đó ấn nút sử dụng để gán khuyến nghị với các địa điểm bạn
                muốn, sau đó khuyến nghị sẽ hiện lên bảng khuyến nghị hành động
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            // target: ".insight-btn-selected-item-" + packageInsight.id,
            target: ".insight-btn-selected-item",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả các hướng dẫn tại đây
              </span>
            ),
            placement: "right-start",
            spotlightClicks: true,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };
  const clickTourInsightAdd = () => {
    history.push("/insights");
    dispatch(
      InsightActions.setInsight({
        data: null,
        isShowModal: true,
        isAdvanced: false,
      })
    );
    dispatch(
      TourActions.fetchInsightAdd({
        run: true,
        stepIndex: 0,
        isStart: true,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thiết lập cơ bản dành cho người mới bắt đầu, đơn giản, dễ sử
                dụng.
              </span>
            ),
            placement: "right-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            target:
              ".insight-modal .ant-modal-content .ant-modal-body .insight-basic",
            title: "Hướng dẫn thêm khuyến nghị theo thiết lập cơ bản",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nhập tên khuyến nghị hành động.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-name",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Lựa chọn mức độ quan trọng của khuyến nghị tùy ý (chỉ ảnh hưởng
                tới màu hiển thị trên bảng khuyến nghị hành động).
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-level",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm các mô tả cho khuyến nghị.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-create-text-area",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn yếu tố thời tiết bạn quan tâm.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-element",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn hình thái thời tiết cần cảnh báo.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-condition",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm điều kiện mới đồng thời xảy ra (VÀ), lặp lại bước 6,7.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-button",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm điều kiện mới hoặc sẽ xảy ra (HOẶC), lặp lại bước 6,7.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-create-button-condition",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn địa điểm mà khuyến nghị sẽ hoạt động.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-points",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn người dùng sẽ nhận được cảnh báo.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".checkbox-right-contact",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn kênh cảnh báo, email là ngầm định, zalo dành cho gói tính
                phí.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-channel",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn thời gian cảnh báo trước, hệ thống sẽ gửi trước thời gian
                này khi sự kiện xảy ra.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-before-time",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nhập lời nhắn riêng cho người nhận khuyến nghị nếu muốn.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-content-alert",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>Thêm người dùng mới nếu cần.</span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-button",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả các hướng dẫn tại đây
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };
  const clickTourInsightAddAdvanced = () => {
    history.push("/insights");
    dispatch(
      InsightActions.setInsight({
        data: null,
        isShowModal: true,
        isAdvanced: true,
      })
    );
    dispatch(
      TourActions.fetchInsightAddAdvanced({
        run: true,
        stepIndex: 0,
        isStart: true,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thiết lập nâng cao, nhiều yếu tố thời tiết, ngưỡng đặt linh
                hoạt.
              </span>
            ),
            placement: "right-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            target:
              ".insight-modal .ant-modal-content .ant-modal-body .insight-advanced",
            title: "Hướng dẫn thêm khuyến nghị theo thiết lập nâng cao",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nhập tên khuyến nghị hành động.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-name",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Lựa chọn mức độ quan trọng của khuyến nghị tùy ý (chỉ ảnh hưởng
                tới màu hiển thị trên bảng khuyến nghị hành động).
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-level",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm các mô tả cho khuyến nghị.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-create-text-area",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn yếu tố thời tiết bạn quan tâm, chú ý đơn vị tính.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-element",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn phép so sánh theo ý bạn.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-operator",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nhập giá trị ngưỡng mà bạn quan tâm
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-value",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm điều kiện mới đồng thời xảy ra (VÀ), lặp lại bước 6,7,8.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-button",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Thêm điều kiện mới hoặc sẽ xảy ra (HOẶC), lặp lại bước 6,7,8.
              </span>
            ),
            placement: "top",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".insight-create-button-condition",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn địa điểm mà khuyến nghị sẽ hoạt động.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-points",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn người dùng sẽ nhận được cảnh báo.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".checkbox-right-contact",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn kênh cảnh báo, email là ngầm định, zalo dành cho gói tính
                phí.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-channel",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chọn thời gian cảnh báo trước, hệ thống sẽ gửi trước thời gian
                này khi sự kiện xảy ra.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-before-time",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nhập lời nhắn riêng cho người nhận khuyến nghị nếu muốn.
              </span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-content-alert",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>Thêm người dùng mới nếu cần.</span>
            ),
            placement: "left-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".step-insight-basic-contacts-button",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả các hướng dẫn tại đây
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };
  const clickTourDashboardMenu = () => {
    dispatch(
      TourActions.fetchMenu({
        run: true,
        isStart: true,
        stepIndex: 0,
        steps: [
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Cung cấp đầy đủ thông tin dự báo thời tiết cho mọi điểm bạn cần.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableBeacon: true,
            disableOverlayClose: true,
            target: ".sideWeatherName",
            title: "Giới thiệu các menu",
            styles: {
              options: {
                width: "425px",
              },
            },
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>Bảng khuyến nghị hành động</span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sideDashboardName",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Nơi quản lý các khuyến nghị của bạn và bạn có thể tự thêm khuyến
                nghị mới hoặc sử dụng các khuyến nghị có sẵn theo ngành.
              </span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sideInsightName",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Quản lý người dùng: có thể thêm người dùng mới cùng xem nội
                dung.
              </span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sideUserName",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Quản lý địa điểm: giúp bạn theo dõi, thêm, bớt địa điểm.
              </span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sidePointName",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Báo cáo: lưu trữ các bản tin dự báo mà bạn đặt hàng, các mẫu báo
                cáo thời tiết Weatherplus có thể cung cấp, các bài viết phân
                tích ngành.
              </span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sideReport",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Tài liệu hướng dẫn sử dụng, các câu hỏi thường gặp.
              </span>
            ),
            placement: "bottom-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: ".sideHelpName",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Chat liên hệ ngay với đội ngũ hỗ trợ Metplus khi cần trợ giúp.
              </span>
            ),
            placement: "top-end",
            spotlightClicks: false,
            disableOverlayClose: true,
            target: "#facebook",
          },
          {
            content: (
              <span style={{ fontSize: 20 }}>
                Truy cập lại tất cả hướng dẫn tại đây.
              </span>
            ),
            placement: "right-start",
            spotlightClicks: false,
            disableOverlayClose: true,
            disableBeacon: true,
            hideCloseButton: true,
            target: ".avatar",
          },
        ],
      })
    );
  };

  return (
    <Menu className={"menu-account bg-cl-header"} style={{ width: 300 }}>
      <Menu.Item
        className="option"
        onClick={() => {
          openInNewTab("https://www.youtube.com/watch?v=MW210WL19CU");
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <VideoCameraOutlined
              className={"ft-cl-icon-location-search-weather"}
              style={{ fontSize: "24px", color: "white" }}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Video hướng dẫn METPLUS
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourWeather();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <img
              src={Weather}
              alt="User"
              width="30px"
              height="30px"
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn sử dụng trang thông tin thời tiết
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourDashboard();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <img
              src={Category}
              alt="User"
              width="30px"
              height="30px"
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn sử dụng bảng khuyến nghị hành động
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourInsight();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <img
              src={Insight}
              alt="User"
              width="30px"
              height="30px"
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn sử dụng Thư viện khuyến nghị
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourInsightCareer();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <img
              src={InsightCareer}
              alt="User"
              width="30px"
              height="30px"
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn sử dụng Khuyến nghị theo ngành
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourInsightAdd();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <SettingOutlined
              style={{ fontSize: 26, color: "#FFFFFF" }}
              className={"ft-cl-icon-location-search-weather"}
            />
            {/*<img src={Weather} alt="User" width="30px" height="30px" />*/}
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn thêm khuyến nghị theo thiết lập cơ bản
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourInsightAddAdvanced();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <img
              src={advanceButton}
              alt="User"
              width="30px"
              height="30px"
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Hướng dẫn thêm khuyến nghị theo thiết lập nâng cao
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item
        className="option"
        onClick={() => {
          clickTourDashboardMenu();
        }}
      >
        <Row gutter={10} align={"middle"}>
          <Col span={4}>
            <MenuOutlined
              style={{ color: "#FFFFFF", fontSize: 28 }}
              className={"ft-cl-icon-location-search-weather"}
            />
          </Col>
          <Col span={20} className="title-tour cl-main-title">
            Giới thiệu các menu
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
};
