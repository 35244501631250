import { combineReducers } from "redux";
import account from "./account.reducer";
import info from "./info.reducer";
import dashboard from "../../../dashboard/store/reducers/dashboard.reducer";
import payment from "./payment.reducer";
import notify from "../../../log_notify/store/reducers/notify.reducer";
import report from "../../../profile/store/reducers/frequency_report.reducer";
import tour from "../../../help/store/reducers/step.reducer";
import career from "../../../insights/store/reducers/insight.reducer";
import location from "../../../location/store/reducers/location.reducer";
import insight from "../../../insights/store/reducers/insight.reducer";
import element from "../../../insights/store/reducers/element.reducer";
import contact from "../../../contact/store/reducers/contact.reducer";
const reducer = combineReducers({
  account,
  info,
  payment,
  dashboard,
  notify,
  report,
  tour,
  career,
  location,
  insight,
  element,
  contact,
});

export default reducer;
