import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { convertConditionOnboarding } from "../../helpers/common";
import { useTranslation } from "react-i18next";
import tick from "../../../assets/icon/tick.svg";
import { useDispatch, useSelector } from "react-redux";
import * as LocationActions from "../../main/location/store/actions";
import AddLocationNew from "./add_location_new";
import { Element } from "react-scroll";

const { Option } = Select;

const Steps5Mobile = React.memo((props) => {
  const { prev, infoSteps, next, setInfoSteps, account } = props;
  const { t } = useTranslation("account");
  const [validate, setValidate] = useState(true);
  const dispatch = useDispatch();
  const [disablePoint, setDisablePoint] = useState(false);
  const [checkFocus, setCheckFocus] = useState(false);
  const [isDropdown, setIsDropdown] = useState(true);

  useEffect(() => {
    let exitsPoint = true;
    infoSteps.step5?.insights?.forEach((insight) => {
      if (insight.points.length === 0) {
        exitsPoint = false;
      }
    });
    const listPoint = [];
    infoSteps.step5.insights?.forEach((i) => {
      i.points?.forEach((point) => {
        listPoint.push(point);
      });
    });
    const uniqueListPoint = [
      ...new Map(listPoint.map((item) => [item, item])).values(),
    ];
    setValidate(exitsPoint && uniqueListPoint.length <= 2);
  }, [infoSteps]);

  const { data } = useSelector((state) => state.accountData.location.locations);

  useEffect(() => {
    dispatch(
      LocationActions.fetch({
        searchCommon: "",
        sortBy: "createdAt",
        rowsPerPage: 999999,
      })
    );
  }, [dispatch]);

  const processDataFinal = () => {
    if (account?.packageDetail?.isOneClick !== 1) {
      const dataBody = {
        step2: infoSteps.step2,
        step3: {
          insights: infoSteps.step5.insights.map((insight) => {
            return {
              ...insight,
              id: null,
              type: 1,
              packageInsight: null,
              groupConditions: insight.groupConditions.map((groupCondition) => {
                return {
                  conditions: groupCondition.conditions.map((condition) => {
                    return {
                      elementId: condition.elementId.id,
                      operator: condition.operator,
                      value: condition.value,
                    };
                  }),
                };
              }),
            };
          }),
        },
      };
      dispatch(LocationActions.fetchOnBoarding(dataBody));
    }
    next();
  };

  const onChangePoint = (e, insight) => {
    setInfoSteps({
      ...infoSteps,
      step5: {
        insights: infoSteps.step5.insights.map((i) => {
          if (i.id === insight.id || i.points.length === 0) {
            return {
              ...i,
              points: e,
            };
          }
          return i;
        }),
      },
    });
  };

  const handleClickSelect = () => {
    if (isDropdown) {
      setCheckFocus(true);
    } else {
      setCheckFocus(false);
    }
  };

  const onDropdownVisibleChangePoint = (e) => {
    setDisablePoint(!e);
    setIsDropdown(e);
    if (!e) {
      setCheckFocus(false);
    }
  };

  return (
    <div>
      <ArrowLeftOutlined
        onClick={() => prev()}
        style={{
          color: "#FFFFFF",
          fontSize: 26,
          position: "absolute",
          top: 40,
        }}
      />
      <Row justify="space-around" align="middle">
        <Col
          className="font-text font-weight-7"
          style={{
            fontSize: "16px",
            paddingBottom: 20,
            paddingTop: 15,
            color: "#000000",
          }}
        >
          Gán địa điểm cho các khuyến nghị của bạn
        </Col>
      </Row>
      <Element
        style={{
          position: "relative",
          height: 360,
          // paddingRight: "20px",
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "9px",
          width: "calc(100% + 9px)",
        }}
        alt={"width"}
      >
        {/*<Col>*/}
        {infoSteps.step5?.insights.map((insight, index) => {
          return (
            <Row
              key={index}
              className="steps5-mobile-condition insight-package-table"
            >
              <Col span={2} style={{ paddingTop: 15, paddingLeft: 10 }}>
                <img alt="tick" src={tick} />
              </Col>
              <Col span={22} style={{ paddingTop: 14, paddingLeft: 10 }}>
                <Tooltip placement="topLeft" title={insight.name}>
                  <Typography.Paragraph
                    className={"font-text font-weight-6"}
                    style={{ fontSize: 14, color: "#000000" }}
                    ellipsis={{
                      rows: 1,
                      expandable: false,
                      tooltip: insight.name,
                    }}
                  >
                    {insight.name}
                  </Typography.Paragraph>
                </Tooltip>
              </Col>
              <Divider
                style={{
                  background: "rgba(131, 131, 131, 0.4)",
                  margin: "0 0 20px 0",
                }}
              />
              <Col span={24} style={{ paddingLeft: 10 }}>
                <span className="steps4-mobile-text-header">
                  Điều kiện thời tiết:{" "}
                </span>
                <span className="steps4-mobile-text-condition">
                  <span style={{ color: "#000000" }}>
                    {convertConditionOnboarding(insight)}
                  </span>
                </span>
              </Col>
              <Divider
                style={{
                  background: "rgba(131, 131, 131, 0.4)",
                  margin: "20px 0 0 0",
                }}
              />
              <Col span={24} style={{ padding: 10 }}>
                <Select
                  mode="multiple"
                  className={"alert-input"}
                  showSearch
                  optionFilterProp="children"
                  placeholder={!checkFocus ? t("FormPlaceholderPoint") : ""}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%", background: "#ffffff" }}
                  allowClear
                  onChange={(e) => onChangePoint(e, insight)}
                  dropdownClassName={`dropdown-select-step-popup-first`}
                  listHeight={200}
                  value={insight.points}
                  notFoundContent={<Empty description={false} />}
                  onClick={() => {
                    handleClickSelect();
                  }}
                  onDropdownVisibleChange={(e) =>
                    onDropdownVisibleChangePoint(e)
                  }
                  dropdownRender={(menu) => (
                    <>
                      <AddLocationNew
                        checkFocus={checkFocus}
                        insight={insight}
                        setInfoSteps={setInfoSteps}
                        infoSteps={infoSteps}
                        setDisablePoint={setDisablePoint}
                      />
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                  // open={true}
                >
                  {data?.content?.length > 0 &&
                    data?.content?.map((item, idx) => (
                      <Option
                        key={String(idx)}
                        value={item.id}
                        disabled={disablePoint}
                      >
                        {item.pointName}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          );
        })}
        {/*</Col>*/}
      </Element>
      <Row
        style={{ marginTop: 20, marginBottom: 20 }}
        justify="space-around"
        align="middle"
      >
        <Col
          className="font-text font-weight-4 font-size"
          style={{ textAlign: "center", color: "#ffffff" }}
        >
          <Button
            className="button-first mst-onboarding-step-5"
            type="text"
            disabled={!validate}
            onClick={() => processDataFinal()}
          >
            Tiếp theo
          </Button>
        </Col>
      </Row>
    </div>
  );
});

export default Steps5Mobile;
