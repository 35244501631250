import Keycloak from "keycloak-js";
import apiConfig from "../configs/api.config";

const _kc = new Keycloak(apiConfig.keycloak);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  if (
    sessionStorage.getItem("accessToken") === undefined ||
    sessionStorage.getItem("accessToken") === null ||
    sessionStorage.getItem("accessToken") === ""
  ) {
    _kc
      .init({
        onLoad: "login-required",
        // onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      })
      .then((authenticated) => {
        if (authenticated) {
          onAuthenticatedCallback();
        } else {
          doLogin();
        }
      });
  } else {
    _kc
      .init({
        checkLoginIframe: false,
        token: sessionStorage.getItem("accessToken"),
        refreshToken: sessionStorage.getItem("refreshToken"),
      })
      .then((authenticated) => {
        if (authenticated) {
          onAuthenticatedCallback();
        } else {
          doLogin();
        }
      });
  }
};

const doLogin = _kc.login;

const doLogout = () => {
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("key");

  _kc.logout();
};

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserId = () => _kc.tokenParsed?.sub;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const createLoginUrl = (idpHint) =>
  _kc.createLoginUrl({
    idpHint,
  });

const setTokens = ({ accessToken, refreshToken, key }) => {
  _kc.init({
    // onLoad: "login-required",
    // onLoad: 'check-sso',
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
  });
  _kc.token = accessToken;
  _kc.refreshToken = refreshToken;
  _kc.key = key;
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
  sessionStorage.setItem("key", key);
};

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserId,
  createLoginUrl,
  setTokens,
};

export default AuthService;
