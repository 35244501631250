import React, { useState } from "react";
import { Col, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./header.scss";
import apiConfig from "../../app/configs/api.config";
import ModalRegister from "../../../src/app/main/auth/modal_register";
import { isMobile } from "react-device-detect";
import SidebarHome from "../Sidebar/Sidebar_home";

const HeaderHome = () => {
  const [isShowModalRegister, setIsShowModalRegister] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const handleShowModalRegister = () => {
    setIsShowModalRegister(!isShowModalRegister);
  };
  const onCloseDrawer = () => {
    setIsShowDrawer(false);
  };
  const handleShowDrawer = () => {
    setIsShowDrawer(true);
  };
  const widthScreen = window.screen.width;
  return (
    <Row className={"container-detail-header-home"}>
      <Col span={24}>
        <Row>
          {!isMobile ? (
            <>
              <Col span={1}></Col>
              <Col span={14} style={{ display: "grid", alignItems: "center" }}>
                <Row>
                  <Col span={5} className={"container-title-header-home"}>
                    <a href={"#home"} className={"title-header-home"}>
                      Trang chủ
                    </a>
                  </Col>
                  <Col span={5} className={"container-title-header-home"}>
                    <a href={"#function"} className={"title-header-home"}>
                      Tính năng
                    </a>
                  </Col>
                  <Col span={5} className={"container-title-header-home"}>
                    <a href={"#price-list"} className={"title-header-home"}>
                      Bảng giá
                    </a>
                  </Col>
                  <Col span={5} className={"container-title-header-home"}>
                    <a href={"#newspaper"} className={"title-header-home"}>
                      Báo chí
                    </a>
                  </Col>
                  <Col span={4} className={"container-title-header-home"}>
                    <a href={"#contact"} className={"title-header-home"}>
                      Liên hệ
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col span={1}></Col>
            </>
          ) : (
            <Col
              span={widthScreen < 390 ? 10 : 10}
              className={"menu-header-home"}
            >
              <Row onClick={handleShowDrawer}>
                <Col span={10} className={"img-menu-header-home"}>
                  <MenuOutlined
                    style={{
                      color: "#6B539A",
                      fontSize: "23px",
                    }}
                  />
                </Col>
                {/* <Col span={16} className={"title-header-home"}>
                  Metplus
                </Col> */}
              </Row>
            </Col>
          )}
          <Col span={!isMobile ? 8 : widthScreen < 390 ? 14 : 14}>
            <Row>
              <Col span={13} className={"container-button-login-header-home"}>
                <a
                  href={apiConfig.domainHome + "/dashboards"}
                  className="login"
                >
                  <button className={"button-login-home"}>Đăng nhập</button>
                </a>
              </Col>
              <Col
                span={11}
                className={"container-button-register-header-home"}
              >
                <a
                  className={"button-register-home"}
                  onClick={handleShowModalRegister}
                >
                  Đăng ký
                </a>
              </Col>
            </Row>
          </Col>
          <ModalRegister
            isShowModalRegister={isShowModalRegister}
            handleShowModalRegister={handleShowModalRegister}
          />
        </Row>
      </Col>
      <SidebarHome isShowDrawer={isShowDrawer} onCloseDrawer={onCloseDrawer} />
    </Row>
  );
};
export default HeaderHome;
