import React from "react";

import { authRoles } from "app/auth";

import vi from "./i18n/vi";
import en from "./i18n/en";

import { isMobileOnly } from "react-device-detect";

import i18next from "i18next";

i18next.addResourceBundle("vi", "profile", vi);
i18next.addResourceBundle("en", "profile", en);

const ProfileConfig = {
  settings: {
    layout: "admin",
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/profile",
      exact: true,
      component: React.lazy(() => import("./profile")),
    },
    {
      path: "/profile/upgrade",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly ? import("./upgrade.mobile") : import("./upgrade")
      ),
    },
    {
      path: "/profile/pay-info",
      exact: true,
      component: React.lazy(() =>
        isMobileOnly
          ? import("./payment_history.mobile")
          : import("./payment_history")
      ),
    },
    // {
    //   path: "/profile/upgrade",
    //   exact: true,
    //   component: React.lazy(() =>
    //     isMobileOnly ? import("./payment") : import("./payment")
    //   ),
    // },
  ],
};

export default ProfileConfig;
