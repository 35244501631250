import axios from "axios";
import AuthService from "app/auth-keycloak/authservice";

export const REPORT_ERROR = "REPORT_ERROR";
export const REPORT_FETCHING = "REPORT_FETCHING";
export const REPORT_FETCHED = "REPORT_FETCHED";
export const REPORT_UPDATE = "REPORT_UPDATE";
export const HELP_CHECK = "HELP_CHECK";
export const HELP_UPDATE = "HELP_UPDATE";
export const getAllReportFrequency = () => async (dispatch) => {
  dispatch({ type: REPORT_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    const dataBody = {
      searchCommon: "",
      sortBy: "createdAt",
      rowsPerPage: 10,
    };
    const res = await axios.post("user/list-report-frequency", dataBody);
    dispatch({
      type: REPORT_FETCHED,
      payload: {
        data: res.data.data,
        status: res.data.status,
        message: res.data.message,
      },
    });
  } catch (error) {
    dispatch({
      type: REPORT_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const checkActiveHelp = (key) => async (dispatch) => {
  dispatch({ type: REPORT_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;

    dispatch({
      type: HELP_CHECK,
      payload: key,
    });
  } catch (error) {
    dispatch({
      type: REPORT_ERROR,
      payload: error,
    });
    throw error;
  }
};
export const updateDefaultValue = (value) => async (dispatch) => {
  dispatch({ type: REPORT_FETCHING });
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
    dispatch({
      type: HELP_UPDATE,
      payload: value,
    });
  } catch (error) {
    dispatch({
      type: REPORT_ERROR,
      payload: error,
    });
    throw error;
  }
};
